import use from '@/services/saas.request';
import {
    ReqDeleteV1SaasStudioIdCoursesCourseId,
    ReqDeleteV1SaasStudioIdPkgPkgId,
    ReqDeleteV1SaasStudioIdScheduleScheduleId,
    ReqDeleteV1SaasStudioStudioIdGroupGroupId,
    ReqGetV1SaasStudioIdCourseCourseIdTagListAll,
    ReqGetV1SaasStudioIdCourseLoginfoList,
    ReqGetV1SaasStudioIdCoursesCourseIdInfo,
    ReqGetV1SaasStudioIdCoursesList,
    ReqGetV1SaasStudioIdCoursesListAll,
    ReqGetV1SaasStudioIdCourseTagList,
    ReqGetV1SaasStudioIdCycleScheduleCycleScheduleIdInfo,
    ReqGetV1SaasStudioIdCycleScheduleList,
    ReqGetV1SaasStudioIdPkgList,
    ReqGetV1SaasStudioIdPkgLoginfoList,
    ReqGetV1SaasStudioIdPkgPkgIdStatusList,
    ReqGetV1SaasStudioIdScheduleList,
    ReqGetV1SaasStudioIdScheduleScheduleIdCleanList,
    ReqGetV1SaasStudioIdScheduleScheduleIdInfo,
    ReqGetV1SaasStudioIdTagLoginfoList,
    ReqGetV1SaasStudioStudioIdGroupList,
    ReqGetV1SaasStudioStudioIdPkgPkgIdInfo,
    ReqGetV1SaasStudioStudioIdScheduleScheduleIdAppointmentList,
    ReqGetV1SaasStudioStudioIdScheduleScheduleIdSignedList,
    ReqPatchV1SaasStudioIdCycleScheduleCycleScheduleId,
    ReqPatchV1SaasStudioStudioIdPkgPkgIdGroupGroupId,
    ReqPostV1SaasStudioIdCourses,
    ReqPostV1SaasStudioIdCourseTag,
    ReqPostV1SaasStudioIdCycleSchedule,
    ReqPostV1SaasStudioIdPkg,
    ReqPostV1SaasStudioIdSchedule,
    ReqPostV1SaasStudioStudioIdGroup,
    ReqPutV1SaasStudioIdCoursesCourseId,
    ReqPutV1SaasStudioIdCourseTagTagId,
    ReqPutV1SaasStudioIdCycleScheduleCycleScheduleId,
    ReqPutV1SaasStudioIdPkgPkgId,
    ReqPutV1SaasStudioIdPkgPkgIdStatus,
    ReqPutV1SaasStudioIdScheduleScheduleId,
    ReqPutV1SaasStudioStudioIdScheduleScheduleIdCheck,
    ReqPutV1SaasStudioIdScheduleScheduleIdClear,
    ReqPutV1SaasStudioStudioIdScheduleScheduleIdReservation,
    ReqPutV1SaasStudioStudioIdScheduleScheduleIdUncheck,
    ReqPutV1SaasStudioStudioIdGroupDefaultGroupId,
    ReqPutV1SaasStudioStudioIdGroupExchange,
    ReqPutV1SaasStudioStudioIdGroupGroupId,
    ResDeleteV1SaasStudioIdCoursesCourseId,
    ResDeleteV1SaasStudioIdPkgPkgId,
    ResDeleteV1SaasStudioIdScheduleScheduleId,
    ResGetV1SaasStudioIdCourseCourseIdTagListAll,
    ResGetV1SaasStudioIdCourseLoginfoList,
    ResGetV1SaasStudioIdCoursesCourseIdInfo,
    ResGetV1SaasStudioIdCoursesList,
    ResGetV1SaasStudioIdCoursesListAll,
    ResGetV1SaasStudioIdCourseTagList,
    ResGetV1SaasStudioIdCycleScheduleCycleScheduleIdInfo,
    ResGetV1SaasStudioIdCycleScheduleList,
    ResGetV1SaasStudioIdPkgList,
    ResGetV1SaasStudioIdPkgLoginfoList,
    ResGetV1SaasStudioIdPkgPkgIdStatusList,
    ResGetV1SaasStudioIdScheduleList,
    ResGetV1SaasStudioIdScheduleScheduleIdCleanList,
    ResGetV1SaasStudioIdScheduleScheduleIdInfo,
    ResGetV1SaasStudioIdTagLoginfoList,
    ResGetV1SaasStudioStudioIdGroupList,
    ResGetV1SaasStudioStudioIdPkgPkgIdInfo,
    ResGetV1SaasStudioStudioIdScheduleScheduleIdAppointmentList,
    ResPatchV1SaasStudioIdCycleScheduleCycleScheduleId,
    ResPatchV1SaasStudioStudioIdPkgPkgIdGroupGroupId,
    ResPostV1SaasStudioIdCourses,
    ResPostV1SaasStudioIdCourseTag,
    ResPostV1SaasStudioIdCycleSchedule,
    ResPostV1SaasStudioIdPkg,
    ResPostV1SaasStudioIdSchedule,
    ResPostV1SaasStudioStudioIdGroup,
    ResPutV1SaasStudioIdCoursesCourseId,
    ResPutV1SaasStudioIdCourseTagTagId,
    ResPutV1SaasStudioIdCycleScheduleCycleScheduleId,
    ResPutV1SaasStudioIdPkgPkgId,
    ResPutV1SaasStudioIdPkgPkgIdStatus,
    ResPutV1SaasStudioIdScheduleScheduleId,
    ResPutV1SaasStudioStudioIdScheduleScheduleIdCheck,
    ResPutV1SaasStudioIdScheduleScheduleIdClear,
    ResPutV1SaasStudioStudioIdScheduleScheduleIdUncheck,
    ResPutV1SaasStudioStudioIdGroupDefaultGroupId,
    ResPutV1SaasStudioStudioIdGroupExchange,
    ResPutV1SaasStudioStudioIdGroupGroupId,
    ResPutV1SaasStudioStudioIdScheduleScheduleIdReservation,
    ReqGetV1SaasStudioIdScheduleScheduleIdVipVipIdUncheckListAll,
    ResGetV1SaasStudioIdScheduleScheduleIdVipVipIdUncheckListAll,
    ReqGetV1SaasStudioIdScheduleScheduleIdStudents,
    ResGetV1SaasStudioIdScheduleScheduleIdStudents,
    ReqGetV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealIdFirstAppointment,
    ResGetV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealIdFirstAppointment,
    ReqGetV1SaasStudioSchedulePackageBooking,
    ResGetV1SaasStudioSchedulePackageBooking,
    ReqGetV1SaasStudioIdCourseCourseIdPkgListAll,
    ResGetV1SaasStudioIdCourseCourseIdPkgListAll,
    ReqGetV1SaasStudioIdPkgVipList,
    ResGetV1SaasStudioIdPkgVipList,
    ReqPostV1SaasStudioIdScheduleScheduleIdCopy,
    ResPostV1SaasStudioIdScheduleScheduleIdCopy,
    ReqPostV1SaasStudioIdScheduleCopy,
    ResPostV1SaasStudioIdScheduleCopy,
    ReqGetV1SaasStudioIdCleanCourseList,
    ResGetV1SaasStudioIdCleanCourseList,
    ReqGetV1SaasStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList,
    ResGetV1SaasStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList,
    ReqPutV1SaasStudioIdScheduleScheduleIdReservationAll,
    ResPutV1SaasStudioIdScheduleScheduleIdReservationAll,
    ReqGetV1SaasStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse,
    ResGetV1SaasStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse,
    ReqGetV1SaasStudioIdPkgListAll,
    ResGetV1SaasStudioIdPkgListAll,
    ReqGetV1SaasStudioIdGroupGroupIdPkgListAll,
    ResGetV1SaasStudioIdGroupGroupIdPkgListAll,
    ReqPutV1SaasStudioIdGroupGroupIdPkgSort,
    ResPutV1SaasStudioIdGroupGroupIdPkgSort,
    ReqPatchV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealId,
    ResPatchV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealId,
    ReqPostV1SaasStudioIdIntegral,
    ResPostV1SaasStudioIdIntegral,
    ReqPutV1SaasStudioIdIntegral,
    ResPutV1SaasStudioIdIntegral,
    ReqGetV1SaasStudioIdIntegralIntegralIdInfo,
    ResGetV1SaasStudioIdIntegralIntegralIdInfo,
    ReqGetV1SaasStudioIdIntegralListAll,
    ResGetV1SaasStudioIdIntegralListAll,
    ReqPatchV1SaasStudioIdIntegralFromIdToId,
    ResPatchV1SaasStudioIdIntegralFromIdToId,
    ReqDeleteV1SaasStudioIdIntegralIntegralId,
    ResDeleteV1SaasStudioIdIntegralIntegralId,
    ReqPutV1SaasStudioIdIntegralIntegralIdStatus,
    ResPutV1SaasStudioIdIntegralIntegralIdStatus,
    ReqGetV1SaasStudioIdPointsScaleInfo,
    ResGetV1SaasStudioIdPointsScaleInfo,
    ReqPutV1SaasStudioIdBuyScale,
    ResPutV1SaasStudioIdBuyScale,
    ReqPutV1SaasStudioIdExchangeScale,
    ResPutV1SaasStudioIdExchangeScale,
    ReqGetV1SaasStudioIdScheduleScheduleIdPointsStudentsList,
    ResGetV1SaasStudioIdScheduleScheduleIdPointsStudentsList,
    ReqGetV1SaasStudioIdIntegralVipList,
    ResGetV1SaasStudioIdIntegralVipList,
    ReqPutV1SaasStudioIdScheduleScheduleIdReservationIntegral,
    ReqGetV1SaasStudioIdTagTagIdCoursesList,
    ResGetV1SaasStudioIdTagTagIdCoursesList,
    ReqGetV1SaasStudioIdScheduleNoCleanList,
    ResGetV1SaasStudioIdScheduleNoCleanList,
    ResPutV1SaasStudioIdScheduleScheduleIdReservationIntegral,
    ReqGetV1SaasStudioIdTagTagIdCheck,
    ResGetV1SaasStudioIdTagTagIdCheck,
    ReqGetV1ReceptionStudioStudioIdScheduleSkdIdCandidate,
    ResGetV1ReceptionStudioStudioIdScheduleSkdIdCandidate,
    ReqDeleteV1SaasStudioIdCycleScheduleCycleScheduleId,
    ResDeleteV1SaasStudioIdCycleScheduleCycleScheduleId,
    ReqPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip,
    ResPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip,
    ReqGetV1SaasStudioIdScheduleCopyList,
    ResGetV1SaasStudioIdScheduleCopyList,
    ReqPostV1SaasStudioIdScheduleCopyList,
    ResPostV1SaasStudioIdScheduleCopyList,
    ReqGetV1SaasStudioIdScheduleSetting,
    ResGetV1SaasStudioIdScheduleSetting,
    ResPutV1SaasStudioIdScheduleSetting,
    ReqPutV1SaasStudioIdScheduleSetting,
    ReqGetV1SaasStudioStudioIdScheduleScheduleIdCheckAll,
    ResGetV1SaasStudioStudioIdScheduleScheduleIdCheckAll,
    ReqGetV1SaasStudioIdScheduleScheduleIdCountStudentsList,
    ResGetV1SaasStudioIdScheduleScheduleIdCountStudentsList,
    ReqPutV1SaasStudioIdScheduleScheduleIdReservationPoint,
    ResPutV1SaasStudioIdScheduleScheduleIdReservationPoint,
    ResGetV1SaasStudioStudioIdScheduleScheduleIdSignedList,
} from './params';



export const createCourseStudio = (p: ReqPostV1SaasStudioIdCourses) =>
    use<ReqPostV1SaasStudioIdCourses, ResPostV1SaasStudioIdCourses>(
        'post',
        `/v1/saas/studio/${p.id}/courses`,
        p
    )

export const editCourseStudio = (p: ReqPutV1SaasStudioIdCoursesCourseId) =>
    use<ReqPutV1SaasStudioIdCoursesCourseId, ResPutV1SaasStudioIdCoursesCourseId>(
        'put',
        `/v1/saas/studio/${p.id}/courses/${p.courseId}`,
        p
    )

export const deleteCourseStudio = (p: ReqDeleteV1SaasStudioIdCoursesCourseId) =>
    use<ReqDeleteV1SaasStudioIdCoursesCourseId, ResDeleteV1SaasStudioIdCoursesCourseId>(
        'delete',
        `/v1/saas/studio/${p.id}/courses/${p.courseId}`,
        p
    )

export const getCourseInfoStudio = (p: ReqGetV1SaasStudioIdCoursesCourseIdInfo) =>
    use<ReqGetV1SaasStudioIdCoursesCourseIdInfo, ResGetV1SaasStudioIdCoursesCourseIdInfo>(
        'get',
        `/v1/saas/studio/${p.id}/courses/${p.courseId}/info`,
        p
    )

export const getCourseListStudio = (p: ReqGetV1SaasStudioIdCoursesList) =>
    use<ReqGetV1SaasStudioIdCoursesList, ResGetV1SaasStudioIdCoursesList>(
        'get',
        `/v1/saas/studio/${p.id}/courses/list`,
        p
    )

export const courseLogIngoStudio = (p: ReqGetV1SaasStudioIdCourseLoginfoList) =>
    use<ReqGetV1SaasStudioIdCourseLoginfoList, ResGetV1SaasStudioIdCourseLoginfoList>(
        'get',
        `/v1/saas/studio/${p.id}/course/loginfo/list`,
        p
    )

export const editTagStudio = (p: ReqPutV1SaasStudioIdCourseTagTagId) =>
    use<ReqPutV1SaasStudioIdCourseTagTagId, ResPutV1SaasStudioIdCourseTagTagId>(
        'put',
        `/v1/saas/studio/${p.id}/course_tag/${p.tagId}`,
        p
    )

export const createTagStudio = (p: ReqPostV1SaasStudioIdCourseTag) =>
    use<ReqPostV1SaasStudioIdCourseTag, ResPostV1SaasStudioIdCourseTag>(
        'post',
        `/v1/saas/studio/${p.id}/course_tag`,
        p
    )

export const tagLogInfoStudio = (p: ReqGetV1SaasStudioIdTagLoginfoList) =>
    use<ReqGetV1SaasStudioIdTagLoginfoList, ResGetV1SaasStudioIdTagLoginfoList>(
        'get',
        `/v1/saas/studio/${p.id}/tag/loginfo/list`,
        p
    )

export const pkgLogInfoStudio = (p: ReqGetV1SaasStudioIdPkgLoginfoList) =>
    use<ReqGetV1SaasStudioIdPkgLoginfoList, ResGetV1SaasStudioIdPkgLoginfoList>(
        'get',
        `/v1/saas/studio/${p.id}/pkg/loginfo/list`,
        p
    )

export const getPkgListStudio = (p: ReqGetV1SaasStudioIdPkgList) =>
    use<ReqGetV1SaasStudioIdPkgList, ResGetV1SaasStudioIdPkgList>(
        'get',
        `/v1/saas/studio/${p.id}/pkg/list`,
        p
    )

export const deletePkgStudio = (p: ReqDeleteV1SaasStudioIdPkgPkgId) =>
    use<ReqDeleteV1SaasStudioIdPkgPkgId, ResDeleteV1SaasStudioIdPkgPkgId>(
        'delete',
        `/v1/saas/studio/${p.id}/pkg/${p.pkgId}`,
        p
    )

export const getTagListStudio = (p: ReqGetV1SaasStudioIdCourseTagList) =>
    use<ReqGetV1SaasStudioIdCourseTagList, ResGetV1SaasStudioIdCourseTagList>(
        'get',
        `/v1/saas/studio/${p.id}/course_tag/list`,
        p
    )
export const checkTagUseStudio = (p: ReqGetV1SaasStudioIdTagTagIdCheck) =>
    use<ReqGetV1SaasStudioIdTagTagIdCheck, ResGetV1SaasStudioIdTagTagIdCheck>(
        'get',
        `/v1/saas/studio/${p.id}/tag/${p.tagId}/check`,
        p
    )
export const createPkgStudio = (p: ReqPostV1SaasStudioIdPkg) =>
    use<ReqPostV1SaasStudioIdPkg, ResPostV1SaasStudioIdPkg>(
        'post',
        `/v1/saas/studio/${p.id}/pkg`,
        p
    )

export const getCourseAllStudio = (p: ReqGetV1SaasStudioIdCoursesListAll) =>
    use<ReqGetV1SaasStudioIdCoursesListAll, ResGetV1SaasStudioIdCoursesListAll>(
        'get',
        `/v1/saas/studio/${p.id}/courses/list_all`,
        p
    )

export const getTagCourseAllStudio = (p: ReqGetV1SaasStudioIdTagTagIdCoursesList) =>
    use<ReqGetV1SaasStudioIdTagTagIdCoursesList, ResGetV1SaasStudioIdTagTagIdCoursesList>(
        'get',
        `/v1/saas/studio/${p.id}/tag/${p.tagId}/coursesList`,
        p
    )

export const getCourseTagsStudio = (p: ReqGetV1SaasStudioIdCourseCourseIdTagListAll) =>
    use<ReqGetV1SaasStudioIdCourseCourseIdTagListAll, ResGetV1SaasStudioIdCourseCourseIdTagListAll>(
        'get',
        `/v1/saas/studio/${p.id}/course/${p.course_id}/tag/list_all`,
        p
    )

export const getPkgStatusRecordStudio = (p: ReqGetV1SaasStudioIdPkgPkgIdStatusList) =>
    use<ReqGetV1SaasStudioIdPkgPkgIdStatusList, ResGetV1SaasStudioIdPkgPkgIdStatusList>(
        'get',
        `/v1/saas/studio/${p.id}/pkg/${p.pkgId}/status/list`,
        p
    )

export const getPkgDetailStudio = (p: ReqGetV1SaasStudioStudioIdPkgPkgIdInfo) =>
    use<ReqGetV1SaasStudioStudioIdPkgPkgIdInfo, ResGetV1SaasStudioStudioIdPkgPkgIdInfo>(
        'get',
        `/v1/saas/studio/${p.studioId}/pkg/${p.pkgId}/info`,
        p
    )

export const editPkgStudio = (p: ReqPutV1SaasStudioIdPkgPkgId) =>
    use<ReqPutV1SaasStudioIdPkgPkgId, ResPutV1SaasStudioIdPkgPkgId>(
        'put',
        `/v1/saas/studio/${p.id}/pkg/${p.pkgId}`,
        p
    )

export const putPkgStatusStudio = (p: ReqPutV1SaasStudioIdPkgPkgIdStatus) =>
    use<ReqPutV1SaasStudioIdPkgPkgIdStatus, ResPutV1SaasStudioIdPkgPkgIdStatus>(
        'put',
        `/v1/saas/studio/${p.id}/pkg/${p.pkgId}/status`,
        p
    )

export const getPkgGroup = (p: ReqGetV1SaasStudioStudioIdGroupList) =>
    use<ReqGetV1SaasStudioStudioIdGroupList, ResGetV1SaasStudioStudioIdGroupList>(
        'get',
        `/v1/saas/studio/${p.studioId}/group/list`,
        p
    )

export const deletePkgGroup = (p: ReqDeleteV1SaasStudioStudioIdGroupGroupId) =>
    use<ReqDeleteV1SaasStudioStudioIdGroupGroupId, ReqDeleteV1SaasStudioStudioIdGroupGroupId>(
        'delete',
        `/v1/saas/studio/${p.studioId}/group/${p.groupId}`,
        p
    )

export const putPkgGroup = (p: ReqPutV1SaasStudioStudioIdGroupGroupId) =>
    use<ReqPutV1SaasStudioStudioIdGroupGroupId, ResPutV1SaasStudioStudioIdGroupGroupId>(
        'put',
        `/v1/saas/studio/${p.studioId}/group/${p.groupId}`,
        p
    )
export const setDefaultGroup = (p: ReqPutV1SaasStudioStudioIdGroupDefaultGroupId) =>
    use<ReqPutV1SaasStudioStudioIdGroupDefaultGroupId, ResPutV1SaasStudioStudioIdGroupDefaultGroupId>(
        'put',
        `/v1/saas/studio/${p.studioId}/group/default/${p.groupId}`,
        p
    )

export const postPkgGroup = (p: ReqPostV1SaasStudioStudioIdGroup) =>
    use<ReqPostV1SaasStudioStudioIdGroup, ResPostV1SaasStudioStudioIdGroup>(
        'post',
        `/v1/saas/studio/${p.studioId}/group`,
        p
    )

export const setPkgGroup = (p: ReqPatchV1SaasStudioStudioIdPkgPkgIdGroupGroupId) =>
    use<ReqPatchV1SaasStudioStudioIdPkgPkgIdGroupGroupId, ResPatchV1SaasStudioStudioIdPkgPkgIdGroupGroupId>(
        'patch',
        `/v1/saas/studio/${p.studioId}/pkg/${p.pkgId}/group/${p.groupId}`,
        p
    )
export const exchangeGroup = (p: ReqPutV1SaasStudioStudioIdGroupExchange) =>
    use<ReqPutV1SaasStudioStudioIdGroupExchange, ResPutV1SaasStudioStudioIdGroupExchange>(
        'put',
        `/v1/saas/studio/${p.studioId}/group/exchange`,
        p
    )

export const getGroupPkgListAll = (p: ReqGetV1SaasStudioIdGroupGroupIdPkgListAll) =>
    use<ReqGetV1SaasStudioIdGroupGroupIdPkgListAll, ResGetV1SaasStudioIdGroupGroupIdPkgListAll>(
        'get',
        `/v1/saas/studio/${p.id}/group/${p.groupId}/pkg/list_all`
    )

export const sortPkg = (p: ReqPutV1SaasStudioIdGroupGroupIdPkgSort) =>
    use<ReqPutV1SaasStudioIdGroupGroupIdPkgSort, ResPutV1SaasStudioIdGroupGroupIdPkgSort>(
        'put',
        `/v1/saas/studio/${p.id}/group/${p.groupId}/pkg_sort`,
        p
    )

export const getScheduleList = (p: ReqGetV1SaasStudioIdScheduleList) =>
    use<ReqGetV1SaasStudioIdScheduleList, ResGetV1SaasStudioIdScheduleList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/list`,
        p
    )


export const postSchedule = (p: ReqPostV1SaasStudioIdSchedule) =>
    use<ReqPostV1SaasStudioIdSchedule, ResPostV1SaasStudioIdSchedule>(
        'post',
        `/v1/saas/studio/${p.id}/schedule`,
        p
    )

export const deleteSchedule = (p: ReqDeleteV1SaasStudioIdScheduleScheduleId) =>
    use<ReqDeleteV1SaasStudioIdScheduleScheduleId, ResDeleteV1SaasStudioIdScheduleScheduleId>(
        'delete',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}`,
        p
    )

export const getScheduleDetail = (p: ReqGetV1SaasStudioIdScheduleScheduleIdInfo) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdInfo, ResGetV1SaasStudioIdScheduleScheduleIdInfo>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/info`,
        p
    )

export const putScheduleClear = (p: ReqPutV1SaasStudioIdScheduleScheduleIdClear) =>
    use<ReqPutV1SaasStudioIdScheduleScheduleIdClear, ResPutV1SaasStudioIdScheduleScheduleIdClear>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/clear`,
        p
    )

export const putSchedule = (p: ReqPutV1SaasStudioIdScheduleScheduleId) =>
    use<ReqPutV1SaasStudioIdScheduleScheduleId, ResPutV1SaasStudioIdScheduleScheduleId>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}`,
        p
    )

export const getCycleScheduleList = (p: ReqGetV1SaasStudioIdCycleScheduleList) =>
    use<ReqGetV1SaasStudioIdCycleScheduleList, ResGetV1SaasStudioIdCycleScheduleList>(
        'get',
        `/v1/saas/studio/${p.id}/cycleSchedule/list`,
        p
    )

export const postCycleSchedule = (p: ReqPostV1SaasStudioIdCycleSchedule) =>
    use<ReqPostV1SaasStudioIdCycleSchedule, ResPostV1SaasStudioIdCycleSchedule>(
        'post',
        `/v1/saas/studio/${p.id}/cycleSchedule`,
        p
    )

export const getCycleScheduleDetail = (p: ReqGetV1SaasStudioIdCycleScheduleCycleScheduleIdInfo) =>
    use<ReqGetV1SaasStudioIdCycleScheduleCycleScheduleIdInfo, ResGetV1SaasStudioIdCycleScheduleCycleScheduleIdInfo>(
        'get',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}/info`,
        p
    )
export const putCycleSchedule = (p: ReqPutV1SaasStudioIdCycleScheduleCycleScheduleId) =>
    use<ReqPutV1SaasStudioIdCycleScheduleCycleScheduleId, ResPutV1SaasStudioIdCycleScheduleCycleScheduleId>(
        'put',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}`,
        p
    )

export const putCycleVip = (p: ReqPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip) =>
    use<ReqPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip, ResPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip>(
        'put',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}/vip`,
        p
    )
export const putCycleScheduleStatus = (p: ReqPatchV1SaasStudioIdCycleScheduleCycleScheduleId) =>
    use<ReqPatchV1SaasStudioIdCycleScheduleCycleScheduleId, ResPatchV1SaasStudioIdCycleScheduleCycleScheduleId>(
        'patch',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}`,
        p
    )
export const putCycleScheduleVip = (p: ReqPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip) =>
    use<ReqPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip, ResPutV1SaasStudioIdCycleScheduleCycleScheduleIdVip>(
        'patch',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}/vip`,
        p
    )
export const deleteCycleSchedule = (p: ReqDeleteV1SaasStudioIdCycleScheduleCycleScheduleId) =>
    use<ReqDeleteV1SaasStudioIdCycleScheduleCycleScheduleId, ResDeleteV1SaasStudioIdCycleScheduleCycleScheduleId>(
        'delete',
        `/v1/saas/studio/${p.id}/cycleSchedule/${p.cycle_schedule_id}`,
        p
    )

export const getScheduleOrderInfo = (p: ReqGetV1SaasStudioStudioIdScheduleScheduleIdAppointmentList) =>
    use<ReqGetV1SaasStudioStudioIdScheduleScheduleIdAppointmentList, ResGetV1SaasStudioStudioIdScheduleScheduleIdAppointmentList>(
        'get',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/appointment/list`,
        p
    )

export const getCycleScheduleOrderInfo = (p: ReqGetV1SaasStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList) =>
    use<ReqGetV1SaasStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList, ResGetV1SaasStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList>(
        'get',
        `/v1/saas/studio/${p.studioId}/cycle_schedule/${p.cycle_scheduleId}/appointment/list`,
        p
    )

export const getScheduleSignInfo = (p: ReqGetV1SaasStudioStudioIdScheduleScheduleIdSignedList) =>
    use<ReqGetV1SaasStudioStudioIdScheduleScheduleIdSignedList, ResGetV1SaasStudioStudioIdScheduleScheduleIdSignedList>(
        'get',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/signed/list`,
        p
    )

export const getScheduleCleanInfo = (p: ReqGetV1SaasStudioIdScheduleScheduleIdCleanList) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdCleanList, ResGetV1SaasStudioIdScheduleScheduleIdCleanList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/clean/list`,
        p
    )

export const putScheduleReservation = (p: ReqPutV1SaasStudioStudioIdScheduleScheduleIdReservation) =>
    use<ReqPutV1SaasStudioStudioIdScheduleScheduleIdReservation, ResPutV1SaasStudioStudioIdScheduleScheduleIdReservation>(
        'put',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/reservation`,
        p
    )
export const getCandidateList = (p: ReqGetV1ReceptionStudioStudioIdScheduleSkdIdCandidate) =>
    use<ReqGetV1ReceptionStudioStudioIdScheduleSkdIdCandidate, ResGetV1ReceptionStudioStudioIdScheduleSkdIdCandidate>(
        'get',
        `/v1/reception/studio/${p.studioId}/schedule/${p.skdId}/candidate`,
        p
    )
export const helpReservationIntegral = (p: ReqPutV1SaasStudioIdScheduleScheduleIdReservationIntegral) =>
    use<ReqPutV1SaasStudioIdScheduleScheduleIdReservationIntegral, ResPutV1SaasStudioIdScheduleScheduleIdReservationIntegral>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/reservation_integral`,
        p
    )

export const helpReservationPoint = (p: ReqPutV1SaasStudioIdScheduleScheduleIdReservationPoint) =>
    use<ReqPutV1SaasStudioIdScheduleScheduleIdReservationPoint, ResPutV1SaasStudioIdScheduleScheduleIdReservationPoint>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/reservation_point`,
        p
    )
export const putCheck = (p: ReqPutV1SaasStudioStudioIdScheduleScheduleIdCheck) =>
    use<ReqPutV1SaasStudioStudioIdScheduleScheduleIdCheck, ResPutV1SaasStudioStudioIdScheduleScheduleIdCheck>(
        'put',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/check`,
        p
    )
export const checkAll = (p: ReqGetV1SaasStudioStudioIdScheduleScheduleIdCheckAll) =>
    use<ReqGetV1SaasStudioStudioIdScheduleScheduleIdCheckAll, ResGetV1SaasStudioStudioIdScheduleScheduleIdCheckAll>(
        'put',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/check_all`,
        p
    )
export const patchVipCount = (p: ReqPatchV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealId) =>
    use<ReqPatchV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealId, ResPatchV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealId>(
        'patch',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/user/${p.userId}/real/${p.realId}`,
        p
    )
export const putUnCheck = (p: ReqPutV1SaasStudioStudioIdScheduleScheduleIdUncheck) =>
    use<ReqPutV1SaasStudioStudioIdScheduleScheduleIdUncheck, ResPutV1SaasStudioStudioIdScheduleScheduleIdUncheck>(
        'put',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/uncheck`,
        p
    )

export const getVipUnCheckList = (p: ReqGetV1SaasStudioIdScheduleScheduleIdVipVipIdUncheckListAll) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdVipVipIdUncheckListAll, ResGetV1SaasStudioIdScheduleScheduleIdVipVipIdUncheckListAll>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/vip/${p.vipId}/uncheck/list_all`,
        p
    )

export const getScheduleStudents = (p: ReqGetV1SaasStudioIdScheduleScheduleIdStudents) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdStudents, ResGetV1SaasStudioIdScheduleScheduleIdStudents>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/students`,
        p
    )

export const checkFirstReservation = (p: ReqGetV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealIdFirstAppointment) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealIdFirstAppointment, ResGetV1SaasStudioIdScheduleScheduleIdUserUserIdRealRealIdFirstAppointment>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/user/${p.userId}/real/${p.realId}/first/appointment`,
        p
    )

export const getUserPkgList = (p: ReqGetV1SaasStudioSchedulePackageBooking) =>
    use<ReqGetV1SaasStudioSchedulePackageBooking, ResGetV1SaasStudioSchedulePackageBooking>(
        'get',
        `/v1/saas/studio/schedule/package/booking`,
        p
    )

export const integralVipList = (p: ReqGetV1SaasStudioIdScheduleScheduleIdPointsStudentsList) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdPointsStudentsList, ResGetV1SaasStudioIdScheduleScheduleIdPointsStudentsList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/points/students/list`,
        p
    )

export const pointVipList = (p: ReqGetV1SaasStudioIdScheduleScheduleIdCountStudentsList) =>
    use<ReqGetV1SaasStudioIdScheduleScheduleIdCountStudentsList, ResGetV1SaasStudioIdScheduleScheduleIdCountStudentsList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/${p.scheduleId}/count/students/list`,
        p
    )

export const getCoursePkgs = (p: ReqGetV1SaasStudioIdCourseCourseIdPkgListAll) =>
    use<ReqGetV1SaasStudioIdCourseCourseIdPkgListAll, ResGetV1SaasStudioIdCourseCourseIdPkgListAll>(
        'get',
        `/v1/saas/studio/${p.id}/course/${p.course_id}/pkg/list_all`,
        p
    )

export const getPkgVipList = (p: ReqGetV1SaasStudioIdPkgVipList) =>
    use<ReqGetV1SaasStudioIdPkgVipList, ResGetV1SaasStudioIdPkgVipList>(
        'get',
        `/v1/saas/studio/${p.id}/pkg/vip/list`,
        p
    )

export const getIntegralVipList = (p: ReqGetV1SaasStudioIdIntegralVipList) =>
    use<ReqGetV1SaasStudioIdIntegralVipList, ResGetV1SaasStudioIdIntegralVipList>(
        'get',
        `/v1/saas/studio/${p.id}/integral/vip/list`,
        p
    )

export const postCopySchedule = (p: ReqPostV1SaasStudioIdScheduleScheduleIdCopy) =>
    use<ReqPostV1SaasStudioIdScheduleScheduleIdCopy, ResPostV1SaasStudioIdScheduleScheduleIdCopy>(
        'post',
        `/v1/saas/studio/${p.id}/schedule/${p.schedule_id}/copy`,
        p
    )

export const postCopySchedules = (p: ReqPostV1SaasStudioIdScheduleCopy) =>
    use<ReqPostV1SaasStudioIdScheduleCopy, ResPostV1SaasStudioIdScheduleCopy>(
        'post',
        `/v1/saas/studio/${p.id}/schedule/copy`,
        p
    )
export const postCopyRangeSche = (p: ReqPostV1SaasStudioIdScheduleCopyList) =>
    use<ReqPostV1SaasStudioIdScheduleCopyList, ResPostV1SaasStudioIdScheduleCopyList>(
        'post',
        `/v1/saas/studio/${p.id}/schedule/copy_list`,
        p
    )

export const getCleanScheduleList = (p: ReqGetV1SaasStudioIdCleanCourseList) =>
    use<ReqGetV1SaasStudioIdCleanCourseList, ResGetV1SaasStudioIdCleanCourseList>(
        'get',
        `/v1/saas/studio/${p.id}/clean_course/list`,
        p
    )

export const getTimeRangeScheduleList = (p: ReqGetV1SaasStudioIdScheduleCopyList) =>
    use<ReqGetV1SaasStudioIdScheduleCopyList, ResGetV1SaasStudioIdScheduleCopyList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/copy_list`,
        p
    )
export const getNotCleasrList = (p: ReqGetV1SaasStudioIdScheduleNoCleanList) =>
    use<ReqGetV1SaasStudioIdScheduleNoCleanList, ResGetV1SaasStudioIdScheduleNoCleanList>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/no_clean/list`,
        p
    )

export const helpReservationAll = (p: ReqPutV1SaasStudioIdScheduleScheduleIdReservationAll) =>
    use<ReqPutV1SaasStudioIdScheduleScheduleIdReservationAll, ResPutV1SaasStudioIdScheduleScheduleIdReservationAll>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/${p.schedule_id}/reservation_all`,
        p
    )

export const canAppointment = (p: ReqGetV1SaasStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse) =>
    use<ReqGetV1SaasStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse, ResGetV1SaasStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse>(
        'get',
        `/v1/saas/studio/${p.studioId}/schedule/${p.scheduleId}/curriculum/${p.curriculumId}/hasUse`,
        p
    )

export const getPkgListAll = (p: ReqGetV1SaasStudioIdPkgListAll) =>
    use<ReqGetV1SaasStudioIdPkgListAll, ResGetV1SaasStudioIdPkgListAll>(
        'get',
        `/v1/saas/studio/${p.id}/pkg/list_all`,
        p
    )

export const postIntegral = (p: ReqPostV1SaasStudioIdIntegral) =>
    use<ReqPostV1SaasStudioIdIntegral, ResPostV1SaasStudioIdIntegral>(
        'post',
        `/v1/saas/studio/${p.id}/integral`,
        p
    )

export const putIntegral = (p: ReqPutV1SaasStudioIdIntegral) =>
    use<ReqPutV1SaasStudioIdIntegral, ResPutV1SaasStudioIdIntegral>(
        'put',
        `/v1/saas/studio/${p.id}/integral`,
        p
    )
export const getIntegralInfo = (p: ReqGetV1SaasStudioIdIntegralIntegralIdInfo) =>
    use<ReqGetV1SaasStudioIdIntegralIntegralIdInfo, ResGetV1SaasStudioIdIntegralIntegralIdInfo>(
        'get',
        `/v1/saas/studio/${p.id}/integral/${p.integralId}/info`,
        p
    )

export const getIntegralListAll = (p: ReqGetV1SaasStudioIdIntegralListAll) =>
    use<ReqGetV1SaasStudioIdIntegralListAll, ResGetV1SaasStudioIdIntegralListAll>(
        'get',
        `/v1/saas/studio/${p.id}/integral/list/all`,
        p
    )
export const changeIntegralWeight = (p: ReqPatchV1SaasStudioIdIntegralFromIdToId) =>
    use<ReqPatchV1SaasStudioIdIntegralFromIdToId, ResPatchV1SaasStudioIdIntegralFromIdToId>(
        'patch',
        `/v1/saas/studio/${p.id}/integral/${p.fromId}/${p.toId}`,
        p
    )

export const deleteIntegral = (p: ReqDeleteV1SaasStudioIdIntegralIntegralId) =>
    use<ReqDeleteV1SaasStudioIdIntegralIntegralId, ResDeleteV1SaasStudioIdIntegralIntegralId>(
        'delete',
        `/v1/saas/studio/${p.id}/integral/${p.integralId}`,
        p
    )
export const putIntegralStatus = (p: ReqPutV1SaasStudioIdIntegralIntegralIdStatus) =>
    use<ReqPutV1SaasStudioIdIntegralIntegralIdStatus, ResPutV1SaasStudioIdIntegralIntegralIdStatus>(
        'put',
        `/v1/saas/studio/${p.id}/integral/${p.integralId}/status`,
        p
    )

export const scaleInfo = (p: ReqGetV1SaasStudioIdPointsScaleInfo) =>
    use<ReqGetV1SaasStudioIdPointsScaleInfo, ResGetV1SaasStudioIdPointsScaleInfo>(
        'get',
        `/v1/saas/studio/${p.id}/points/scaleInfo`,
        p
    )

export const putBuyScale = (p: ReqPutV1SaasStudioIdBuyScale) =>
    use<ReqPutV1SaasStudioIdBuyScale, ResPutV1SaasStudioIdBuyScale>(
        'put',
        `/v1/saas/studio/${p.id}/buyScale`,
        p
    )

export const putExchangeScale = (p: ReqPutV1SaasStudioIdExchangeScale) =>
    use<ReqPutV1SaasStudioIdExchangeScale, ResPutV1SaasStudioIdExchangeScale>(
        'put',
        `/v1/saas/studio/${p.id}/exchangeScale`,
        p
    )

export const getScheduleSetting = (p: ReqGetV1SaasStudioIdScheduleSetting) =>
    use<ReqGetV1SaasStudioIdScheduleSetting, ResGetV1SaasStudioIdScheduleSetting>(
        'get',
        `/v1/saas/studio/${p.id}/schedule/setting`,
        p
    )

export const putScheduleSetting = (p: ReqPutV1SaasStudioIdScheduleSetting) =>
    use<ReqPutV1SaasStudioIdScheduleSetting, ResPutV1SaasStudioIdScheduleSetting>(
        'put',
        `/v1/saas/studio/${p.id}/schedule/setting`,
        p
    )