import { NavMenu } from '@/features';

import Bread from './bread';

const Header = () => {
  return (
    <div className="h-full flex items-center justify-between">
      <Bread />
      <NavMenu />
    </div>
  );
};

export default Header;
