/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Card, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';

const { Grid } = Card;

interface IProps {
  value?: any;
  onChange?: (val: any) => void;
}
const Rooms: React.FC<IProps> = ({ value = [], onChange }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleDelete = (name: string) => onChange?.(value.filter((_: any) => _.roomName !== name));

  const handleFinish = (val: any) => {
    onChange?.(value.concat(val));
    setVisible(false);
  };

  return (
    <>
      <Card extra={<a onClick={() => setVisible(true)}>新增教室</a>} title="教室列表">
        {value.map((_: any) => (
          <Grid className="flex items-start justify-between w-1/4" key={_.roomName}>
            <Space direction="vertical">
              <Typography.Text ellipsis style={{ width: 80 }}>
                {_.roomName}
              </Typography.Text>
              <div>
                <em className="mr-8">{_.roomArea ?? '--'}平米</em>
                <em>{_.maxPerson}人</em>
              </div>
            </Space>
            <a onClick={() => handleDelete(_.roomName)}>删除</a>
          </Grid>
        ))}
      </Card>

      <Modal
        afterClose={form.resetFields}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setVisible(false)}
        onOk={form.submit}
        open={visible}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            label="教室名称"
            name="roomName"
            required
            rules={[
              {
                validator(_, v) {
                  if (!v) return Promise.reject(new Error('请输入教室名称'));
                  if (value.find((_: any) => _.roomName === v)) return Promise.reject(new Error('教室名称已存在'));
                  return Promise.resolve();
                },
              },
            ]}>
            <Input placeholder="请输入教室名称" />
          </Form.Item>
          <Form.Item label="教室面积" name="roomArea">
            <InputNumber min={0} placeholder="请输入教室面积，单位：平米" stringMode style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="推荐人数" name="maxPerson" rules={[{ required: true }]}>
            <InputNumber min={0} placeholder="输入推荐人数，单位：人" stringMode style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item initialValue={1} label="一室多用" name="isUse" rules={[{ required: true, message: '请选择一室多用' }]}>
            <Select
              options={[
                { type: 1, name: '可以' },
                { type: 0, name: '不可以' },
              ].map(({ type, name }) => ({
                label: name,
                value: type,
              }))}
              placeholder="请选择一室多用"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="教室设备" name="roomDevices">
            <Input.TextArea
              allowClear
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={200}
              placeholder="如：雅马哈音响NS-AW294；智能镜面；"
              showCount
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Rooms;
