import use, { downloadInterceptor } from '@/services/saas.request';
import {
  ReqGetV1SaasStudioStudioIdFundList,
  ResGetV1SaasStudioStudioIdFundList,
  ReqGetV1SaasStudioStudioIdRefundList,
  ResGetV1SaasStudioStudioIdRefundList,
  ReqPostV1SaasStudioIdFundList,
  ResPostV1SaasStudioIdFundList,
  ReqGetV1ReceptionStudioIdTeachersListAll,
  ResGetV1ReceptionStudioIdTeachersListAll,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdInfo,
  ResGetV1ReceptionStudioIdScheduleScheduleIdInfo,
  ReqGetV1SaasStudioStudioIdDelayList,
  ReqGetV1SaasStudioStudioIdFreeClassList,
  ResGetV1SaasStudioStudioIdDelayList,
  ResGetV1SaasStudioStudioIdFreeClassList,
} from '@/services/params';

export const getFundStudio = (p: ReqGetV1SaasStudioStudioIdFundList) =>
  use<ReqGetV1SaasStudioStudioIdFundList, ResGetV1SaasStudioStudioIdFundList>(
    'get',
    `/v1/saas/studio/${p.studioId}/fund/list`,
    p,
  );

  export const getRefundStudio = (p: ReqGetV1SaasStudioStudioIdRefundList) =>
  use<ReqGetV1SaasStudioStudioIdRefundList, ResGetV1SaasStudioStudioIdRefundList>(
    'get',
    `/v1/saas/studio/${p.studioId}/refund/list`,
    p,
  );
export const getDelayStudio = (p: ReqGetV1SaasStudioStudioIdDelayList) =>
  use<ReqGetV1SaasStudioStudioIdDelayList, ResGetV1SaasStudioStudioIdDelayList>(
    'get',
    `/v1/saas/studio/${p.studioId}/delay/list`,
    p,
  );
export const getGiveStudio = (p: ReqGetV1SaasStudioStudioIdFreeClassList) =>
  use<ReqGetV1SaasStudioStudioIdFreeClassList, ResGetV1SaasStudioStudioIdFreeClassList>(
    'get',
    `/v1/saas/studio/${p.studioId}/free/class/list`,
    p,
  );
export const exportFundStudio = (p: ReqPostV1SaasStudioIdFundList, type: string) =>
  use<ReqPostV1SaasStudioIdFundList, ResPostV1SaasStudioIdFundList>(
    'post',
    `/v1/saas/brand/${p.id}/vip/export`,
    p,
    downloadInterceptor<ResPostV1SaasStudioIdFundList>(type),
  );

export const getTeachersStudioDesk = (p: ReqGetV1ReceptionStudioIdTeachersListAll) =>
  use<ReqGetV1ReceptionStudioIdTeachersListAll, ResGetV1ReceptionStudioIdTeachersListAll>(
    'get',
    `/v1/reception/studio/${p.id}/teachers/list_all`,
    p,
  );

export const getScheduleDetailDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdInfo) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdInfo, ResGetV1ReceptionStudioIdScheduleScheduleIdInfo>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/info`,
    p,
  );
