import { CheckOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import { Space } from 'antd';

interface IProps {
  type?: boolean;
}

const YesNo = ({ type }: IProps) => {
  if (type === true)
    return (
      <Space className="font-bold">
        <CheckOutlined className="text-green-500" />
        <span>是</span>
      </Space>
    );
  if (type === false)
    return (
      <Space className="font-bold">
        <CloseOutlined className="text-red-500" />
        <span>否</span>
      </Space>
    );
  return (
    <Space className="font-bold">
      <QuestionOutlined className="text-gray-500" />
      <span>未知</span>
    </Space>
  );
};

export default YesNo;
