import type { FC, MouseEventHandler } from 'react';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Button, Popover } from 'antd';

import { IData, IRange, week } from './common';
import AddCard from './addCard';

const cssBorder = 'border-solid border-slate-300 border-0';

interface IRenderMonthData {
  date: number; // 本月第几天
  timeStamp: number; // 当天开始的时间
  data: {
    id: string;
    start: number;
    end: number;
    title: string;
    payload: unknown;
  }[];
}
const transformData: (_: IData, no: number) => IData = (_, no) => {
  return _.filter((t) => dayjs(t.start).date() === no);
};

interface IProps {
  data: IData;
  range: IRange;
  clickCardCb?: (data: unknown) => void;
  clickEmptyCb?: (startStamp: number) => void;
}
const Month: FC<IProps> = ({ data, range, clickCardCb, clickEmptyCb }) => {
  const allBlock = useMemo<(IRenderMonthData | null)[]>(() => {
    const start = dayjs(range.start).day() - 1; // 第一天是星期几-1，得到数组索引
    const count = dayjs(range.end).date(); // 本月总共多少天
    const total = Array(6 * 7).fill(null);
    for (let i = start; i < count + start; i++) {
      total[i] = {
        date: i - start + 1,
        timeStamp: dayjs(range.start)
          .add(i - start, 'd')
          .valueOf(),
        data: transformData(data, i - start + 1),
      };
    }
    return total;
  }, [range, data]);

  const renderBlock = useMemo(() => {
    const init: (IRenderMonthData | null)[][] = Array(6).fill(Array(7).fill(null));
    let result = init.map((_, i) => _.map((_, j) => allBlock[i * 7 + j]));
    if (result[5][0] === null) {
      result = result.slice(0, 5);
    }
    return result;
  }, [allBlock]);
  const handleClickData =
    (d: unknown): MouseEventHandler<HTMLSpanElement> =>
    (e) => {
      e.stopPropagation();
      clickCardCb?.(d);
    };

  return (
    <div className="flex flex-col">
      <div className="flex-none h-8 text-sm font-bold flex">
        {week.map((day) => (
          <div className="text-center" key={day} style={{ width: `${100 / 7}%` }}>
            <span>星期{day}</span>
          </div>
        ))}
      </div>

      <div className={`flex-1 h-0 overflow-y-auto flex flex-col ${cssBorder} border-l border-t`}>
        {renderBlock.map((w, rowIdx) => (
          <div className={`flex-auto ${cssBorder} border-b flex`} key={rowIdx}>
            {w.map((d, colIdx) => (
              <div
                className={`${cssBorder} border-r p-1 h-32 flex flex-col gap-2 font-mono ${
                  d ? (dayjs().isSame(d?.timeStamp, 'day') ? 'bg-slate-100' : '') : 'bg-slate-50'
                }`}
                key={colIdx}
                style={{ width: `${100 / 7}%` }}>
                <div className="font-bold">{d?.date}</div>
                {d?.data.length ? (
                  <Popover
                    color="#0f172a"
                    content={
                      <div className="w-64 flex flex-col gap-2">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            clickEmptyCb?.(d?.timeStamp);
                          }}
                          type="primary">
                          新增排期
                        </Button>
                        <div className="flex flex-col gap-1">
                          {d.data.map((_, tIdx) => (
                            <div className="text-sm text-white font-mono flex items-center gap-2 pr-2" key={_.id}>
                              <span className="flex-none w-8 text-xs">{tIdx + 1}.</span>
                              <span
                                className="flex-1 w-0 text-right overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer hover:text-blue-600"
                                onClick={handleClickData(_.payload)}>
                                {_.title}（{dayjs(_.start).format('HH:mm')}）
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    destroyTooltipOnHide
                    placement="right"
                    trigger={['hover', 'click']}>
                    <div className="cursor-pointer">
                      {d.data.slice(0, 3).map((_) => (
                        <div className="text-sm flex items-center gap-2 pr-2" key={_.id}>
                          <span className="flex-none w-1.5 h-1.5 bg-slate-500 rounded-full" />
                          <span className="flex-1 w-0 overflow-hidden text-ellipsis whitespace-nowrap">{_.title}</span>
                        </div>
                      ))}
                      {d.data.length > 3 ? (
                        <div className="text-sm pr-2 text-gray-500 text-right">另外还有{d.data.length - 3}个</div>
                      ) : null}
                    </div>
                  </Popover>
                ) : d ? (
                  <AddCard onClick={() => clickEmptyCb?.(d?.timeStamp)} />
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Month;
