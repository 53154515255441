/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Grid, Input, Row, Select, Space } from 'antd';
import { DatePicker } from '@/components';

import NumberRange from './NumberRange';
import styles from './index.module.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  initialValues?: any; // 不要把Filter.useState的结果传进来，会导致重置无法回到最初始的值
  options: {
    title: string;
    type: 'input' | 'numberrange' | 'select' | 'datepicker' | 'daterange';
    name: string;
    placeholder?: any;
    selects?: { label: any; value: any }[];
    showSearch?: boolean;
    initialValue?: any;
    range?: [number?, number?];
    show?: boolean;
    onChange?: (value: any) => void
  }[];
  onFilter?: (value?: any) => void;
}

const { Item } = Form;
const { useBreakpoint } = Grid;

const wrapCol = { lg: 12, xl: 8, xxl: 6 };

const Filter: React.FC<IProps> & { useFilter: typeof useState<Record<string, unknown>> } = ({
  className,
  initialValues,
  options,
  onFilter,
  disabled = false,
}) => {
  const [form] = Form.useForm();
  const { xl, xxl } = useBreakpoint();
  const [fold, setFold] = useState(false);
  const length = options.filter(_ => _.show === false ? false : true).length;
  const hidden = (i: number) => !fold && i >= (xxl ? 3 : xl ? 2 : 1);

  const offsetCalc = useMemo(() => {
    if (xxl) return (3 - (!fold ? (length >= 3 ? 3 : length) : length % 4)) * 6;
    if (xl) return (2 - (!fold ? (length >= 2 ? 2 : length) : length % 3)) * 8;
    return (1 - (!fold ? (length >= 1 ? 1 : length) : length % 2)) * 12;
  }, [length, xxl, xl, fold]);

  const showFold = useMemo(() => {
    if (xxl && length < 4) return false;
    return !(xl && length < 3);
  }, [length, xxl, xl]);

  const handleFinish = async (value: any) => {
    onFilter?.({...value,pageNo: '1'});
  };

  const handleReset = () => {
    form.resetFields();
    onFilter?.(form.getFieldsValue());
  };
  return (
    <Form
      className={`${styles.filter} ${className}`}
      disabled={disabled}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleFinish}
      requiredMark
    >
      <Row gutter={24} wrap>
        {options.filter(_ => _.show === false ? false : true).map((_, i) => {
          // Input
          if (_.type === 'input')
            return (
              <Col key={_.name} {...(hidden(i) ? { span: 0 } : wrapCol)}>
                <Item hidden={hidden(i)} label={_.title} name={_.name}>
                  <Input allowClear placeholder={_.placeholder} />
                </Item>
              </Col>
            );
          // Number Range
          if (_.type === 'numberrange')
            return (
              <Col key={_.name} {...(hidden(i) ? { span: 0 } : wrapCol)}>
                <Item hidden={hidden(i)} label={_.title} name={_.name}>
                  <NumberRange placeholder={_.placeholder} range={_.range} />
                </Item>
              </Col>
            );
          // Select
          if (_.type === 'select')
            return (
              <Col key={_.name} {...(hidden(i) ? { span: 0 } : wrapCol)}>
                <Item hidden={hidden(i)} label={_.title} name={_.name}>
                  <Select
                    allowClear
                    filterOption={(input, option: any) =>
                      option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={e => _.onChange?.(e)}
                    placeholder={_.placeholder}
                    showSearch={_.showSearch}
                    
                  >
                    {_.selects?.map((_) => (
                      <Select.Option key={_.value} value={_.value}>
                        {_.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            );
          // DatePicker.Range
          if (_.type === 'daterange')
            return (
              <Col key={_.name} {...(hidden(i) ? { span: 0 } : wrapCol)}>
                <Item
                  getValueProps={(v) => ({ value: v?.length && v.map((_: number | undefined) => _ && dayjs(_)) })}
                  hidden={hidden(i)}
                  label={_.title}
                  name={_.name}
                  normalize={(v) => {
                    if (v === null) return null;
                    const [start, end] = v;
                    return [start?.startOf('day').valueOf(), end?.endOf('day').valueOf()];
                  }}
                >
                  <DatePicker.RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
            );

          return null;
        })}
        <Col offset={offsetCalc} {...wrapCol}>
          <Item className="text-right" label=" ">
            <Space size="middle">
              <Button htmlType="submit" icon={<SearchOutlined />} type="primary">
                查询
              </Button>
              <Button icon={<RollbackOutlined />} onClick={handleReset}>
                重置
              </Button>
              {showFold ? (
                <>
                  <Divider type="vertical" />
                  {fold ? (
                    <a onClick={() => setFold(false)}>
                      收起 <CaretUpOutlined />
                    </a>
                  ) : (
                    <a onClick={() => setFold(true)}>
                      展开 <CaretDownOutlined />
                    </a>
                  )}
                </>
              ) : null}
            </Space>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

Filter.useFilter = useState;

export default Filter;
