import use from '@/services/saas.request';
import {
  ReqGetV1SaasStudioIdReduceReduceIdGiveList,
  ReqGetV1SaasStudioIdReduceReduceIdInfo,
  ReqGetV1SaasStudioIdReduceList,
  ReqPostV1SaasStudioIdReduce,
  ReqPutV1SaasStudioIdReduceReduceId,
  ResGetV1SaasStudioIdReduceReduceIdInfo,
  ResGetV1SaasStudioIdReduceList,
  ResPutV1SaasStudioIdReduceReduceId,
  ResGetV1SaasStudioIdReduceReduceIdGiveList,
  ReqGetV1SaasStudioIdReduceReduceIdGrantIdGiveVipList,
  ResGetV1SaasStudioIdReduceReduceIdGrantIdGiveVipList,
  ReqDeleteV1SaasStudioIdReduceReduceId,
  ResDeleteV1SaasStudioIdReduceReduceId
} from '@/services/params';

export const getReduceList = (p: ReqGetV1SaasStudioIdReduceList) =>
  use<ReqGetV1SaasStudioIdReduceList, ResGetV1SaasStudioIdReduceList>(
    'get',
    `/v1/saas/studio/${p.id}/reduce/list`,
    p
  )

export const postReduce = (p: ReqPostV1SaasStudioIdReduce) =>
  use<ReqPostV1SaasStudioIdReduce, ReqPostV1SaasStudioIdReduce>(
    'post',
    `/v1/saas/studio/${p.id}/reduce`,
    p
  )

export const putReduce = (p: ReqPutV1SaasStudioIdReduceReduceId) =>
  use<ReqPutV1SaasStudioIdReduceReduceId, ResPutV1SaasStudioIdReduceReduceId>(
    'put',
    `/v1/saas/studio/${p.id}/reduce/${p.reduceId}`,
    p
  )

export const getReduce = (p: ReqGetV1SaasStudioIdReduceReduceIdInfo) =>
  use<ReqGetV1SaasStudioIdReduceReduceIdInfo, ResGetV1SaasStudioIdReduceReduceIdInfo>(
    'get',
    `/v1/saas/studio/${p.id}/reduce/${p.reduceId}/info`,
    p
  )
export const getReduceGiveList = (p: ReqGetV1SaasStudioIdReduceReduceIdGiveList) =>
  use<ReqGetV1SaasStudioIdReduceReduceIdGiveList, ResGetV1SaasStudioIdReduceReduceIdGiveList>(
    'get',
    `/v1/saas/studio/${p.id}/reduce/${p.reduceId}/give/list`,
    p
  )

  export const getReduceGiveVipList = (p: ReqGetV1SaasStudioIdReduceReduceIdGrantIdGiveVipList) =>
  use<ReqGetV1SaasStudioIdReduceReduceIdGrantIdGiveVipList, ResGetV1SaasStudioIdReduceReduceIdGrantIdGiveVipList>(
    'get',
    `/v1/saas/studio/${p.id}/reduce/${p.reduceId}/${p.grantId}/give/vip/list`,
    p
  )
  export const deletetReduce = (p: ReqDeleteV1SaasStudioIdReduceReduceId) =>
  use<ReqDeleteV1SaasStudioIdReduceReduceId, ResDeleteV1SaasStudioIdReduceReduceId>(
    'delete',
    `/v1/saas/studio/${p.id}/reduce/${p.reduceId}`,
    p
  )