import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { selectUser } from '@/model/userSlice';
import { useAppSelector } from '@/hooks';

interface IProps {
  action: string;
  value?: string;
  type?: string;
  onChange?: (value: string) => void;
  disable?: boolean;
  children?: React.ReactNode
}

const UploadFile: React.FC<IProps> = ({ action, onChange, disable = false, type = '', children, value }) => {
  const { accessToken } = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [flag, setFlag] = useState(false);

  const beforeUpload = (file: RcFile) => {
    // if (!isXlsx) message.error('请上传xlsx格式的文件');
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) message.error('请上传小于5M的文件');
    return isLt2M;
  };

  const handleChange = ({ file }: UploadChangeParam) => {
    setLoading(file.status === 'uploading');

    if (file.status === 'error') {
      return message.error({
        content: '上传失败！',
        key: 'error',
      });
    }
    if (file.status === 'done') {
       if (file.response.result) {
        setName(file.name)
        onChange && onChange(file.response?.result);
        setFlag(true);
        message.success({
          content: '上传成功！',
          key: 'success',
        });
      } else message.error('存在错误数据')
    }
  };

  return (
    <Upload
      accept={type}
      action={action}
      beforeUpload={beforeUpload}
      disabled={loading  || disable}
      headers={{
        'x-AppId': 'cyber-saas',
        Authorization: `Bearer ${accessToken}`,
      }}
      maxCount={1}
      name="file"
      onChange={handleChange}
      showUploadList={false}
    >
      {children ? children : <>
        <Button disabled={disable} icon={<UploadOutlined />} >
          {flag ? '重新上传' : '上传'}

        </Button>
        <a href={value} rel="noreferrer" target='_blank'>{name}</a>
      </>}
    </Upload>
  );
};

export default UploadFile;
