import { Button, Result, Typography } from 'antd';
import { TECH_FEEDBACK_PHONE } from '@/utils/constant';

const { Paragraph, Text } = Typography;

const Error = () => {
  return (
    <Result
      extra={
        <Button onClick={() => window.location.assign(window.location.origin)} type="primary">
          刷新重试
        </Button>
      }
      status="error"
      title="系统异常"
    >
      <Paragraph>
        <Text strong>系统在向服务器获取基础数据时出现错误：</Text>
      </Paragraph>
      <Paragraph>1. 请先检查您的网络是否出现异常情况。</Paragraph>
      <Paragraph>2. 也可能由于您违反系统使用准则，被系统拒绝访问。</Paragraph>
      <Paragraph>3. 如果持续出现错误，请联系官方进行反馈。联系电话: {TECH_FEEDBACK_PHONE}</Paragraph>
    </Result>
  );
};

export default Error;
