/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';
import { message, Modal } from 'antd';
import { useAppSelector } from '@/hooks';
import { putPkgStatus } from '@/services';
import { selectUser } from '@/model/userSlice';
import { useMutation } from '@tanstack/react-query';

interface IProps {
  course: boolean
  id: string;
  cb?: () => void;
  action: '1' | '3'; // 1 上架 3 下架
}

const Putaway: React.FC<PropsWithChildren<IProps>> = ({ children, id, cb, action, course }) => {
  const { brandInfo } = useAppSelector(selectUser);
  const mutPut = useMutation(putPkgStatus);

  const handleFinish = () => {
    if (!course) return Modal.warning({
      closable: true,
      title: '提示',
      okText: '关闭',
      content: '套餐内没有课程，无法上架'
    })
    Modal.confirm({
      title: action === '1' ? '确定上架？' : '确定下架？',
      onOk: async () => {
        const { success, msg } = await mutPut.mutateAsync({ id: brandInfo?.id || '', pkgId: id, action });
        if (success) cb?.();
        else message.error(msg);
      },
    });
  }


  return (
    <>
      {React.isValidElement(children) && React.cloneElement<any>(children, { onClick: handleFinish })}
    </>
  );
};

export default Putaway;
