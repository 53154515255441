import type { FC } from 'react';
import clsx from 'clsx';
import { Iconfont } from '@/components';
import { Space } from 'antd';

export const serviceDevices = [
  { key: '1', name: '休息区', icon: 'common-rest' },
  { key: '2', name: '冷气', icon: 'common-cool' },
  { key: '3', name: '暖气', icon: 'common-warm' },
  { key: '4', name: 'wifi', icon: 'common-wifi' },
  { key: '5', name: '充电宝', icon: 'common-battery' },
  { key: '6', name: '贩卖机', icon: 'common-vending' },
  { key: '7', name: '咖啡机', icon: 'common-coffee' },
  { key: '8', name: '禁烟', icon: 'common-nosmoke' },
  { key: '9', name: '用餐区', icon: 'common-dining' },
  { key: '10', name: '停车位', icon: 'common-park' },
  { key: '11', name: '淋浴', icon: 'common-bathe' },
  { key: '12', name: '急救', icon: 'common-aid' },
];

interface IProps {
  value?: string[];
  onChange?: (val: string[]) => void;
}

const ServiceDevices: FC<IProps> = ({ value = [], onChange }) => {
  const handleClick = (key: string) => {
    let result = value;
    if (result.includes(key)) result = result.filter((_) => _ !== key);
    else result = [...result, key];

    onChange && onChange(result);
  };

  return (
    <Space wrap>
      {serviceDevices.map((_) => (
        <div
          className={clsx(
            'flex flex-col items-center justify-center w-16 h-16 text-lg text-gray-300 outline-1 outline outline-gray-300 rounded cursor-pointer',
            {
              'text-gray-900 ring-gray-900': value.includes(_.key),
            },
          )}
          key={_.key}
          onClick={() => handleClick(_.key)}
        >
          <Iconfont type={_.icon} />
          <span className="mt-2 text-xs">{_.name}</span>
        </div>
      ))}
    </Space>
  );
};

export default ServiceDevices;
