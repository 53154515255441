/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';
import { useAppNavigate, useAppSelector } from '@/hooks';
import { routes } from '@/routes/brand';
import { selectUser } from '@/model/userSlice';

// const items = routes.map(({ name, path, icon, children }) => ({
//   key: path,
//   label: name,
//   icon,
//   children: children?.map((c) => ({
//     key: c.path,
//     label: c.name,
//   })),
// }));

const Aside = () => {
  const { userinfo } = useAppSelector(selectUser);
  const { pathname } = useLocation();
  const { appNavigate } = useAppNavigate();
  const items = useMemo<MenuProps['items']>(() => {
    return routes.map(({ name, path, icon, children, KEY }) => {
      // 超级管理员permissions === '*'
      if ((userinfo?.permissions as any)?.['*'] || (userinfo?.permissions as any)[KEY])
        return {
          key: path,
          label: name,
          icon,
          children: children?.map((c) => {
            if ((userinfo?.permissions as any)?.['*'] || (userinfo?.permissions as any)[c.KEY])
              return {
                key: c.path,
                label: c.name,
              };
            return undefined as any;
          }),
        };
      return undefined as any;
    });
  }, [userinfo]);
  const keys = useMemo(
    () =>
      pathname.split('/').reduce((prev, next) => {
        if (next) return prev.concat((prev[prev.length - 1] || '') + `/${next}`);
        return prev;
      }, [] as string[]),
    [pathname],
  );

  return (
    <Menu
      defaultOpenKeys={keys}
      forceSubMenuRender
      items={items}
      mode="inline"
      onClick={({ keyPath }) => keyPath[0] !== pathname && appNavigate(keyPath[0])}
      selectedKeys={keys}
    />
  );
};

export default Aside;
