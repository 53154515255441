import { useState } from 'react';
import { Tabs } from 'antd';
import { Copyright } from '@/features';

import FormPassword from './FormPassword';
import FormCaptcha from './FormCaptcha';
import Signup from './Signup';
import Forget from './Forget';

const LoginForm = () => {
  const [block, setBlock] = useState(1); // 1: 登录, 2:注册, 3: 忘记密码

  return (
    <div className="h-screen bg-white flex flex-col justify-between items-center">
      <p>{` `}</p>

      {block === 1 ? (
        <div className="w-80">
          <h1 className="font-medium">登录智械舞客</h1>
          <Tabs
            centered
            defaultActiveKey="1"
            items={[
              {
                label: '密码登录',
                key: '1',
                children: (
                  <div className="h-80">
                    <FormPassword setBlock={setBlock} />
                  </div>
                ),
              },
              {
                label: '手机验证码登录',
                key: '2',
                children: (
                  <div className="h-80">
                    <FormCaptcha />
                  </div>
                ),
              },
            ]}
            size="large"
          />
        </div>
      ) : null}

      {block === 2 ? (
        <div className="w-80">
          <Signup setBlock={setBlock} />
        </div>
      ) : null}
      {block === 3 ? (
        <div className="w-80">
          <Forget setBlock={setBlock} />
        </div>
      ) : null}

      <Copyright />
    </div>
  );
};

export default LoginForm;
