/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, ReactElement } from 'react';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { IData, IRange, week } from './common';
import AddCard from './addCard';

dayjs.extend(weekOfYear);

interface IRenderWeekData {
  time: string;
  hour: number;
  minute: number;
  data: Array<IData>;
}
const transformData: (_: IData) => IRenderWeekData[] = (data) => {
  const m: { [k: string]: any } = data.reduce((prev, next) => {
    const timeObj = dayjs(next.start);
    const minuteKey = timeObj.startOf('m').format('HH:mm');
    const dayIdx = timeObj.day() - 1 < 0 ? 6 : timeObj.day() - 1;

    const d = {
      ...next,
      id: next.id,
      start: timeObj.format('HH:mm'),
      end: dayjs(next.end).format('HH:mm'),
    };
    if (prev[minuteKey]) {
      prev[minuteKey][dayIdx].push(d);
    } else {
      const dataArr: (typeof d)[][] = [[], [], [], [], [], [], []];
      dataArr[dayIdx] = [d];
      prev[minuteKey] = dataArr;
    }
    return prev;
  }, {} as Record<string, any>);

  return Object.entries(m)
    .map(([k, v]) => {
      const [h, m] = k.split(':');
      return {
        time: k,
        hour: parseInt(h),
        minute: parseInt(m),
        data: v,
      };
    })
    .sort((_, __) => {
      if (_.hour === __.hour) return _.minute - __.minute;
      return _.hour - __.hour;
    });
};

interface IProps {
  data: IData;
  range: IRange;
  renderCard?: (data: unknown) => ReactElement;
  clickCardCb?: (data: unknown) => void;
  clickEmptyCb?: (startStamp: number) => void;
  clickCopy?: (startStamp: number) => void
}
const Week: FC<IProps> = ({ data, range, renderCard, clickCardCb, clickEmptyCb, clickCopy }) => {
  const weekArray = useMemo(() => {
    const result = [];

    for (let i = 0; i < 7; i++) {
      result[i] = {
        day: `周${week[i]}`,
        start: dayjs(range.start).add(i, 'day').valueOf(),
      };
    }

    return result;
  }, [range]);
  const renderData = useMemo(() => transformData(data), [data]);

  return (
    <div className="max-h-[720px] flex flex-col">
      <div className="h-8 bg-white text-sm font-bold border-solid border-0 border-b border-slate-300 flex">
        <div style={{ width: '5%' }}>第{dayjs(range.start).week()}周</div>
        {weekArray.map(({ day, start }) => (
          <div className="flex justify-center gap-1 cursor-pointer" key={day} onClick={() => clickCopy?.(start)} style={{ width: `${95 / 7}%` }} >
            <span>{dayjs(start).format('M月DD日')}</span>
            <span>{day}</span>
          </div>
        ))}
      </div>

      {renderData.length === 0 ? <div className='h-96 flex justify-center items-center font-bold text-xl'>本周暂无排课</div> : null}

      <div className="flex-1 h-0 overflow-y-auto">
        {renderData.map((timeQue) => (
          <div className="flex border-solid border-0 border-b border-slate-300" key={timeQue.time}>
            <div className="p-1 border-solid border-0 border-r border-slate-300 font-mono" style={{ width: '5%' }}>
              {timeQue.time}
            </div>
            {timeQue.data.map((_: any, dayIdx: number) => (
              <div
                className={`border-solid border-0 border-r border-slate-300 flex flex-col gap-1 p-1 ${dayjs(range.start).add(dayIdx, 'd').isSame(Date.now(), 'd') ? 'bg-slate-100' : ''
                  }`}
                key={dayIdx}
                style={{ width: `${95 / 7}%` }}>
                {_.length ? (
                  _.map((task: IRenderWeekData['data'][number][number]) => (
                    <div
                      className="text-sm bg-white border-solid border rounded overflow-hidden cursor-pointer hover:shadow-xl duration-200 transition-all"
                      key={task.id}
                      onClick={() => clickCardCb?.(task.payload)}
                      style={{ borderColor: task.conflict ? task.conflict : task.color, border: task.conflict ? `1px solid ${task.conflict}` : `1px solid ${task.color}` }}>
                      <div
                        className="p-1 flex items-center gap-1 text-white"
                        style={{ backgroundColor: task.conflict ? task.conflict : task.color, }}>
                        <svg
                          className="w-3 h-3"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span>
                          {task.start}&nbsp;至&nbsp;{task.end}
                        </span>
                      </div>

                      <div className="p-1 flex flex-col font-mono">
                        {renderCard ? renderCard(task.payload) : <div className="text-sm font-bold">{task.title}</div>}
                      </div>
                    </div>
                  ))
                ) : (
                  <AddCard
                    onClick={() =>
                      clickEmptyCb?.(
                        dayjs(range.start).add(dayIdx, 'd').hour(timeQue.hour).minute(timeQue.minute).valueOf(),
                      )
                    }
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Week;
