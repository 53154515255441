import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { TabletOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { RE__MOBILE } from '@cyberdancer/regexp';
import { useAppDispatch } from '@/hooks';
import { action__login } from '@/model/userSlice';
import { loginPasswd } from '@/services';

interface IProps {
  setBlock: (type: number) => void;
}

type Values = Parameters<typeof loginPasswd>[number];

const FormPassword = ({ setBlock }: IProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const [passwdShow, setPasswdShow] = useState('password');
  const [form] = Form.useForm();

  const { mutateAsync, isLoading } = useMutation(loginPasswd);

  const handleSubmit = async (v: Values) => {
    const { success, result, msg } = await mutateAsync(v);
    if (success) {
      dispatch(action__login(result.accessToken));
      message.success('登录成功', 2, () => navigate(searchParams.get('from') || '/', { replace: true }));
    } else message.error(msg);
  };

  return (
    <Form<Values> form={form} onFinish={handleSubmit}>
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: '手机号不能为空',
          },
          {
            pattern: RE__MOBILE,
            message: '手机号格式错误',
          },
        ]}
      >
        <Input allowClear placeholder="手机号" prefix={<TabletOutlined />} size="large" />
      </Form.Item>
      <Form.Item
        name="passwd"
        rules={[
          {
            required: true,
            message: '密码不能为空',
          },
        ]}
      >
        <Input
          allowClear
          placeholder="密码"
          prefix={<LockOutlined />}
          size="large"
          suffix={
            passwdShow ? (
              <EyeInvisibleOutlined onClick={() => setPasswdShow('')} />
            ) : (
              <EyeOutlined onClick={() => setPasswdShow('password')} />
            )
          }
          type={passwdShow}
        />
      </Form.Item>
      <p className="text-right">
        <a onClick={() => setBlock(3)}>忘记密码</a>
      </p>
      <Form.Item>
        <Button block htmlType="submit" loading={isLoading} size="large" type="primary">
          登录
        </Button>
      </Form.Item>
      <p className="text-center">
        <a className="text-gray-500" onClick={() => setBlock(2)}>
          注册帐号
        </a>
      </p>
    </Form>
  );
};

export default FormPassword;
