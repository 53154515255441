/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from 'react';
import { cloneElement, isValidElement, useState } from 'react';
import { CloudDownloadOutlined, ColumnHeightOutlined, ReloadOutlined } from '@ant-design/icons';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Alert, Button, Dropdown, Space, Tooltip } from 'antd';
import AuthButton from '../AuthButton';

interface IProps {
  left?: ReactNode[];
  right?: ReactNode[];
  extra?: ReactNode;
  refetch?: (...p: any) => Promise<any> | any;
  download?: (keys: string[]) => Promise<any> | any;
  children?: [JSX.Element] | JSX.Element;
  setDisabled?: (_: boolean) => void;
  prohibit?: (v: any) => any;
  ButtonKey?: string
  PageKey?: string
}

const TableWrap = ({ left, right, extra, refetch, download, children, setDisabled, prohibit, ButtonKey, PageKey }: IProps) => {
  const [size, setSize] = useState<SizeType>('large');

  const [showSelect, setShowSelect] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const handleDownload = async () => {
    await download?.(selectedRowKeys);
    setSelectedRowKeys([]); // download调用成功后，取消选择
  };

  const items = [
    {
      key: 'large',
      label: <a onClick={() => setSize('large')}>默认</a>,
    },
    {
      key: 'middle',
      label: <a onClick={() => setSize('middle')}>中等</a>,
    },
    {
      key: 'small',
      label: <a onClick={() => setSize('small')}>紧凑</a>,
    },
  ];

  return (
    <div className="bg-white rounded p-6 shadow">
      <div className="flex items-center justify-between mb-6">
        <div>
          <Space>{left}</Space>
        </div>

        <div>
          <Space>
            {right}

            {download ? (
              <Tooltip title="下载数据">
                <AuthButton
                  ButtonKey={ButtonKey}
                  PageKey={PageKey}
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    setDisabled?.(!showSelect);
                    setShowSelect((prevState) => !prevState);
                  }}
                  shape="circle"
                />
              </Tooltip>
            ) : null}

            {refetch ? (
              <Tooltip title="刷新">
                <Button disabled={showSelect} icon={<ReloadOutlined />} onClick={refetch} shape="circle" />
              </Tooltip>
            ) : null}

            <Tooltip title="密度">
              <Dropdown menu={{ items }} trigger={['click']}>
                <Button icon={<ColumnHeightOutlined />} shape="circle" />
              </Dropdown>
            </Tooltip>
          </Space>
        </div>
      </div>
      {extra ? <div className="mb-6">{extra}</div> : null}

      {showSelect ? (
        <div className="mb-6">
          <Alert
            message={
              <div className="flex items-center justify-between">
                <span>已选择 {selectedRowKeys.length} 项</span>
                <div>
                  <Button
                    onClick={() => {
                      setSelectedRowKeys([]);
                    }}
                    type="link">
                    取消所有勾选
                  </Button>
                  <Button onClick={handleDownload} type="link">
                    下载勾选数据
                  </Button>
                </div>
              </div>
            }
            type="info"
          />
        </div>
      ) : null}

      {isValidElement(children) &&
        cloneElement(children, {
          size,
          rowSelection: showSelect
            ? {
              preserveSelectedRowKeys: true,
              selectedRowKeys,
              onChange: (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys),
              getCheckboxProps: prohibit,
            }
            : undefined,
        } as any)}
    </div>
  );
};

export default TableWrap;
