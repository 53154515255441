import React, { useEffect, useState } from 'react';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Space, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';

interface IProps {
  action: string;
  value?: string;
  onChange?: (value: string) => void;
  aspect?: number;
  type?: string
}

const UploadSingleImage: React.FC<IProps> = ({ action, onChange, value, aspect, type = '.png, .jpg, .jpeg' }) => {
  const { accessToken } = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    value && setUrl(value);
  }, [value]);

  const handleModalBefore = (file: RcFile, _: RcFile[]) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) message.error('请上传jpeg/jpg/png格式的图片');
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) message.error('请上传小于2M的图片');
    return isJpgOrPng && isLt2M;
  };

  const handleChange = ({ file }: UploadChangeParam) => {
    setLoading(file.status === 'uploading');
    setUrl(file.response?.result);
    if (file.status === 'done') onChange?.(file.response?.result);

    if (file.status === 'error') message.error('上传失败，请重试');
  };
  const handleRemove = () => {
    setUrl('')
    onChange?.('')
  }
  return (
    <Space align="start">
      {url ? <div
        className={`group relative flex items-center justify-center w-24 h-24 mb-2 p-1 outline-1 outline outline-slate-300 rounded`}
      >
        <div className="w-full h-full bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${url})` }} />

        <div
          className="group-hover:opacity-100 group-hover:cursor-pointer absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center bg-black/50 text-white opacity-0 transition-all duration-200"
          onClick={() => handleRemove()}>
          <DeleteOutlined />
        </div>
      </div> : null}
      {aspect ?
        <ImgCrop aspect={aspect} beforeCrop={handleModalBefore} modalCancel='取消' modalOk='确定' modalTitle='编辑图片'>
          <Upload
            accept={type}
            action={action}
            disabled={loading}
            headers={{
              'x-AppId': 'cyber-saas',
              Authorization: `Bearer ${accessToken}`,
            }}
            listType="picture-card"
            maxCount={1}
            name="file"
            onChange={handleChange}
            showUploadList={false}>
            {loading ? (
              <LoadingOutlined style={{ color: '#6579fe', fontSize: '24px' }} />
            ) : url ? (
              '重新上传'
            ) : (
              <PlusOutlined />
            )}
          </Upload>
        </ImgCrop> : <Upload
          accept={type}
          action={action}
          beforeUpload={handleModalBefore}
          disabled={loading}
          headers={{
            'x-AppId': 'cyber-saas',
            Authorization: `Bearer ${accessToken}`,
          }}
          listType="picture-card"
          maxCount={1}
          name="file"
          onChange={handleChange}
          showUploadList={false}>
          {loading ? (
            <LoadingOutlined style={{ color: '#6579fe', fontSize: '24px' }} />
          ) : url ? (
            '重新上传'
          ) : (
            <PlusOutlined />
          )}
        </Upload>}
    </Space>
  );
};

export default UploadSingleImage;
