/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Slider, Switch } from 'antd';
import dayjs from 'dayjs';

const tipFormatter = (value?: number) =>
  typeof value === 'number'
    ? dayjs()
        .startOf('day')
        .add(30 * value, 'm')
        .format('HH:mm')
    : null;

const marks = {
  0: '00:00',
  // 6: '03:00',
  12: '06:00',
  // 18: '09:00',
  24: '12: 00',
  // 30: '15:00',
  36: '18:00',
  // 42: '21: 00',
  48: '00:00',
};

const week: { key: WEEK; name: string }[] = [
  { key: 'mon', name: '周一' },
  { key: 'tue', name: '周二' },
  { key: 'wed', name: '周三' },
  { key: 'thu', name: '周四' },
  { key: 'fri', name: '周五' },
  { key: 'sat', name: '周六' },
  { key: 'sun', name: '周日' },
];

type WEEK = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

type Item = { checked: boolean; hours: number[] };

interface IProps {
  value?: {
    [key in WEEK]: Item;
  };
  onChange?: (val: any) => void;
}

const ShopHours: React.FC<IProps> = ({ value, onChange }) => {
  const handleSwitch = (key: WEEK, checked: boolean) => {
    onChange && onChange(value ? { ...value, [key]: { ...value[key], checked } } : value);
  };

  const handleSlide = (key: WEEK, hours: [number, number]) => {
    onChange && onChange(value ? { ...value, [key]: { ...value[key], hours } } : value);
  };

  return (
    <>
      {week.map((_) => (
        <div className="flex items-baseline gap-4" key={_.key}>
          <Switch
            checked={value?.[_.key].checked}
            checkedChildren={_.name}
            onChange={(checked) => handleSwitch(_.key, checked)}
            size="small"
            unCheckedChildren={_.name}
          />
          <Slider
            className="flex-1"
            disabled={!value?.[_.key].checked}
            marks={marks}
            max={48}
            min={0}
            onChange={(val) => handleSlide(_.key, val)}
            range
            tooltip={{
              formatter: tipFormatter,
            }}
            value={value?.[_.key].hours as [number, number]}
          />
        </div>
      ))}
    </>
  );
};

export default ShopHours;
