import { useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import type { CarouselRef } from 'antd/lib/carousel';

import SVG1 from './1.svg';
import SVG2 from './2.svg';
import SVG3 from './3.svg';
import SVG4 from './4.svg';

interface IBanner {
  item: { title: string; sub: string; svg: string };
}
const items = [
  { title: '智能SaaS管理与营销系统', sub: '面向艺术、体育、科技线下品牌机构，助力降本增效', svg: SVG1 },
  { title: '线上社区公域导流', sub: '智械舞客垂类社区流量线下转化，无视地域获客', svg: SVG2 },
  { title: '数据安全', sub: '多云数据脱敏保存，以防泄漏', svg: SVG3 },
  { title: '混合云 + AIoT', sub: '智能物联网赋能，构建未来场馆', svg: SVG4 },
];
const Banner = ({ item }: IBanner) => {
  return (
    <div className="w-full h-screen bg-gradient-to-b from-slate-800 to-primary flex flex-col justify-center items-center">
      <h1 className="text-white font-bold">{item.title}</h1>
      <p className="text-gray-400">{item.sub}</p>
      <img alt={item.title} className="w-80" src={item.svg} />
    </div>
  );
};

const CarouselBlock = () => {
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <>
      <div
        className="absolute left-2 top-1/2 z-30 w-8 h-8 rounded-full flex justify-center items-center cursor-pointer text-white bg-[rgba(0,0,0,.2)] hover:bg-[rgba(0,0,0,.5)]"
        onClick={() => carouselRef.current?.prev()}>
        <LeftOutlined />
      </div>
      <div
        className="absolute right-2 top-1/2 z-30 w-8 h-8 rounded-full flex justify-center items-center cursor-pointer text-white bg-[rgba(0,0,0,.2)] hover:bg-[rgba(0,0,0,.5)]"
        onClick={() => carouselRef.current?.next()}>
        <RightOutlined />
      </div>
      <Carousel autoplay easing="ease-out" ref={carouselRef}>
        {items.map((item, idx) => (
          <div key={idx}>
            <Banner item={item} />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselBlock;
