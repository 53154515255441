import type { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import qs from 'qs';
import { message, notification } from 'antd';
import Request, { RequestInterceptors, RequestConfig } from '@/utils/request';
import { store } from '@/store';
import { action__logout } from '@/model/userSlice';

export interface SaaSResponse<T> {
  t: number;
  success: boolean;
  result: T;
  msg?: string;
  errCode?: string;
}

interface SaaSRequestConfig<T, R> extends RequestConfig<SaaSResponse<R>> {
  data?: T;
}

const request = new Request({
  baseURL: '/api',
  timeout: 1000 * 60 * 5,
  headers: { 'x-AppId': 'cyber-saas' },
  interceptors: {
    requestInterceptors: (config) => {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${store.getState().user.accessToken}`,
      };
      return config;
    },
    responseInterceptors: (result: AxiosResponse) => {
      // console.log('实例响应拦截');
      const { data } = result;
      if (data.success === false && data.errCode === '401') {
        console.log(`该接口 "${result.config.url}" 未授权`);
        notification.warning({
          key: 'nopermission',
          message: data.msg,
        });
        throw new Error(data.msg);
      }
      return result;
    },
    responseInterceptorsCatch: ({ response }) => {
      switch (response.status) {
        case 401:
        case 449:
          notification.warning({
            key: 'expired',
            message: '登录过期',
          });
          store.dispatch(action__logout());
          break;
        default:
          notification.error({
            key: 'requestError',
            message: '网络异常，请稍后重试',
          });
          break;
      }
      return
      // return Promise.reject(new Error(`[status]: ${response.status}; [statusText]: ${response.statusText}`));
    },
  },
});

/**
 * @description: 函数的描述
 * @generic D 请求参数
 * @generic T 响应结构
 * @param {SaaSRequestConfig} config 不管是GET还是POST请求都使用data
 * @returns {Promise}
 */
export const saasRequest = <D = never, T = never>(config: SaaSRequestConfig<D, T>) => {
  const { method = 'GET' } = config;
  if (method === 'get' || method === 'GET') {
    config.url = config.url + '?' + qs.stringify(config.data);
  }
  return request.request<SaaSResponse<T>>(config);
};
export const cancelRequest = (url: string | string[]) => request.cancelRequest(url);
export const cancelAllRequest = () => request.cancelAllRequest();

const use = <Req, Res>(
  method: Method,
  url: string,
  data?: Req,
  interceptors?: RequestInterceptors<SaaSResponse<Res>>,
) =>
  saasRequest<Req, Res>({
    url,
    method,
    data,
    interceptors,
  });
export default use;

export const downloadInterceptor = <T>(type: string) => ({
  requestInterceptors: (config: AxiosRequestConfig) => {
    config.headers = {
      ...config.headers,
      responseType: 'blob',
      'content-type': type,
    };
    // headers中指定responseType=blob告诉后端
    // 然后config中也需要指定responseType=blob交给adapter处理
    config.responseType = 'blob';

    return config;
  },
  responseInterceptors: (response: AxiosResponse<SaaSResponse<T>>) => {
    if (response.headers['content-type'] === 'application/json') {
      const a = response.data as unknown as Blob;
      a.text()
        .then((data) => {
          // data：指成功读取到的内容
          const { msg } = JSON.parse(data);
          message.error(msg);
        })
        .catch(() => {
          //err： 指读取失败后返回的错误内容
        });
      return response;
    }

    const filename = decodeURIComponent(
      response?.headers?.['content-disposition']?.split(';')[1].split('=')[1] || 'unknown',
    );

    const link = document.createElement('a');
    // request拦截器中config.responseType指定为blob
    // response.data本身就是个Blob对象，直接传给createObjectURL
    link.href = URL.createObjectURL(response.data as unknown as Blob);
    link.setAttribute('download', decodeURIComponent(filename || 'unknown'));
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);

    return response;
  },
});
