import use from '@/services/saas.request';
import {
  ReqGetV1SaasBrandIdReduceReduceIdGiveList,
  ReqGetV1SaasBrandIdReduceReduceIdInfo,
  ReqGetV1SaasBrandIdReduceList,
  ReqPostV1SaasBrandIdReduce,
  ReqPutV1SaasBrandIdReduceReduceId,
  ResGetV1SaasBrandIdReduceReduceIdInfo,
  ResGetV1SaasBrandIdReduceList,
  ResPutV1SaasBrandIdReduceReduceId,
  ResGetV1SaasBrandIdReduceReduceIdGiveList,
  ReqDeleteV1SaasBrandIdReduceReduceId,
  ResDeleteV1SaasBrandIdReduceReduceId,
  ReqGetV1SaasBrandIdReduceReduceIdGrantIdGiveVipList,
  ResGetV1SaasBrandIdReduceReduceIdGrantIdGiveVipList
} from '@/services/params';

export const getBrandReduceList = (p: ReqGetV1SaasBrandIdReduceList) =>
  use<ReqGetV1SaasBrandIdReduceList, ResGetV1SaasBrandIdReduceList>(
    'get',
    `/v1/saas/brand/${p.id}/reduce/list`,
    p
  )

export const postBrandReduce = (p: ReqPostV1SaasBrandIdReduce) =>
  use<ReqPostV1SaasBrandIdReduce, ReqPostV1SaasBrandIdReduce>(
    'post',
    `/v1/saas/brand/${p.id}/reduce`,
    p
  )

export const putBrandReduce = (p: ReqPutV1SaasBrandIdReduceReduceId) =>
  use<ReqPutV1SaasBrandIdReduceReduceId, ResPutV1SaasBrandIdReduceReduceId>(
    'put',
    `/v1/saas/brand/${p.id}/reduce/${p.reduceId}`,
    p
  )

export const getBrandReduce = (p: ReqGetV1SaasBrandIdReduceReduceIdInfo) =>
  use<ReqGetV1SaasBrandIdReduceReduceIdInfo, ResGetV1SaasBrandIdReduceReduceIdInfo>(
    'get',
    `/v1/saas/brand/${p.id}/reduce/${p.reduceId}/info`,
    p
  )
export const getBrandReduceGiveList = (p: ReqGetV1SaasBrandIdReduceReduceIdGiveList) =>
  use<ReqGetV1SaasBrandIdReduceReduceIdGiveList, ResGetV1SaasBrandIdReduceReduceIdGiveList>(
    'get',
    `/v1/saas/brand/${p.id}/reduce/${p.reduceId}/give/list`,
    p
  )

export const getBrandReduceGiveVipList = (p: ReqGetV1SaasBrandIdReduceReduceIdGrantIdGiveVipList) =>
  use<ReqGetV1SaasBrandIdReduceReduceIdGrantIdGiveVipList, ResGetV1SaasBrandIdReduceReduceIdGrantIdGiveVipList>(
    'get',
    `/v1/saas/brand/${p.id}/reduce/${p.reduceId}/${p.grantId}/give/vip/list`,
    p
  )

export const deletetBrandReduce = (p: ReqDeleteV1SaasBrandIdReduceReduceId) =>
  use<ReqDeleteV1SaasBrandIdReduceReduceId, ResDeleteV1SaasBrandIdReduceReduceId>(
    'delete',
    `/v1/saas/brand/${p.id}/reduce/${p.reduceId}`,
    p
  )

