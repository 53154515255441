import React, { useCallback, useMemo } from 'react';
import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';

interface IProps {
  value?: number[];
  onChange?: (v: unknown) => void;
  placeholder?: [string, string];
  range?: [number?, number?];
}

const inputStyle = {
  width: '100%',
};

const NumberRange: React.FC<IProps> = ({ value = [], onChange, placeholder = [], range = [] }) => {
  const [MIN = -Infinity, MAX = +Infinity] = useMemo(() => range, [range]);
  const isNumber = useCallback((n: unknown) => Object.prototype.toString.call(n) === '[object Number]', []);

  return (
    <div className="flex items-center gap-2">
      <InputNumber
        onBlur={() => {
          let [vMin, vMax] = value;
          if (!isNumber(vMin)) return;
          if (vMin < MIN || vMin > MAX) vMin = MIN === -Infinity ? MAX : MIN;

          if (!isNumber(vMax) && vMin > vMax) [vMin, vMax] = [vMax, vMin];
          onChange?.([vMin, vMax]);
        }}
        onChange={(v) => {
          const nv: (number | null)[] = [...value];
          nv[0] = v;
          onChange?.(nv);
        }}
        placeholder={placeholder[0]}
        style={inputStyle}
        value={value[0]}
      />

      <SwapRightOutlined />

      <InputNumber
        onBlur={() => {
          let [vMin, vMax] = value;
          if (!isNumber(vMax)) return;
          if (vMax < MIN || vMax > MAX) vMax = MIN === -Infinity ? MAX : MIN;

          if (!isNumber(vMin) && vMin > vMax) [vMin, vMax] = [vMax, vMin];
          onChange?.([vMin, vMax]);
        }}
        onChange={(v) => {
          const nv: (number | null)[] = [...value];
          nv[1] = v;
          onChange?.(nv);
        }}
        placeholder={placeholder[1]}
        style={inputStyle}
        value={value[1]}
      />
    </div>
  );
};

export default NumberRange;
