import use, { downloadInterceptor } from '@/services/saas.request';
import {
  ReqGetV1SaasBrandIdInfo,
  ReqGetV1SaasBrandIdStudioList,
  ReqGetV1SaasBrandIdTemporarySetting,
  ReqGetV1SaasBrandBrandIdTeachersListAll,
  ResGetV1SaasBrandBrandIdTeachersListAll,
  ReqPatchV1SaasBrandIdTemporarySetting,
  ReqPostV1SaasBrand,
  ReqPostV1SaasBrandIdStudio,
  ReqPutV1SaasBrandBrandIdStudioStudioId,
  ReqPutV1SaasBrandId,
  ReqPutV1SaasBrandIdActive,
  ReqPutV1SaasBrandIdStudioStudioIdOpeStatus,
  ResGetV1SaasBrandIdInfo,
  ResGetV1SaasBrandIdStudioList,
  ResGetV1SaasBrandIdTemporarySetting,
  ReqGetV1SaasBrandBrandIdSalesmanListAll,
  ResGetV1SaasBrandBrandIdSalesmanListAll,
  ResPatchV1SaasBrandIdTemporarySetting,
  ResPostV1SaasBrand,
  ResPostV1SaasBrandIdStudio,
  ResPutV1SaasBrandBrandIdStudioStudioId,
  ResPutV1SaasBrandId,
  ResPutV1SaasBrandIdActive,
  ResPutV1SaasBrandIdStudioStudioIdOpeStatus,
  ReqPostV1SaasBrandBrandIdStaffExport,
  ResPostV1SaasBrandBrandIdStaffExport,
  ReqPostV1SaasBrandBrandIdVipErrorExport,
  ResPostV1SaasBrandBrandIdVipErrorExport,
  ReqPostV1SaasBrandBrandIdCourseExport,
  ResPostV1SaasBrandBrandIdCourseExport,
  ReqPostV1SaasBrandBrandIdPackageExport,
  ResPostV1SaasBrandBrandIdPackageExport,
  ReqPutV1SaasStudioStudioIdTagBind,
  ResPutV1SaasStudioStudioIdTagBind,
  ResGetV1SaasBrandTags,
  ReqGetV1SaasBrandTags,
  ReqPostV1SaasBrandAppeal,
  ResPostV1SaasBrandAppeal,
  ReqGetV1SaasBrandCertificationAppealList,
  ResGetV1SaasBrandCertificationAppealList,
  ReqGetV1SaasBrandCertificationAppealCaIdDetail,
  ResGetV1SaasBrandCertificationAppealCaIdDetail,
  ReqPostV1SaasBrandJoin,
  ResPostV1SaasBrandJoin,
  ReqGetV1SaasBrandJoinJoinIdDetail,
  ResGetV1SaasBrandJoinJoinIdDetail,
  ReqGetV1SaasBrandJoinBrandListAll,
  ResGetV1SaasBrandJoinBrandListAll,
  ReqGetV1SaasBrandJoinRecordList,
  ResGetV1SaasBrandJoinRecordList,
  ReqGetV1SaasBrandJoinApplyList,
  ResGetV1SaasBrandJoinApplyList,
  ReqGetV1SaasBrandEnterpriseCertificationEaIdDetail,
  ResGetV1SaasBrandEnterpriseCertificationEaIdDetail,
  ReqGetV1SaasBrandEnterpriseCertificationList,
  ResGetV1SaasBrandEnterpriseCertificationList,
  ReqPostV1SaasBrandIdCertification,
  ReqGetV1SaasBrandIdCertificationInfo,
  ResGetV1SaasBrandIdCertificationInfo,
  ReqPatchV1SaasBrandIdJoinOpenStatus,
  ResPatchV1SaasBrandIdJoinOpenStatus,
  ReqGetV1SaasBrandIdEnterpriseCertificationListAll,
  ReqGetV1SaasBrandIdCertificationDetail,
  ResGetV1SaasBrandIdCertificationDetail,
  ReqPatchV1SaasBrandIdEnterpriseCertificationEcId,
  ResPatchV1SaasBrandIdEnterpriseCertificationEcId,
  ResGetV1SaasBrandIdEnterpriseCertificationListAll,
  ReqPutV1SaasBrandJoinJoinId,
  ReqGetV1SaasBrandJoinJoinidPrice,
  ReqGetV1SaasBrandJoinJoinidContract,
  ResGetV1SaasBrandJoinJoinidContract,
  ReqPostV1SaasBrandIdEnterpriseCertification,
  ResPostV1SaasBrandIdEnterpriseCertification,
  ReqPutV1SaasBrandIdJoinContract,
  ResPutV1SaasBrandIdJoinContract,
  ReqPatchV1SaasBrandJoinJoinIdRefreshDeposits,
  ResPatchV1SaasBrandJoinJoinIdRefreshDeposits,
  ReqGetV1SaasBrandIdCourseCourseIdCheck,
  ResGetV1SaasBrandIdCourseCourseIdCheck,
  ReqPutV1SaasStudioIdStatusBind,
  ResPutV1SaasStudioIdStatusBind,
  ReqGetV1SaasBrandIdStudioListAll,
  ResGetV1SaasBrandIdStudioListAll,
} from './params';

export const postBrand = (p: ReqPostV1SaasBrand) =>
  use<ReqPostV1SaasBrand, ResPostV1SaasBrand>('post', '/v1/saas/brand', p);

export const getBrand = (p: ReqGetV1SaasBrandIdInfo) =>
  use<ReqGetV1SaasBrandIdInfo, ResGetV1SaasBrandIdInfo>('get', `/v1/saas/brand/${p.id}/info`);

export const getStudioUnderBrand = (p: ReqGetV1SaasBrandIdStudioList) =>
  use<ReqGetV1SaasBrandIdStudioList, ResGetV1SaasBrandIdStudioList>('get', `/v1/saas/brand/${p.id}/studio/list`);

export const getGoldList = (p: ReqGetV1SaasBrandTags) =>
  use<ReqGetV1SaasBrandTags, ResGetV1SaasBrandTags>(
    'get',
    `/v1/saas/brand/tags`,
    p
  )
export const putStudioUnderBrand = (p: ReqPutV1SaasBrandBrandIdStudioStudioId) =>
  use<ReqPutV1SaasBrandBrandIdStudioStudioId, ResPutV1SaasBrandBrandIdStudioStudioId>(
    'put',
    `/v1/saas/brand/${p.brandId}/studio/${p.studioId}`,
    p,
  );

export const postStudioUnderBrand = (p: ReqPostV1SaasBrandIdStudio) =>
  use<ReqPostV1SaasBrandIdStudio, ResPostV1SaasBrandIdStudio>('post', `/v1/saas/brand/${p.id}/studio`, p);

export const putBrand = (p: ReqPutV1SaasBrandId) =>
  use<ReqPutV1SaasBrandId, ResPutV1SaasBrandId>('put', `/v1/saas/brand/${p.id}`, p);

export const getTemporary = (p: ReqGetV1SaasBrandIdTemporarySetting) =>
  use<ReqGetV1SaasBrandIdTemporarySetting, ResGetV1SaasBrandIdTemporarySetting>(
    'get',
    `/v1/saas/brand/${p.id}/temporary/setting`,
  );

export const patchTemporary = (p: ReqPatchV1SaasBrandIdTemporarySetting) =>
  use<ReqPatchV1SaasBrandIdTemporarySetting, ResPatchV1SaasBrandIdTemporarySetting>(
    'patch',
    `/v1/saas/brand/${p.id}/temporary/setting`,
    p
  );

export const activeBrand = (p: ReqPutV1SaasBrandIdActive) =>
  use<ReqPutV1SaasBrandIdActive, ResPutV1SaasBrandIdActive>('put', `/v1/saas/brand/${p.id}/active`);

export const toggleStudio = (p: ReqPutV1SaasBrandIdStudioStudioIdOpeStatus) =>
  use<ReqPutV1SaasBrandIdStudioStudioIdOpeStatus, ResPutV1SaasBrandIdStudioStudioIdOpeStatus>(
    'put',
    `/v1/saas/brand/${p.id}/studio/${p.studioId}/ope_status`,
    p,
  );

export const getTeachersBrand = (p: ReqGetV1SaasBrandBrandIdTeachersListAll) =>
  use<ReqGetV1SaasBrandBrandIdTeachersListAll, ResGetV1SaasBrandBrandIdTeachersListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/teachers/list_all`,
    p,
  );

export const getSalesmanBrand = (p: ReqGetV1SaasBrandBrandIdSalesmanListAll) =>
  use<ReqGetV1SaasBrandBrandIdSalesmanListAll, ResGetV1SaasBrandBrandIdSalesmanListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/salesman/list_all`,
    p,
  );

export const postStaffWrong = (p: ReqPostV1SaasBrandBrandIdStaffExport, type: string) =>
  use<ReqPostV1SaasBrandBrandIdStaffExport, ResPostV1SaasBrandBrandIdStaffExport>(
    'post',
    `/v1/saas/brand/${p.brandId}/staff/export`,
    p,
    downloadInterceptor(type),
  );

export const postVipWrong = (p: ReqPostV1SaasBrandBrandIdVipErrorExport, type: string) =>
  use<ReqPostV1SaasBrandBrandIdVipErrorExport, ResPostV1SaasBrandBrandIdVipErrorExport>(
    'post',
    `/v1/saas/brand/${p.brandId}/vip/error/export`,
    p,
    downloadInterceptor(type),
  );

export const postCourseWrong = (p: ReqPostV1SaasBrandBrandIdCourseExport, type: string) =>
  use<ReqPostV1SaasBrandBrandIdCourseExport, ResPostV1SaasBrandBrandIdCourseExport>(
    'post',
    `/v1/saas/brand/${p.brandId}/course/export`,
    p,
    downloadInterceptor(type),
  );

export const postPkgWrong = (p: ReqPostV1SaasBrandBrandIdPackageExport, type: string) =>
  use<ReqPostV1SaasBrandBrandIdPackageExport, ResPostV1SaasBrandBrandIdPackageExport>(
    'post',
    `/v1/saas/brand/${p.brandId}/package/export`,
    p,
    downloadInterceptor(type),
  );

export const getStudioList = (p: ReqGetV1SaasBrandIdStudioList) =>
  use<ReqGetV1SaasBrandIdStudioList, ResGetV1SaasBrandIdStudioList>('get', `/v1/saas/brand/${p.id}/studio/list`, p);

  export const getStudioListAll = (p: ReqGetV1SaasBrandIdStudioListAll) =>
  use<ReqGetV1SaasBrandIdStudioListAll, ResGetV1SaasBrandIdStudioListAll>('get', `/v1/saas/brand/${p.id}/studio/list_all`, p);
export const checkCourseUse = (p: ReqGetV1SaasBrandIdCourseCourseIdCheck) =>
  use<ReqGetV1SaasBrandIdCourseCourseIdCheck, ResGetV1SaasBrandIdCourseCourseIdCheck>(
    'get',
    `/v1/saas/brand/${p.id}/course/${p.courseId}/check`,
    p
  )
export const setStudio = (p: ReqPutV1SaasStudioStudioIdTagBind) =>
  use<ReqPutV1SaasStudioStudioIdTagBind, ResPutV1SaasStudioStudioIdTagBind>(
    'put',
    `/v1/saas/studio/${p.studioId}/tag/bind`,
    p
  )
export const putStudioScrip = (p: ReqPutV1SaasStudioIdStatusBind) =>
  use<ReqPutV1SaasStudioIdStatusBind, ResPutV1SaasStudioIdStatusBind>(
    'put',
    `/v1/saas/studio/${p.id}/status/bind`,
    p
  )
export const brandAppeal = (p: ReqPostV1SaasBrandAppeal) =>
  use<ReqPostV1SaasBrandAppeal, ResPostV1SaasBrandAppeal>(
    'post',
    `/v1/saas/brand/appeal`,
    p
  )

export const appealList = (p: ReqGetV1SaasBrandCertificationAppealList) =>
  use<ReqGetV1SaasBrandCertificationAppealList, ResGetV1SaasBrandCertificationAppealList>(
    'get',
    `/v1/saas/brand/certification_appeal/list`,
    p
  )

export const appealDetail = (p: ReqGetV1SaasBrandCertificationAppealCaIdDetail) =>
  use<ReqGetV1SaasBrandCertificationAppealCaIdDetail, ResGetV1SaasBrandCertificationAppealCaIdDetail>(
    'get',
    `/v1/saas/brand/certification_appeal/${p.caId}/detail`,
    p
  )

export const applyJoin = (p: ReqPostV1SaasBrandJoin) =>
  use<ReqPostV1SaasBrandJoin, ResPostV1SaasBrandJoin>(
    'post',
    `/v1/saas/brand/join`,
    p
  )

export const canJoinList = (p: ReqGetV1SaasBrandJoinBrandListAll) =>
  use<ReqGetV1SaasBrandJoinBrandListAll, ResGetV1SaasBrandJoinBrandListAll>(
    'get',
    `/v1/saas/brand/join_brand/list_all`,
    p
  )

export const joinList = (p: ReqGetV1SaasBrandJoinRecordList) =>
  use<ReqGetV1SaasBrandJoinRecordList, ResGetV1SaasBrandJoinRecordList>(
    'get',
    `/v1/saas/brand/join/record/list`,
    p
  )
export const joinDetail = (p: ReqGetV1SaasBrandJoinJoinIdDetail) =>
  use<ReqGetV1SaasBrandJoinJoinIdDetail, ResGetV1SaasBrandJoinJoinIdDetail>(
    'get',
    `/v1/saas/brand/join/${p.joinId}/detail`,
    p
  )

export const applyList = (p: ReqGetV1SaasBrandJoinApplyList) =>
  use<ReqGetV1SaasBrandJoinApplyList, ResGetV1SaasBrandJoinApplyList>(
    'get',
    `/v1/saas/brand/join/apply/list`,
    p
  )

export const getJoinPayResult = (p: ReqGetV1SaasBrandJoinJoinidPrice) =>
  use<ReqGetV1SaasBrandJoinJoinidPrice, ReqGetV1SaasBrandJoinJoinidPrice>(
    'get',
    `/v1/saas/brand/join/${p.joinid}/price`,
    p
  )
export const getJoinContractResult = (p: ReqGetV1SaasBrandJoinJoinidContract) =>
  use<ReqGetV1SaasBrandJoinJoinidContract, ResGetV1SaasBrandJoinJoinidContract>(
    'get',
    `/v1/saas/brand/join/${p.joinid}/contract`,
    p
  )

export const refreshDepositQRCode = (p: ReqPatchV1SaasBrandJoinJoinIdRefreshDeposits) =>
  use<ReqPatchV1SaasBrandJoinJoinIdRefreshDeposits, ResPatchV1SaasBrandJoinJoinIdRefreshDeposits>(
    'patch',
    `/v1/saas/brand/join/${p.joinId}/refresh/deposits`,
    p
  )
export const putJoin = (p: ReqPutV1SaasBrandJoinJoinId) =>
  use<ReqPutV1SaasBrandJoinJoinId, ReqPutV1SaasBrandJoinJoinId>(
    'put',
    `/v1/saas/brand/join/${p.joinId}`,
    p
  )

export const companyApprove = (p: ReqPostV1SaasBrandIdEnterpriseCertification) =>
  use<ReqPostV1SaasBrandIdEnterpriseCertification, ResPostV1SaasBrandIdEnterpriseCertification>(
    'post',
    `/v1/saas/brand/${p.id}/enterprise_certification`,
    p
  )
export const approveList = (p: ReqGetV1SaasBrandEnterpriseCertificationList) =>
  use<ReqGetV1SaasBrandEnterpriseCertificationList, ResGetV1SaasBrandEnterpriseCertificationList>(
    'get',
    `/v1/saas/brand/enterprise_certification/list`,
    p
  )
export const approveDetail = (p: ReqGetV1SaasBrandEnterpriseCertificationEaIdDetail) =>
  use<ReqGetV1SaasBrandEnterpriseCertificationEaIdDetail, ResGetV1SaasBrandEnterpriseCertificationEaIdDetail>(
    'get',
    `/v1/saas/brand/enterprise_certification/${p.eaId}/detail`,
    p
  )
export const brandApprove = (p: ReqPostV1SaasBrandIdCertification) =>
  use<ReqPostV1SaasBrandIdCertification, ReqPostV1SaasBrandIdCertification>(
    'post',
    `/v1/saas/brand/${p.id}/certification`,
    p
  )

export const brandApproveInfo = (p: ReqGetV1SaasBrandIdCertificationInfo) =>
  use<ReqGetV1SaasBrandIdCertificationInfo, ResGetV1SaasBrandIdCertificationInfo>(
    'get',
    `/v1/saas/brand/${p.id}/certification/info`,
    p
  )

export const patchJoinStatus = (p: ReqPatchV1SaasBrandIdJoinOpenStatus) =>
  use<ReqPatchV1SaasBrandIdJoinOpenStatus, ResPatchV1SaasBrandIdJoinOpenStatus>(
    'patch',
    `/v1/saas/brand/${p.id}/join/open_status`,
    p
  )

export const brandApproveDetail = (p: ReqGetV1SaasBrandIdCertificationDetail) =>
  use<ReqGetV1SaasBrandIdCertificationDetail, ResGetV1SaasBrandIdCertificationDetail>(
    'get',
    `/v1/saas/brand/${p.id}/certification/detail`,
    p
  )
export const patchApproveCompany = (p: ReqPatchV1SaasBrandIdEnterpriseCertificationEcId) =>
  use<ReqPatchV1SaasBrandIdEnterpriseCertificationEcId, ResPatchV1SaasBrandIdEnterpriseCertificationEcId>(
    'patch',
    `/v1/saas/brand/${p.id}/enterprise_certification/${p.ecId}`,
    p
  )
export const getAllApproveCompany = (p: ReqGetV1SaasBrandIdEnterpriseCertificationListAll) =>
  use<ReqGetV1SaasBrandIdEnterpriseCertificationListAll, ResGetV1SaasBrandIdEnterpriseCertificationListAll>(
    'get',
    `/v1/saas/brand/${p.id}/enterprise_certification/list_all`,
    p
  )

export const putBrandJoinContract = (p: ReqPutV1SaasBrandIdJoinContract) =>
  use<ReqPutV1SaasBrandIdJoinContract, ResPutV1SaasBrandIdJoinContract>(
    'put',
    `/v1/saas/brand/${p.id}/join/contract`,
    p
  )
