import { AlipayCircleFilled,  EuroCircleOutlined, WalletFilled, WechatFilled } from '@ant-design/icons';
import { Tag } from 'antd';

interface IProps {
  type?: string;
}

const PayType = ({ type }: IProps) => {
  if (type === '1')
    return (
      <Tag color="#0fcf1a" icon={<WechatFilled />}>
        微信
      </Tag>
    );
  if (type === '2')
    return (
      <Tag color="#1477fe" icon={<AlipayCircleFilled />}>
        支付宝
      </Tag>
    );
  if (type === '3')
    return (
      <Tag color="#666" icon={<WalletFilled />}>
        线下
      </Tag>
    );
    if (type === '4')
    return  <Tag color="#21c7b0" icon={<EuroCircleOutlined />}>点数</Tag>;
  return <>--</>;
};

export default PayType;
