import { useNavigate } from 'react-router-dom';
import NProgress from 'nprogress';
import type { IRouteReal } from '@/routes/_generate';
import studioRoutes from '@/routes/studio';
import brandRoutes from '@/routes/brand';
import userRoutes from '@/routes/user';

let timer: NodeJS.Timer;

export default function useAppNavigate() {
  const navigate = useNavigate();

  const link = async (target?: IRouteReal, search?: Record<string, unknown>) => {
    let qs = '';
    if (search) {
      for (const key in search) {
        search[key] ?? delete search[key];
      }
      qs = `?${new URLSearchParams(search as never).toString()}`;
    }

    NProgress.start();
    await target?.preload();

    // 猜测页面预加载时虽然lazy load 的 promise 已返回，但由于js执行还是有延迟导致suspense触发，fallback页面一闪而过
    // 所以暂时增加一个定时器
    timer && clearTimeout(timer);
    await new Promise((resolve) => {
      timer = setTimeout(resolve, 200);
    });

    target?.path && navigate(`${target.path}${qs}`);
    NProgress.done();
  };

  return {
    navigate,
    appNavigate: async (path?: string) => {
      const target =
        studioRoutes.find((r) => r.path === path) ||
        brandRoutes.find((r) => r.path === path) ||
        userRoutes.find((r) => r.path === path);
      await link(target);
    },
    appNavigateKey: async (KEY: string, search?: Record<string, unknown>) => {
      const target =
        studioRoutes.find((r) => r.KEY === KEY) ||
        brandRoutes.find((r) => r.KEY === KEY) ||
        userRoutes.find((r) => r.KEY === KEY);
      await link(target, search);
    },
  };
}
