import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';

import * as models from '@/model';

/**
 * 状态持久化配置
 * */
const persistConfig = {
  key: 'cyber-saas',
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ['basic', 'user'],
  debug: false,
};

/**
 * 持久化的reducer
 * */
const reducer = combineReducers(models);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _persistedReducer = persistReducer<any, AnyAction>(persistConfig, reducer);

/**
 * 创建store
 * 配置持久化的reducer
 * 中间件添加
 * 配置调试工具
 * enhancers添加redux-batch
 * */
export const store = configureStore({
  reducer: _persistedReducer as typeof reducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    if (process.env.NODE_ENV !== 'production')
      return defaultMiddleware.concat(
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('redux-logger').createLogger({
          collapsed: true,
          duration: true,
          diff: true,
        }),
      );

    return defaultMiddleware;
  },
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
  enhancers: [],
});

/**
 * 持久化的store
 * */
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<ReturnType = Promise<void>> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
