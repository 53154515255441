import use from '@/services/saas.request';
import {
  ReqGetV1SaasBrandIdClassCount,
  ReqGetV1SaasBrandIdClassCountList,
  ReqGetV1SaasBrandIdCleanMember,
  ReqGetV1SaasBrandIdCleanValue,
  ReqGetV1SaasBrandIdCourseAll,
  ReqGetV1SaasBrandIdIncomeAll,
  ReqGetV1SaasBrandIdIncomeDetail,
  ReqGetV1SaasBrandIdIncomeDetailList,
  ReqGetV1SaasBrandIdIncomeToday,
  ReqGetV1SaasBrandIdMerchandiseSales,
  ReqGetV1SaasBrandIdMerchandiseSalesList,
  ReqGetV1SaasBrandIdOrderAll,
  ReqGetV1SaasBrandIdOrderCount,
  ReqGetV1SaasBrandIdOrderCountList,
  ReqGetV1SaasBrandIdOrderList,
  ReqGetV1SaasBrandIdSaleCountAll,
  ReqGetV1SaasBrandIdSaleMoneyList,
  ReqGetV1SaasBrandIdSalePerformance,
  ReqGetV1SaasBrandIdSalePerformanceList,
  ReqGetV1SaasBrandIdSaleType,
  ReqGetV1SaasBrandIdStaffStaffIdCleanList,
  ReqGetV1SaasBrandIdStaffStaffIdSaleList,
  ResGetV1SaasBrandIdClassCount,
  ResGetV1SaasBrandIdClassCountList,
  ResGetV1SaasBrandIdCleanMember,
  ResGetV1SaasBrandIdCleanValue,
  ResGetV1SaasBrandIdCourseAll,
  ResGetV1SaasBrandIdIncomeAll,
  ResGetV1SaasBrandIdIncomeDetail,
  ResGetV1SaasBrandIdIncomeDetailList,
  ResGetV1SaasBrandIdIncomeToday,
  ResGetV1SaasBrandIdMerchandiseSales,
  ResGetV1SaasBrandIdMerchandiseSalesList,
  ResGetV1SaasBrandIdOrderAll,
  ResGetV1SaasBrandIdOrderCount,
  ResGetV1SaasBrandIdOrderCountList,
  ResGetV1SaasBrandIdOrderList,
  ResGetV1SaasBrandIdSaleCountAll,
  ResGetV1SaasBrandIdSaleMoneyList,
  ResGetV1SaasBrandIdSalePerformance,
  ResGetV1SaasBrandIdSalePerformanceList,
  ResGetV1SaasBrandIdSaleType,
  ResGetV1SaasBrandIdStaffStaffIdCleanList,
  ResGetV1SaasBrandIdStaffStaffIdSaleList,
} from '@/services/params';

export const getIncomeAll = (p: ReqGetV1SaasBrandIdIncomeAll) =>
  use<ReqGetV1SaasBrandIdIncomeAll, ResGetV1SaasBrandIdIncomeAll>('get', `/v1/saas/brand/${p.id}/income_all`, p);

export const getIncomeToday = (p: ReqGetV1SaasBrandIdIncomeToday) =>
  use<ReqGetV1SaasBrandIdIncomeToday, ResGetV1SaasBrandIdIncomeToday>('get', `/v1/saas/brand/${p.id}/income_today`, p);

export const getIncomeDetailLite = (p: ReqGetV1SaasBrandIdIncomeDetail) =>
  use<ReqGetV1SaasBrandIdIncomeDetail, ResGetV1SaasBrandIdIncomeDetail>(
    'get',
    `/v1/saas/brand/${p.id}/income_detail`,
    p,
  );

export const getIncomeDetailList = (p: ReqGetV1SaasBrandIdIncomeDetailList) =>
  use<ReqGetV1SaasBrandIdIncomeDetailList, ResGetV1SaasBrandIdIncomeDetailList>(
    'get',
    `/v1/saas/brand/${p.id}/income_detail/list`,
    p,
  );

export const getSaleType = (p: ReqGetV1SaasBrandIdSaleType) =>
  use<ReqGetV1SaasBrandIdSaleType, ResGetV1SaasBrandIdSaleType>('get', `/v1/saas/brand/${p.id}/sale_type`, p);

export const getSaleMoneyList = (p: ReqGetV1SaasBrandIdSaleMoneyList) =>
  use<ReqGetV1SaasBrandIdSaleMoneyList, ResGetV1SaasBrandIdSaleMoneyList>(
    'get',
    `/v1/saas/brand/${p.id}/saleMoney/list`,
    p,
  );

export const getSaleCountList = (p: ReqGetV1SaasBrandIdSaleCountAll) =>
  use<ReqGetV1SaasBrandIdSaleCountAll, ResGetV1SaasBrandIdSaleCountAll>(
    'get',
    `/v1/saas/brand/${p.id}/saleCount_all`,
    p,
  );

export const getSalePerformance = (p: ReqGetV1SaasBrandIdSalePerformance) =>
  use<ReqGetV1SaasBrandIdSalePerformance, ResGetV1SaasBrandIdSalePerformance>(
    'get',
    `/v1/saas/brand/${p.id}/sale_performance`,
    p,
  );

export const getSalePerformanceList = (p: ReqGetV1SaasBrandIdSalePerformanceList) =>
  use<ReqGetV1SaasBrandIdSalePerformanceList, ResGetV1SaasBrandIdSalePerformanceList>(
    'get',
    `/v1/saas/brand/${p.id}/sale_performance/list`,
    p,
  );

export const getSaleStaffDetail = (p: ReqGetV1SaasBrandIdStaffStaffIdSaleList) =>
  use<ReqGetV1SaasBrandIdStaffStaffIdSaleList, ResGetV1SaasBrandIdStaffStaffIdSaleList>(
    'get',
    `/v1/saas/brand/${p.id}/staff/${p.staff_id}/sale_list`,
    p,
  );

export const getStaffCourseDetail = (p: ReqGetV1SaasBrandIdStaffStaffIdCleanList) =>
  use<ReqGetV1SaasBrandIdStaffStaffIdCleanList, ResGetV1SaasBrandIdStaffStaffIdCleanList>(
    'get',
    `/v1/saas/brand/${p.id}/staff/${p.staff_id}/clean_list`,
    p
  )

export const getSaleMerchandise = (p: ReqGetV1SaasBrandIdMerchandiseSales) =>
  use<ReqGetV1SaasBrandIdMerchandiseSales, ResGetV1SaasBrandIdMerchandiseSales>(
    'get',
    `/v1/saas/brand/${p.id}/merchandise_sales`,
    p,
  );

export const getSaleMerchandiseList = (p: ReqGetV1SaasBrandIdMerchandiseSalesList) =>
  use<ReqGetV1SaasBrandIdMerchandiseSalesList, ResGetV1SaasBrandIdMerchandiseSalesList>(
    'get',
    `/v1/saas/brand/${p.id}/merchandise_sales/list`,
    p,
  );

export const getOrdersAll = (p: ReqGetV1SaasBrandIdOrderAll) =>
  use<ReqGetV1SaasBrandIdOrderAll, ResGetV1SaasBrandIdOrderAll>('get', `/v1/saas/brand/${p.id}/order_all`, p);

export const getOrdersCount = (p: ReqGetV1SaasBrandIdOrderCount) =>
  use<ReqGetV1SaasBrandIdOrderCount, ResGetV1SaasBrandIdOrderCount>('get', `/v1/saas/brand/${p.id}/order_count`, p);

export const getOrdersCountList = (p: ReqGetV1SaasBrandIdOrderCountList) =>
  use<ReqGetV1SaasBrandIdOrderCountList, ResGetV1SaasBrandIdOrderCountList>(
    'get',
    `/v1/saas/brand/${p.id}/order_count/list`,
    p,
  );

export const getOrdersDetailList = (p: ReqGetV1SaasBrandIdOrderList) =>
  use<ReqGetV1SaasBrandIdOrderList, ResGetV1SaasBrandIdOrderList>('get', `/v1/saas/brand/${p.id}/order/list`, p);

export const getDashboardCoursesAll = (p: ReqGetV1SaasBrandIdCourseAll) =>
  use<ReqGetV1SaasBrandIdCourseAll, ResGetV1SaasBrandIdCourseAll>('get', `/v1/saas/brand/${p.id}/course_all`, p);

export const getDashboardPriceAll = (p: ReqGetV1SaasBrandIdCleanValue) =>
  use<ReqGetV1SaasBrandIdCleanValue, ResGetV1SaasBrandIdCleanValue>('get', `/v1/saas/brand/${p.id}/clean/value`, p);

export const getDashboardPriceList = (p: ReqGetV1SaasBrandIdCleanMember) =>
  use<ReqGetV1SaasBrandIdCleanMember, ResGetV1SaasBrandIdCleanMember>(
    'get',
    `/v1/saas/brand/${p.id}/clean/member`,
    p,
  );
export const getDashboardCoursesLite = (p: ReqGetV1SaasBrandIdClassCount) =>
  use<ReqGetV1SaasBrandIdClassCount, ResGetV1SaasBrandIdClassCount>('get', `/v1/saas/brand/${p.id}/class_count`, p);

export const getDashboardCoursesList = (p: ReqGetV1SaasBrandIdClassCountList) =>
  use<ReqGetV1SaasBrandIdClassCountList, ResGetV1SaasBrandIdClassCountList>(
    'get',
    `/v1/saas/brand/${p.id}/class_count/list`,
    p,
  );
