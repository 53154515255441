import React, {  useState } from 'react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Space, Upload, UploadFile } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { selectUser } from '@/model/userSlice';
import { useAppSelector } from '@/hooks';


interface IProps {
  count?: number;
  action: string;
  value?: string[];
  type?: string;
  onChange?: (value: string[]) => void;
  disable?: boolean;
}

const UploadFiles: React.FC<IProps> = ({ action, onChange, disable = false, type = '', count = 3, value = [] }) => {
  const { accessToken } = useAppSelector(selectUser);
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) message.error('请上传小于5M的文件');
    return isLt2M;
  };

  const handleChange = ({ file,fileList }: UploadChangeParam) => {
    
    setFileList(fileList)

    if (file.status === 'error') {
      return message.error({
        content: '上传失败！',
        key: 'error',
      });
    }
    if (file.status === 'done') {
      onChange?.([...value, file.url ?? file.response?.result]);
      message.success({
        content: '上传成功！',
        key: 'success',
      });
    }
  };
  
  return (
    <Space className='w-full' direction='vertical'>
      <Upload
        accept={type}
        action={action}
        beforeUpload={beforeUpload}
        disabled={value.length === count}
        fileList={fileList}
        headers={{
          'x-AppId': 'cyber-saas',
          Authorization: `Bearer ${accessToken}`,
        }}
        maxCount={count}
        name="file"
        onChange={handleChange}
        showUploadList={false}
      >
        <Button disabled={disable} icon={<UploadOutlined />} >
          上传
        </Button>
      </Upload>
      <Space className='overflow-x-auto whitespace-nowrap w-full' direction='horizontal'>
        {value.map((_, i) =>
          <div key={_}>
            <a href={value[i]} rel="noreferrer" target='_blank'>{fileList.find(f => f.response.result === _)?.name}</a><DeleteOutlined className='ml-2 cursor-pointer' onClick={() => {
              onChange?.(value.filter(url => url!== _))
              setFileList(fileList.filter((_,__) => i!== __))
            }}/>
          </div>)
        }
      </Space>
    </Space>
  );
};

export default UploadFiles;
