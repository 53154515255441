import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
  title: string;
  subTitle?: string;
  onBack?: boolean;
}

const PageHeader = ({ title, subTitle, onBack = true }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white mb-6 rounded shadow overflow-hidden py-4 px-6 flex items-center gap-4">
      {onBack ? (
        <span className="cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
        </span>
      ) : null}
      <span className="font-bold text-lg">{title}</span>
      {subTitle ? <span className="text-sm text-gray-400">{subTitle}</span> : null}
    </div>
  );
};

export default PageHeader;
