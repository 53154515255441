import use, { downloadInterceptor } from '@/services/saas.request';
import {
  ReqGetV1SaasStudioIdVipList,
  ResGetV1SaasStudioIdVipList,
  ReqPostV1SaasStudioIdVipExport,
  ResPostV1SaasStudioIdVipExport,
  ReqGetV1SaasStudioIdVipTemporaryList,
  ResGetV1SaasStudioIdVipTemporaryList,
  ReqPutV1SaasStudioStudioIdVipVipIdInfo,
  ResPutV1SaasStudioStudioIdVipVipIdInfo,
  ReqGetV1SaasStudioStudioIdVipVipIdInfo,
  ResGetV1SaasStudioStudioIdVipVipIdInfo,
  ReqGetV1SaasStudioStudioIdVipVipIdPkgList,
  ResGetV1SaasStudioStudioIdVipVipIdPkgList,
  ReqGetV1SaasStudioStudioIdVipVipIdAppointmentList,
  ResGetV1SaasStudioStudioIdVipVipIdAppointmentList,
  ReqGetV1SaasStudioStudioIdVipVipIdEliminateList,
  ResGetV1SaasStudioStudioIdVipVipIdEliminateList,
  ReqGetV1SaasStudioStudioIdOrderOrderIdPkgPkgIdInfo,
  ResGetV1SaasStudioStudioIdOrderOrderIdPkgPkgIdInfo,
  ReqPutV1SaasStudioStudioIdVipVipIdOrderOrderIdRefund,
  ResPutV1SaasStudioStudioIdVipVipIdOrderOrderIdRefund,
  ReqGetV1SaasStudioStudioIdVipVipIdTemporaryTimeList,
  ResGetV1SaasStudioStudioIdVipVipIdTemporaryTimeList,
  ReqGetV1SaasStudioStudioIdVipVipIdTemporaryDegreeList,
  ResGetV1SaasStudioStudioIdVipVipIdTemporaryDegreeList,
  ReqGetV1SaasStudioStudioIdVipVipIdExpirationList,
  ResGetV1SaasStudioStudioIdVipVipIdExpirationList,
  ReqGetV1SaasStudioStudioIdVipVipIdTrace,
  ResGetV1SaasStudioStudioIdVipVipIdTrace,
  ReqPostV1SaasStudioStudioIdVipVipIdTrace,
  ResPostV1SaasStudioStudioIdVipVipIdTrace,
  ReqPostV1SaasStudioStudioIdVip,
  ResPostV1SaasStudioStudioIdVip,
  ReqPostV1SaasStudioStudioIdVipVipId,
  ResPostV1SaasStudioStudioIdVipVipId,
  ReqPostV1SaasStudioStudioIdVipPrice,
  ResPostV1SaasStudioStudioIdVipPrice,
  ReqPutV1SaasStudioIdVipVipIdCurriculumCurriculumId,
  ResPutV1SaasStudioIdVipVipIdCurriculumCurriculumId,
  ReqGetV1SaasStudioIdVipVipIdCurriculumCurriculumIdList,
  ResGetV1SaasStudioIdVipVipIdCurriculumCurriculumIdList,
  ReqPostV1SaasStudioIdVipVipIdOrderOrderIdDelay,
  ResPostV1SaasStudioIdVipVipIdOrderOrderIdDelay,
  ReqPostV1SaasStudioIdVipVipIdOrderOrderIdPointsAfter,
  ResPostV1SaasStudioIdVipVipIdOrderOrderIdPointsAfter,
  ReqGetV1SaasStudioStudioIdVipVipIdPointsList,
  ResGetV1SaasStudioStudioIdVipVipIdPointsList,
  ReqGetV1SaasStudioIdVipVipIdIntegralChangeList,
  ResGetV1SaasStudioIdVipVipIdIntegralChangeList,
  ReqGetV1SaasStudioIdVipIntegralIdRelieveFreezeLogs,
  ResGetV1SaasStudioIdVipIntegralIdRelieveFreezeLogs,
  ReqPutV1SaasStudioIdVipVipIdRelieveFreeze,
  ResPutV1SaasStudioIdVipVipIdRelieveFreeze,
  ReqPutV1SaasStudioIdVipVipIdMinusPoints,
  ResPutV1SaasStudioIdVipVipIdMinusPoints,
  ReqPutV1SaasStudioIdVipVipIdDelayDays,
  ResPutV1SaasStudioIdVipVipIdDelayDays,
  ReqGetV1SaasStudioIdVipVipIdUsedPkgList,
  ResGetV1SaasStudioIdVipVipIdUsedPkgList,
  ReqGetV1SaasStudioIdVipVipIdUsedIntegralList,
  ResGetV1SaasStudioIdVipVipIdUsedIntegralList,
  ReqPostV1SaasStudioScrip,
  ResPostV1SaasStudioScrip,
  ReqPostV1SaasStudioScripLess,
  ResPostV1SaasStudioScripLess,
  ReqPutV1SaasStudioIdVipVipIdIntegralFreeze,
  ResPutV1SaasStudioIdVipVipIdIntegralFreeze,
  ReqGetV1SaasStudioIdBlackVipList,
  ResGetV1SaasStudioIdBlackVipList,
  ReqGetV1SaasStudioIdVipVipIdFollowsList,
  ReqPostV1SaasStudioIdVipVipIdFollows,
  ResGetV1SaasStudioIdVipVipIdFollowsList,
  ReqGetV1SaasStudioStudioIdVipVipIdTemporaryIntegralList,
  ResGetV1SaasStudioStudioIdVipVipIdTemporaryIntegralList,
  ReqGetV1SaasStudioStudioIdVipVipIdExpiredIntegralList,
  ResGetV1SaasStudioStudioIdVipVipIdExpiredIntegralList,
  ReqPostV1SaasStudioIdVipVipIdCheck,
  ReqGetV1SaasStudioIdVipVipIdUsers,
  ResPostV1SaasStudioIdVipVipIdCheck,
  ResGetV1SaasStudioIdVipVipIdUsers,
} from '@/services/params';

export const getVipStudioUseful = (p: ReqGetV1SaasStudioIdBlackVipList) =>
  use<ReqGetV1SaasStudioIdBlackVipList, ResGetV1SaasStudioIdBlackVipList>('get', `/v1/saas/studio/${p.id}/black/vip/list`, p);
export const getVipStudio = (p: ReqGetV1SaasStudioIdVipList) =>
  use<ReqGetV1SaasStudioIdVipList, ResGetV1SaasStudioIdVipList>('get', `/v1/saas/studio/${p.id}/vip/list`, p);

export const exportVipStudio = (p: ReqPostV1SaasStudioIdVipExport, type: string) =>
  use<ReqPostV1SaasStudioIdVipExport, ResPostV1SaasStudioIdVipExport>(
    'post',
    `/v1/saas/studio/${p.id}/vip/export`,
    p,
    downloadInterceptor<ResPostV1SaasStudioIdVipExport>(type),
  );

export const getVipWarnStudio = (p: ReqGetV1SaasStudioIdVipTemporaryList) =>
  use<ReqGetV1SaasStudioIdVipTemporaryList, ResGetV1SaasStudioIdVipTemporaryList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/temporary/list`,
    p,
  );

export const getVipTraceStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdTrace) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdTrace, ResGetV1SaasStudioStudioIdVipVipIdTrace>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/trace`,
    p,
  );

export const postVipTraceStudio = (p: ReqPostV1SaasStudioStudioIdVipVipIdTrace) =>
  use<ReqPostV1SaasStudioStudioIdVipVipIdTrace, ResPostV1SaasStudioStudioIdVipVipIdTrace>(
    'post',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/trace`,
    p,
  );

export const getVipInfoStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdInfo) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdInfo, ResGetV1SaasStudioStudioIdVipVipIdInfo>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/info`,
    p,
  );

export const getVipIntegralChangeList = (p: ReqGetV1SaasStudioIdVipVipIdIntegralChangeList) =>
  use<ReqGetV1SaasStudioIdVipVipIdIntegralChangeList, ResGetV1SaasStudioIdVipVipIdIntegralChangeList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/integralChange/list`,
    p
  )
export const putVipStudio = (p: ReqPutV1SaasStudioStudioIdVipVipIdInfo) =>
  use<ReqPutV1SaasStudioStudioIdVipVipIdInfo, ResPutV1SaasStudioStudioIdVipVipIdInfo>(
    'put',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/info`,
    p,
  );

export const getVipSaleList = (p: ReqGetV1SaasStudioIdVipVipIdFollowsList) =>
  use<ReqGetV1SaasStudioIdVipVipIdFollowsList, ResGetV1SaasStudioIdVipVipIdFollowsList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/follows/list`,
    p
  )

export const postVipSale = (p: ReqPostV1SaasStudioIdVipVipIdFollows) =>
  use<ReqPostV1SaasStudioIdVipVipIdFollows, ReqPostV1SaasStudioIdVipVipIdFollows>(
    'post',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/follows`,
    p
  )

export const getVipPkgStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdPkgList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdPkgList, ResGetV1SaasStudioStudioIdVipVipIdPkgList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/pkg/list`,
    p,
  );
export const getVipIntegralListStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdPointsList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdPointsList, ResGetV1SaasStudioStudioIdVipVipIdPointsList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/points/list`,
    p
  )
export const freezePkgStudio = (p: ReqPutV1SaasStudioIdVipVipIdCurriculumCurriculumId) =>
  use<ReqPutV1SaasStudioIdVipVipIdCurriculumCurriculumId, ResPutV1SaasStudioIdVipVipIdCurriculumCurriculumId>(
    'put',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/curriculum/${p.curriculumId}`,
    p
  )
export const freezePkgRecordStudio = (p: ReqGetV1SaasStudioIdVipVipIdCurriculumCurriculumIdList) =>
  use<ReqGetV1SaasStudioIdVipVipIdCurriculumCurriculumIdList, ResGetV1SaasStudioIdVipVipIdCurriculumCurriculumIdList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/curriculum/${p.curriculumId}/list`,
    p
  )
export const getVipAppointmentStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdAppointmentList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdAppointmentList, ResGetV1SaasStudioStudioIdVipVipIdAppointmentList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/appointment/list`,
    p,
  );
export const getVipUsedStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdEliminateList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdEliminateList, ResGetV1SaasStudioStudioIdVipVipIdEliminateList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/eliminate/list`,
    p,
  );

export const getVipOrderStudio = (p: ReqGetV1SaasStudioStudioIdOrderOrderIdPkgPkgIdInfo) =>
  use<ReqGetV1SaasStudioStudioIdOrderOrderIdPkgPkgIdInfo, ResGetV1SaasStudioStudioIdOrderOrderIdPkgPkgIdInfo>(
    'get',
    `/v1/saas/studio/${p.studioId}/order/${p.orderId}/pkg/${p.pkgId}/info`,
    p,
  );
export const freezeIntegralRecordStudio = (p: ReqGetV1SaasStudioIdVipIntegralIdRelieveFreezeLogs) =>
  use<ReqGetV1SaasStudioIdVipIntegralIdRelieveFreezeLogs, ResGetV1SaasStudioIdVipIntegralIdRelieveFreezeLogs>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.integralId}/relieve/freeze/logs`,
    p
  )

export const unFreezeIntegralStudio = (p: ReqPutV1SaasStudioIdVipVipIdRelieveFreeze) =>
  use<ReqPutV1SaasStudioIdVipVipIdRelieveFreeze, ResPutV1SaasStudioIdVipVipIdRelieveFreeze>(
    'put',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/relieve/freeze`,
    p
  )
export const reduceIntegralStudio = (p: ReqPutV1SaasStudioIdVipVipIdMinusPoints) =>
  use<ReqPutV1SaasStudioIdVipVipIdMinusPoints, ResPutV1SaasStudioIdVipVipIdMinusPoints>(
    'put',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/minus/points`,
    p
  )
export const delayIntegralStudio = (p: ReqPutV1SaasStudioIdVipVipIdDelayDays) =>
  use<ReqPutV1SaasStudioIdVipVipIdDelayDays, ResPutV1SaasStudioIdVipVipIdDelayDays>(
    'put',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/delay/days`,
    p
  )

export const freezeIntegralStudio = (p: ReqPutV1SaasStudioIdVipVipIdIntegralFreeze) =>
  use<ReqPutV1SaasStudioIdVipVipIdIntegralFreeze, ResPutV1SaasStudioIdVipVipIdIntegralFreeze>(
    'put',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/integral/freeze`,
    p
  )
export const putVipRefundStudio = (p: ReqPutV1SaasStudioStudioIdVipVipIdOrderOrderIdRefund) =>
  use<ReqPutV1SaasStudioStudioIdVipVipIdOrderOrderIdRefund, ResPutV1SaasStudioStudioIdVipVipIdOrderOrderIdRefund>(
    'put',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/order/${p.orderId}/refund`,
    p,
  );
export const putOrderAfterStudio = (p: ReqPostV1SaasStudioIdVipVipIdOrderOrderIdDelay) =>
  use<ReqPostV1SaasStudioIdVipVipIdOrderOrderIdDelay, ResPostV1SaasStudioIdVipVipIdOrderOrderIdDelay>(
    'post',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/order/${p.orderId}/delay`,
    p
  )
export const putIntegralAfterStudio = (p: ReqPostV1SaasStudioIdVipVipIdOrderOrderIdPointsAfter) =>
  use<ReqPostV1SaasStudioIdVipVipIdOrderOrderIdPointsAfter, ResPostV1SaasStudioIdVipVipIdOrderOrderIdPointsAfter>(
    'post',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/order/${p.orderId}/points/after`,
    p
  )
export const getTempoTimeStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdTemporaryTimeList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdTemporaryTimeList, ResGetV1SaasStudioStudioIdVipVipIdTemporaryTimeList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/temporary/time/list`,
    p,
  );

export const getTempoDegreeStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdTemporaryDegreeList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdTemporaryDegreeList, ResGetV1SaasStudioStudioIdVipVipIdTemporaryDegreeList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/temporary/degree/list`,
    p,
  );
export const getTempoIntegralStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdTemporaryIntegralList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdTemporaryIntegralList, ResGetV1SaasStudioStudioIdVipVipIdTemporaryIntegralList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/temporary/integral/list`,
    p
  )
export const getExpirationStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdExpirationList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdExpirationList, ResGetV1SaasStudioStudioIdVipVipIdExpirationList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/expiration/list`,
    p,
  )

export const getExpirationIntegralStudio = (p: ReqGetV1SaasStudioStudioIdVipVipIdExpiredIntegralList) =>
  use<ReqGetV1SaasStudioStudioIdVipVipIdExpiredIntegralList, ResGetV1SaasStudioStudioIdVipVipIdExpiredIntegralList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}/expired/integral/list`,
    p
  )
export const addVip = (p: ReqPostV1SaasStudioStudioIdVip) =>
  use<ReqPostV1SaasStudioStudioIdVip, ResPostV1SaasStudioStudioIdVip>(
    'post',
    `/v1/saas/studio/${p.studioId}/vip`,
    p
  )

export const vipAddPkg = (p: ReqPostV1SaasStudioStudioIdVipVipId) =>
  use<ReqPostV1SaasStudioStudioIdVipVipId, ResPostV1SaasStudioStudioIdVipVipId>(
    'post',
    `/v1/saas/studio/${p.studioId}/vip/${p.vipId}`,
    p
  )

export const checkPrice = (p: ReqPostV1SaasStudioStudioIdVipPrice) =>
  use<ReqPostV1SaasStudioStudioIdVipPrice, ResPostV1SaasStudioStudioIdVipPrice>(
    'post',
    `/v1/saas/studio/${p.studioId}/vip/price`,
    p
  )
export const useingPkgStudio = (p: ReqGetV1SaasStudioIdVipVipIdUsedPkgList) =>
  use<ReqGetV1SaasStudioIdVipVipIdUsedPkgList, ResGetV1SaasStudioIdVipVipIdUsedPkgList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/used/pkg/list`
  )
export const useingIntegralStudio = (p: ReqGetV1SaasStudioIdVipVipIdUsedIntegralList) =>
  use<ReqGetV1SaasStudioIdVipVipIdUsedIntegralList, ResGetV1SaasStudioIdVipVipIdUsedIntegralList>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/used/integral/list`
  )


export const addScripStudio = (p: ReqPostV1SaasStudioScrip) =>
  use<ReqPostV1SaasStudioScrip, ResPostV1SaasStudioScrip>(
    'post',
    `/v1/saas/studio/scrip`,
    p
  )
export const reduceScripStudio = (p: ReqPostV1SaasStudioScripLess) =>
  use<ReqPostV1SaasStudioScripLess, ResPostV1SaasStudioScripLess>(
    'post',
    `/v1/saas/studio/scrip/less`,
    p
  )

export const postExchange = (p: ReqPostV1SaasStudioIdVipVipIdCheck) =>
  use<ReqPostV1SaasStudioIdVipVipIdCheck, ResPostV1SaasStudioIdVipVipIdCheck>(
    'post',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/check`,
    p
  )

export const getVipUsers = (p: ReqGetV1SaasStudioIdVipVipIdUsers) =>
  use<ReqGetV1SaasStudioIdVipVipIdUsers, ResGetV1SaasStudioIdVipVipIdUsers>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/users`,
    p
  )