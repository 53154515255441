import React, { useMemo, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Divider, Drawer, Input, List, message, Space, Typography } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import PinyinMatch from 'pinyin-match';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';
import { getStudioUnderBrand, putUserStudio } from '@/services';

const Choose: React.FC = () => {
  const { brandInfo } = useAppSelector(selectUser);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');

  const { data, isFetching } = useQuery(
    ['getStudioUnderBrand'],
    () => getStudioUnderBrand({ id: brandInfo?.id || '' }),
    {
      enabled: visible,
    },
  );
  const selectStudio = useMutation(putUserStudio);

  const dataSource = useMemo(() => {
    const result = data?.result;
    if (!search) return result;
    return result?.filter((_) => PinyinMatch.match(_.name.toLowerCase(), search.toLowerCase()));
  }, [search, data]);

  const handleClick = async (id: string) => {
    const { success, msg } = await selectStudio.mutateAsync({ id });
    if (success) window.location.href = '/';
    else message.error(msg);
  };

  return (
    <>
      <div
        className="px-3 py-1 rounded bg-slate-900 hover:bg-slate-700 transition-all duration-200 text-white text-center cursor-pointer"
        onClick={() => setVisible(true)}>
        <Space className="whitespace-nowrap">
          前往门店
          <CaretRightOutlined />
        </Space>
      </div>

      <Drawer
        closable={false}
        destroyOnClose
        onClose={() => setVisible(false)}
        open={visible}
        placement="left"
        title={
          <Space>
            前往门店 <Divider type="vertical" />
            <Input.Search
              allowClear
              onChange={(e) => setSearch(e.target.value)}
              placeholder="搜索门店名称"
              value={search}
            />
          </Space>
        }
        width={420}>
        <List
          dataSource={dataSource}
          itemLayout="horizontal"
          loading={isFetching || selectStudio.isLoading}
          renderItem={(_) => (
            <List.Item
              className="rounded cursor-pointer transition-all duration-200 hover:bg-gray-300"
              extra={<img alt={_.name} src={_.signage} width={80} />}
              onClick={() => handleClick(_.id)}>
              <List.Item.Meta
                description={
                  <Typography.Text ellipsis style={{ width: 200, fontSize: '12px' }} type="secondary">
                    {_.address}
                  </Typography.Text>
                }
                title={_.name}
              />
            </List.Item>
          )}
          split={false}
        />
      </Drawer>
    </>
  );
};

export default Choose;
