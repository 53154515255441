import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { action__setUserinfo, selectUser } from '@/model/userSlice';
import { getUserinfo } from '@/services';
import { LoadingPage } from '@/components';

const AuthIntercept = () => {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(selectUser);

  const { isLoading, isError } = useQuery(
    ['getUserinfo'],
    async () => {
      const response = await getUserinfo();
      if (response.success) return dispatch(action__setUserinfo(response.result));
      else return Promise.reject(response);
    },
    {
      enabled: !!accessToken,
    },
  );

  if (!accessToken) return <Navigate replace to={`/login?from=${encodeURIComponent(pathname + search)}`} />;

  if (isLoading) return <LoadingPage content="获取用户信息" dots />;
  if (isError) throw new Error('获取用户信息错误');

  return <Outlet />;
};

export default AuthIntercept;
