import React, { useMemo } from 'react';
import { Button, ButtonProps } from 'antd';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';

type IProps = {
  PageKey?: string
  ButtonKey?: string
} & ButtonProps
const AuthButton: React.FC<IProps> = ({ PageKey, ButtonKey, ...props }) => {
  const { userinfo } = useAppSelector(selectUser);
  const disabled = useMemo(() => {
    if (!PageKey) return false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((userinfo?.permissions as any)?.[PageKey]?.includes(ButtonKey)) return false
    return true
  }, [userinfo])
  return (
    <Button disabled={disabled} {...props} />
  )
}

export default AuthButton;
