import {
  DashboardOutlined,
  PayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  ShopOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  BookOutlined,
} from '@ant-design/icons';
import generate from './_generate';

/**
 *  KEY 唯一值，与权限点关联
 *  name 必须
 *  path 必须
 *  nopage 如果非页面路径，配置为true
 *  icon 一级菜单才有
 * */
export const routes = [
  { KEY: 'DASHBOARD', name: '仪表盘', path: '/brand/dashboard', icon: <DashboardOutlined /> },
  {
    KEY: 'BRAND.FINANCE',
    name: '财务管理',
    path: '/brand/finance',
    nopage: true,
    icon: <PayCircleOutlined />,
    children: [
      { KEY: 'BRAND.FINANCE.PAYMENTS', name: '收支流水', path: '/brand/finance/payments' },
      { KEY: 'BRAND.FINANCE.CASH', name: '我的余额', path: '/brand/finance/cash' },
    ],
  },
  {
    KEY: 'BRAND.COURSES',
    name: '课程经营',
    path: '/brand/courses',
    nopage: true,
    icon: <ReadOutlined />,
    children: [
      {
        KEY: 'BRAND.COURSES.POINTS',
        name: '积分',
        path: '/brand/courses/points',
        children:[
          {
            KEY:'BRAND.COURSES.POINTS.CTREAE',
            name:'添加积分档位',
            path: '/brand/courses/points/create'
          },
          {
            KEY:'BRAND.COURSES.POINTS.EDIT',
            name:'修改积分档位',
            path: '/brand/courses/points/edit'
          }
        ]
      },
      {
        KEY: 'BRAND.COURSES.PKG',
        name: '课程套餐',
        path: '/brand/courses/pkg',
        children: [
          {
            KEY: 'BRAND.COURSES.PKG.CREATE',
            name: '新增套餐',
            path: '/brand/courses/pkg/create',
          },
          {
            KEY: 'BRAND.COURSES.PKG.EDIT',
            name: '编辑套餐',
            path: '/brand/courses/pkg/edit',
          },
          {
            KEY: 'BRAND.COURSES.PKG.DETAIL',
            name: '套餐详情',
            path: '/brand/courses/pkg/detail',
          },
        ],
      },
      {
        KEY: 'BRAND.COURSES.LIB',
        name: '课程库',
        path: '/brand/courses/lib',
        children: [
          {
            KEY: 'BRAND.COURSES.LIB.DETAIL',
            name: '课程详情',
            path: '/brand/courses/lib/detail',
          },
          {
            KEY: 'BRAND.COURSES.LIB.CREATE',
            name: '新增课程',
            path: '/brand/courses/lib/create',
          },
          {
            KEY: 'BRAND.COURSES.LIB.EDIT',
            name: '编辑课程',
            path: '/brand/courses/lib/edit',
          },
        ],
      },
      { KEY: 'BRAND.COURSES.TAG', name: '授课方式', path: '/brand/courses/tag' },
    ],
  },
  {
    KEY: 'BRAND.SALEACT',
    name: '营销活动',
    path: '/brand/saleactivity',
    nopage: true,
    icon: <BookOutlined />,
    children: [
      {
        KEY: 'BRAND.SALEACT.COIN',
        name: '紫币运营',
        path: '/brand/saleactivity/coin',
      },
      // {
      //   KEY: 'BRAND.SALEACT.REDUCE',
      //   name: '优惠券',
      //   path: '/brand/saleactivity/reduce',
      //   children:[
      //     { KEY: 'BRAND.SALEACT.REDUCE.CREATE', name: '新增优惠券', path: '/brand/saleactivity/reduce/create' },
      //     { KEY: 'BRAND.SALEACT.REDUCE.DETAIL', name: '优惠券详情', path: '/brand/saleactivity/reduce/detail' },
      //     { KEY: 'BRAND.SALEACT.REDUCE.GIVE', name: '优惠券发放', path: '/brand/saleactivity/reduce/give' },
      //   ]
      // },
    ],
  },
  {
    KEY: 'BRAND.VIP',
    name: '会员管理',
    path: '/brand/vip',
    nopage: true,
    icon: <SolutionOutlined />,
    children: [
      {
        KEY: 'BRAND.VIP.LIST',
        name: '会员列表',
        path: '/brand/vip/list',
        children: [
          { KEY: 'BRAND.VIP.EDIT', name: '会员编辑', path: '/brand/vip/list/edit' },
          {
            KEY: 'BRAND.VIP.DETAIL',
            name: '会员详情',
            path: '/brand/vip/list/detail',
            children: [
              { KEY: 'BRAND.VIP.DETAIL.PKG', name: '套餐订单', path: '/brand/vip/list/detail/pkg' },
              { KEY: 'BRAND.VIP.DETAIL.AFTER', name: '套餐售后', path: '/brand/vip/list/detail/after' },
              { KEY: 'BRAND.VIP.DETAIL.INTEGRAL', name: '积分订单', path: '/brand/vip/list/detail/integral' },
              { KEY: 'BRAND.VIP.DETAIL.INTEGRALAFTER', name: '积分售后', path: '/brand/vip/list/detail/integralAfter' },
            ],
          },
        ],
      },
      { KEY: 'BRAND.VIP.NEAR', name: '临期列表', path: '/brand/vip/near' },
      { KEY: 'BRAND.VIP.OVER', name: '过期列表', path: '/brand/vip/over' },
    ],
  },
  {
    KEY: 'BRAND.ORDER',
    name: '订单管理',
    path: '/brand/order',
    nopage: true,
    icon: <UnorderedListOutlined />,
    children: [
      { KEY: 'BRAND.ORDER.AFTER', name: '售后记录', path: '/brand/order/after' },
    ],
  },
  {
    KEY: 'ORGANIZATION',
    name: '组织架构',
    path: '/brand/organization',
    nopage: true,
    icon: <TeamOutlined />,
    children: [
      {
        KEY: 'ORGANIZATION.STAFF',
        name: '员工管理',
        path: '/brand/organization/staff',
        children: [
          { KEY: 'ORGANIZATION.STAFF.CREATE', name: '新增员工', path: '/brand/organization/staff/create' },
          { KEY: 'ORGANIZATION.STAFF.EDIT', name: '修改员工', path: '/brand/organization/staff/edit' },
        ],
      },
      {
        KEY: 'ORGANIZATION.DEP',
        name: '部门管理',
        path: '/brand/organization/dep',
      },
      {
        KEY: 'ORGANIZATION.ROLE',
        name: '角色管理',
        path: '/brand/organization/role',
        children: [
          { KEY: 'ORGANIZATION.ROLE.CREATE', name: '新增角色', path: '/brand/organization/role/create' },
          { KEY: 'ORGANIZATION.ROLE.EDIT', name: '修改角色', path: '/brand/organization/role/edit' },
        ],
      },
    ],
  },
  {
    KEY: 'BRAND.STUDIO',
    name: '门店管理',
    path: '/brand/studio',
    nopage: true,
    icon: <ShopOutlined />,
    children: [
      {
        KEY: 'BRAND.STUDIO.LIST',
        name: '门店列表',
        path: '/brand/studio/list',
        children: [
          { KEY: 'BRAND.STUDIO.CREATE', name: '新增门店', path: '/brand/studio/create' },
          { KEY: 'BRAND.STUDIO.EDIT', name: '编辑门店', path: '/brand/studio/edit' },
          { KEY: 'BRAND.STUDIO.DETAIL', name: '门店详情', path: '/brand/studio/detail' },
          { KEY: 'BRAND.STUDIO.SETTING', name: '门店设置', path: '/brand/studio/set' },
        ],
      },
    ],
  },
  {
    KEY: 'BRAND.SETTING',
    name: '品牌设置',
    path: '/brand/setting',
    nopage: true,
    icon: <SettingOutlined />,
    children: [
      {
        KEY: 'BRAND.SETTING.INFO',
        name: '品牌信息',
        path: '/brand/setting/info',
      },
      { KEY: 'BRAND.SETTING.IMPORT', name: '数据导入', path: '/brand/setting/import' },
    ],
  },
];

const brandRoutes = generate(routes);
export default brandRoutes;
