import use from '@/services/saas.request';
import {
  ReqDeleteV1SaasBrandBrandIdDept,
  ReqGetV1SaasBrandBrandIdDeptList,
  ReqGetV1SaasBrandBrandIdDeptListAll,
  ReqPostV1SaasBrandBrandIdDept,
  ReqPutV1SaasBrandBrandIdDept,
  ResDeleteV1SaasBrandBrandIdDept,
  ResGetV1SaasBrandBrandIdDeptList,
  ResGetV1SaasBrandBrandIdDeptListAll,
  ResPostV1SaasBrandBrandIdDept,
  ResPutV1SaasBrandBrandIdDept,
  ReqGetV1SaasBrandBrandIdRoleList,
  ResGetV1SaasBrandBrandIdRoleList,
  ReqDeleteV1SaasBrandBrandIdRoleRoleId,
  ResDeleteV1SaasBrandBrandIdRoleRoleId,
  ReqPostV1SaasBrandBrandIdRole,
  ResPostV1SaasBrandBrandIdRole,
  ReqPutV1SaasBrandBrandIdRoleRoleId,
  ResPutV1SaasBrandBrandIdRoleRoleId,
  ReqGetV1SaasBrandBrandIdRoleRoleIdInfo,
  ResGetV1SaasBrandBrandIdRoleRoleIdInfo,
  ReqGetV1SaasBrandBrandIdStaffList,
  ResGetV1SaasBrandBrandIdStaffList,
  ReqPostV1SaasBrandIdStaff,
  ResPostV1SaasBrandIdStaff,
  ReqGetV1SaasBrandBrandIdRoleListAll,
  ResGetV1SaasBrandBrandIdRoleListAll,
  ReqPutV1SaasBrandBrandIdStaffStaffId,
  ResPutV1SaasBrandBrandIdStaffStaffId,
  ReqGetV1SaasBrandBrandIdStaffStaffIdInfo,
  ResGetV1SaasBrandBrandIdStaffStaffIdInfo,
  ReqPutV1SaasBrandBrandIdStaffIdResign,
  ResPutV1SaasBrandBrandIdStaffIdResign,
  ReqGetV1SaasBrandIdStaffLoginfoList,
  ResGetV1SaasBrandIdStaffLoginfoList,
  ReqPutV1SaasBrandBrandIdStafftoStafftoId,
  ResPutV1SaasBrandBrandIdStafftoStafftoId,
  ReqGetV1MenusBrandId,
  ResGetV1MenusBrandId,
} from '@/services/params';

export const getDepAll = (p: ReqGetV1SaasBrandBrandIdDeptListAll) =>
  use<ReqGetV1SaasBrandBrandIdDeptListAll, ResGetV1SaasBrandBrandIdDeptListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/dept/list_all`,
    p,
  );
export const getDep = (p: ReqGetV1SaasBrandBrandIdDeptList) =>
  use<ReqGetV1SaasBrandBrandIdDeptList, ResGetV1SaasBrandBrandIdDeptList>(
    'get',
    `/v1/saas/brand/${p.brandId}/dept/list`,
    p,
  );
export const postDep = (p: ReqPostV1SaasBrandBrandIdDept) =>
  use<ReqPostV1SaasBrandBrandIdDept, ResPostV1SaasBrandBrandIdDept>('post', `/v1/saas/brand/${p.brandId}/dept`, p);
export const deleteDep = (p: ReqDeleteV1SaasBrandBrandIdDept) =>
  use<ReqDeleteV1SaasBrandBrandIdDept, ResDeleteV1SaasBrandBrandIdDept>(
    'delete',
    `/v1/saas/brand/${p.brandId}/dept`,
    p,
  );
export const putDep = (p: ReqPutV1SaasBrandBrandIdDept) =>
  use<ReqPutV1SaasBrandBrandIdDept, ResPutV1SaasBrandBrandIdDept>('put', `/v1/saas/brand/${p.brandId}/dept`, p);

export const getAuthoritiesAll = (p: ReqGetV1MenusBrandId) => use<ReqGetV1MenusBrandId, ResGetV1MenusBrandId>('get', `/v1/menus/${p.brandId}`, p);

export const getRoleAll = (p: ReqGetV1SaasBrandBrandIdRoleListAll) =>
  use<ReqGetV1SaasBrandBrandIdRoleListAll, ResGetV1SaasBrandBrandIdRoleListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/role/list_all`,
    p,
  );
export const getRole = (p: ReqGetV1SaasBrandBrandIdRoleList) =>
  use<ReqGetV1SaasBrandBrandIdRoleList, ResGetV1SaasBrandBrandIdRoleList>(
    'get',
    `/v1/saas/brand/${p.brandId}/role/list`,
    p,
  );

export const deleteRole = (p: ReqDeleteV1SaasBrandBrandIdRoleRoleId) =>
  use<ReqDeleteV1SaasBrandBrandIdRoleRoleId, ResDeleteV1SaasBrandBrandIdRoleRoleId>(
    'delete',
    `/v1/saas/brand/${p.brandId}/role/${p.roleId}`,
    p,
  );

export const postRole = (p: ReqPostV1SaasBrandBrandIdRole) =>
  use<ReqPostV1SaasBrandBrandIdRole, ResPostV1SaasBrandBrandIdRole>('post', `/v1/saas/brand/${p.brandId}/role`, p);

export const putRole = (p: ReqPutV1SaasBrandBrandIdRoleRoleId) =>
  use<ReqPutV1SaasBrandBrandIdRoleRoleId, ResPutV1SaasBrandBrandIdRoleRoleId>(
    'put',
    `/v1/saas/brand/${p.brandId}/role/${p.roleId}`,
    p,
  );

export const getRoleInfo = (p: ReqGetV1SaasBrandBrandIdRoleRoleIdInfo) =>
  use<ReqGetV1SaasBrandBrandIdRoleRoleIdInfo, ResGetV1SaasBrandBrandIdRoleRoleIdInfo>(
    'get',
    `/v1/saas/brand/${p.brandId}/role/${p.roleId}/info`,
    p,
  );

export const getStaffList = (p: ReqGetV1SaasBrandBrandIdStaffList) =>
  use<ReqGetV1SaasBrandBrandIdStaffList, ResGetV1SaasBrandBrandIdStaffList>(
    'get',
    `/v1/saas/brand/${p.brandId}/staff/list`,
    p,
  );

export const postStaff = (p: ReqPostV1SaasBrandIdStaff) =>
  use<ReqPostV1SaasBrandIdStaff, ResPostV1SaasBrandIdStaff>('post', `/v1/saas/brand/${p.id}/staff`, p);

export const putStaff = (p: ReqPutV1SaasBrandBrandIdStaffStaffId) =>
  use<ReqPutV1SaasBrandBrandIdStaffStaffId, ResPutV1SaasBrandBrandIdStaffStaffId>(
    'put',
    `/v1/saas/brand/${p.brandId}/staff/${p.staffId}`,
    p,
  );

export const getStaff = (p: ReqGetV1SaasBrandBrandIdStaffStaffIdInfo) =>
  use<ReqGetV1SaasBrandBrandIdStaffStaffIdInfo, ResGetV1SaasBrandBrandIdStaffStaffIdInfo>(
    'get',
    `/v1/saas/brand/${p.brandId}/staff/${p.staffId}/info`,
    p,
  );

export const staffResign = (p: ReqPutV1SaasBrandBrandIdStaffIdResign) =>
  use<ReqPutV1SaasBrandBrandIdStaffIdResign, ResPutV1SaasBrandBrandIdStaffIdResign>(
    'put',
    `/v1/saas/brand/${p.brandId}/staff/${p.id}/resign`,
    p,
  );

export const staffLogIngo = (p: ReqGetV1SaasBrandIdStaffLoginfoList) =>
  use<ReqGetV1SaasBrandIdStaffLoginfoList, ResGetV1SaasBrandIdStaffLoginfoList>(
    'get',
    `/v1/saas/brand/${p.id}/staff/loginfo/list`,
    p
  )

export const transferPower = (p: ReqPutV1SaasBrandBrandIdStafftoStafftoId) =>
  use<ReqPutV1SaasBrandBrandIdStafftoStafftoId, ResPutV1SaasBrandBrandIdStafftoStafftoId>(
    'put',
    `/v1/saas/brand/${p.brandId}/staffto/${p.stafftoId}`,
    p
  )