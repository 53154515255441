import type { FC } from 'react';
import createFromIconfontCN from '@ant-design/icons/es/components/IconFont';
import { IconBaseProps } from '@ant-design/icons/es/components/Icon';

const Icon = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/c/font_3570943_oc8vp4usu7i.js', // cyber-admin
    '//at.alicdn.com/t/c/font_2198486_6lryoo7hf78.js', // cyber-common
    '//at.alicdn.com/t/c/font_3876245_737h3nhrzlx.js', //cyber-third
  ],
});

interface IProps extends IconBaseProps {
  type: string;
}

const IconFont: FC<IProps> = ({ type, ...props }) => {
  return <Icon type={type} {...props} />;
};

export default IconFont;
