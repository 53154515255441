import type { ReactNode } from 'react';
import { lazy } from 'react';
import { motion } from 'framer-motion';
import NotFound from '@/pages/z_common/not_found';

interface IRouteDefine {
  KEY: string;
  name: string;
  nopage?: boolean;
  path: string;
  icon?: ReactNode;
  children?: IRouteDefine[];
}

export interface IRouteReal extends Omit<IRouteDefine, 'children'> {
  l: number;
  element: ReactNode;
  preload: () => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const load = (path: string) => import(`@/pages${path}`).catch(() => ({ default: () => <NotFound /> })); // load不存在的路径捕获到错误，返回一个NotFound

export default function generate(routes: IRouteDefine[], level = 1): IRouteReal[] {
  return routes.reduce((prev, { KEY, nopage, path, name, icon, children }) => {
    let l = level;
    if (!nopage) {
      const preload = () => load(path);
      const Element = lazy(preload);
      prev = prev.concat({
        l,
        KEY,
        name,
        path,
        icon,
        element: withMotion(<Element />, KEY),
        preload,
      });
    }
    if (children?.length) {
      prev = prev.concat(generate(children, ++l));
    }
    return prev;
  }, [] as IRouteReal[]);
}

export const withMotion = (ele: ReactNode, key: string) => {
  return (
    <motion.div
      animate="animate"
      exit="exit"
      initial="initial"
      key={key}
      variants={{
        initial: { opacity: 0, translateX: -20 },
        animate: { opacity: 1, translateX: 0, transition: { duration: 1 } },
        exit: { opacity: 0, translateX: -20, transition: { duration: 1 } },
      }}>
      {ele}
    </motion.div>
  );
};
