import { useState, useEffect } from 'react';
import { TabletOutlined, MessageOutlined, LockOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Row, Col, Button, Form, Input, message } from 'antd';
import { RE__MOBILE } from '@cyberdancer/regexp';
import { useMutation } from '@tanstack/react-query';
import { getVerification, putPasswd } from '@/services';

type Values = Parameters<typeof putPasswd>[number];
interface IProps {
  setBlock: (n: number) => void;
}

const Forget = ({ setBlock }: IProps) => {
  const [sec, setSec] = useState(0);
  const [passwdShow, setPasswdShow] = useState('password');
  const [confirmShow, setConfirmShow] = useState('password');

  const [form] = Form.useForm();

  const mutVerification = useMutation(getVerification);
  const { mutateAsync, isLoading } = useMutation(putPasswd);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (sec > 0) timer = setTimeout(() => setSec((prevState) => --prevState), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [sec]);

  const handleSend = async () => {
    const { phone } = await form.validateFields(['phone']);
    setSec(60)
    const { success, msg } = await mutVerification.mutateAsync({ phone, type: '3' });
    if (success) {
      message.success('短信验证码已发送', 1,);
    }
    else message.error(msg);
  };

  const handleSubmit = async (v: Values) => {
    const { success, msg } = await mutateAsync(v);
    if (success) {
      form.resetFields();
      message.success('密码修改成功', 1, () => setBlock(1));
    } else message.error(msg);
  };

  return (
    <>
      <h1 className="font-medium">忘记密码</h1>
      <Form<Values> form={form} onFinish={handleSubmit}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: '手机号不能为空',
            },
            {
              pattern: RE__MOBILE,
              message: '手机号格式错误',
            },
          ]}>
          <Input allowClear placeholder="手机号" prefix={<TabletOutlined />} size="large" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={14}>
            <Form.Item
              name="verification"
              rules={[
                {
                  required: true,
                  message: '验证码不能为空',
                },
              ]}>
              <Input allowClear placeholder="短信验证码" prefix={<MessageOutlined />} size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item>
              <Button block disabled={sec > 0} onClick={handleSend} size="large">
                {sec > 0 ? `重试(${sec})秒` : '获取验证码'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="passwd"
          rules={[
            () => ({
              validator(_, value) {
                if (!value) return Promise.reject(new Error('密码不能为空'));
                if (value.length < 8) return Promise.reject(new Error('密码长度必须大于等于8位'));
                return Promise.resolve();
              },
            }),
          ]}>
          <Input
            allowClear
            placeholder="密码"
            prefix={<LockOutlined />}
            size="large"
            suffix={
              passwdShow ? (
                <EyeInvisibleOutlined onClick={() => setPasswdShow('')} />
              ) : (
                <EyeOutlined onClick={() => setPasswdShow('password')} />
              )
            }
            type={passwdShow}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) return Promise.reject(new Error('确认密码不能为空'));
                if (value !== getFieldValue('passwd')) return Promise.reject(new Error('两次密码输入不一致'));
                return Promise.resolve();
              },
            }),
          ]}>
          <Input
            allowClear
            placeholder="确认密码"
            prefix={<LockOutlined />}
            size="large"
            suffix={
              confirmShow ? (
                <EyeInvisibleOutlined onClick={() => setConfirmShow('')} />
              ) : (
                <EyeOutlined onClick={() => setConfirmShow('password')} />
              )
            }
            type={confirmShow}
          />
        </Form.Item>

        <Form.Item>
          <Button block htmlType="submit" loading={isLoading} type="primary">
            修改密码
          </Button>
        </Form.Item>
        <p className="text-center">
          <a onClick={() => setBlock(1)}>去登录</a>
        </p>
      </Form>
    </>
  );
};

export default Forget;
