import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Space, Upload} from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';

interface IProps {
  action: string;
  value?: string[];
  count?: number;
  type?: string;
  onChange?: (value: string[]) => void;
}

const UploadMultipleImage: React.FC<IProps> = ({ action, value = [], count = 9, onChange, type = '.png, .jpg, .jpeg' }) => {
  const { accessToken } = useAppSelector(selectUser);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) message.error({
      content: '请上传jpeg/jpg/png格式的图片',
      key: 'isJpgOrPng'
    });
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) message.error({
      content: '请上传小于2M的图片',
      key: 'isLt2M'
    });
    return isJpgOrPng && isLt2M;
  };
  const handleChange = ({ fileList }: UploadChangeParam) => {
    if (!fileList.filter(_ => _.status).find(_ => _.status !=='done')) {
      onChange?.([...value,...fileList.filter(_ => _.status).map(_ => _.response.result).slice(0,count - value.length)]);
    }
  };

  const handleRemove = (i:number) => {
    onChange?.(value?.filter((_,idx) => idx !== i));
  };

  return (
    <Space className='overflow-x-auto whitespace-nowrap w-full pl-1'>
      {value.map((_,i) => (
        <div
          className="group relative flex items-center justify-center w-24 h-24 mb-2 p-1 outline-1 outline outline-slate-300 rounded"
          key={_ + i}>
          <div className="w-full h-full bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${_})` }} />

          <div
            className="group-hover:opacity-100 group-hover:cursor-pointer absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center bg-black/50 text-white opacity-0 transition-all duration-200"
            onClick={() => handleRemove(i)}>
            <DeleteOutlined />
          </div>
        </div>
      ))}

      <Upload
        accept={type}
        action={action}
        beforeUpload={beforeUpload}
        // fileList={fileList}
        headers={{
          'x-AppId': 'cyber-saas',
          Authorization: `Bearer ${accessToken}`,
        }}
        listType="picture-card"
        maxCount={count}
        multiple
        name="file"
        onChange={handleChange}
        showUploadList={false}
        >
        {value.length >= count ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }} />
          </div>
        )}
      </Upload>
    </Space>
  );
};

export default UploadMultipleImage;
