import use from '@/services/saas.request';
import type {
  ReqGetV1MapPcdList,
  ReqGetV1MapSearchSuggestion,
  ReqGetV1SaasUserBrandList,
  ReqGetV1SaasUserInfo,
  ReqGetV1SaasUserStudioList,
  ReqGetV1VerificationType,
  ReqPatchV1SaasUserInfo,
  ReqPostV1SaasLoginPasswd,
  ReqPostV1SaasLoginVerification,
  ReqPostV1SaasUserRegisterPassword,
  ReqPutV1SaasUserBrandDefault,
  ReqPutV1SaasUserPasswd,
  ReqPutV1SaasUserStudioDefault,
  ResGetV1MapPcdList,
  ResGetV1MapSearchSuggestion,
  ResGetV1SaasUserBrandList,
  ResGetV1SaasUserInfo,
  ResGetV1SaasUserStudioList,
  ResGetV1VerificationType,
  ResPatchV1SaasUserInfo,
  ResPostV1SaasLoginPasswd,
  ResPostV1SaasLoginVerification,
  ResPostV1SaasUserRegisterPassword,
  ResPutV1SaasUserBrandDefault,
  ResPutV1SaasUserPasswd,
  ResPutV1SaasUserStudioDefault,
} from './params';

export const getPDC = (p: ReqGetV1MapPcdList) =>
  use<ReqGetV1MapPcdList, ResGetV1MapPcdList>('get', '/v1/map/pcd/list', p);

export const getSuggestion = (p: ReqGetV1MapSearchSuggestion) =>
  use<ReqGetV1MapSearchSuggestion, ResGetV1MapSearchSuggestion>('get', '/v1/map/search/suggestion', p);

export const getVerification = (p: ReqGetV1VerificationType) =>
  use<ReqGetV1VerificationType, ResGetV1VerificationType>('get', `/v1/verification/${p.type}`, p);

export const loginVerification = (p: ReqPostV1SaasLoginVerification) =>
  use<ReqPostV1SaasLoginVerification, ResPostV1SaasLoginVerification>('post', '/v1/saas/login/verification', p);

export const loginPasswd = (p: ReqPostV1SaasLoginPasswd) =>
  use<ReqPostV1SaasLoginPasswd, ResPostV1SaasLoginPasswd>('post', '/v1/saas/login/passwd', p);

export const postUser = (p: ReqPostV1SaasUserRegisterPassword) =>
  use<ReqPostV1SaasUserRegisterPassword, ResPostV1SaasUserRegisterPassword>(
    'post',
    '/v1/saas/user/register/password',
    p,
  );

export const putPasswd = (p: ReqPutV1SaasUserPasswd) =>
  use<ReqPutV1SaasUserPasswd, ResPutV1SaasUserPasswd>('put', '/v1/saas/user/passwd', p);

export const getUserinfo = () => use<ReqGetV1SaasUserInfo, ResGetV1SaasUserInfo>('get', '/v1/saas/user/info');

export const putUserinfo = (p: ReqPatchV1SaasUserInfo) =>
  use<ReqPatchV1SaasUserInfo, ResPatchV1SaasUserInfo>('patch', '/v1/saas/user/info', p);

export const getUserBrandList = () =>
  use<ReqGetV1SaasUserBrandList, ResGetV1SaasUserBrandList>('get', '/v1/saas/user/brand/list');
export const getUserStudioList = () =>
  use<ReqGetV1SaasUserStudioList, ResGetV1SaasUserStudioList>('get', '/v1/saas/user/studio/list');
export const putUserBrand = (p: ReqPutV1SaasUserBrandDefault) =>
  use<ReqPutV1SaasUserBrandDefault, ResPutV1SaasUserBrandDefault>('put', '/v1/saas/user/brand/default', p);
export const putUserStudio = (p: ReqPutV1SaasUserStudioDefault) =>
  use<ReqPutV1SaasUserStudioDefault, ResPutV1SaasUserStudioDefault>('put', '/v1/saas/user/studio/default', p);
