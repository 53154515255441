import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ResGetV1SaasUserInfo, ResGetV1SaasBrandIdInfo, ResGetV1SaasStudioIdInfo } from '@/services/params';

interface IStateUser {
  accessToken: string;
  userinfo: null | ResGetV1SaasUserInfo;
  brandInfo: null | ResGetV1SaasBrandIdInfo;
  studioInfo: null | ResGetV1SaasStudioIdInfo;
}

const initialState: IStateUser = {
  accessToken: '',
  userinfo: null,
  brandInfo: null,
  studioInfo: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    action__logout: () => initialState,
    action__login: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    action__setUserinfo: (state, action: PayloadAction<ResGetV1SaasUserInfo>) => {
      state.userinfo = action.payload;
    },
    action__setBrandInfo: (state, action: PayloadAction<ResGetV1SaasBrandIdInfo>) => {
      state.brandInfo = action.payload;
    },
    action__setStudioInfo: (state, action: PayloadAction<ResGetV1SaasStudioIdInfo>) => {
      state.studioInfo = action.payload;
    },
  },
});

export const { action__logout, action__login, action__setUserinfo, action__setBrandInfo, action__setStudioInfo } =
  userSlice.actions;

export const selectUser = ({ user }: RootState) => user;

export default userSlice.reducer;
