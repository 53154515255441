/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

import AuthIntercept from '@/layout/AuthIntercept';
import StudioLayout from '@/layout/StudioLayout';
import BrandLayout from '@/layout/BrandLayout';
import UserLayout from '@/layout/UserLayout';

import NotFound from '@/pages/z_common/not_found';
import Login from '@/pages/z_common/login';

import studioRoutes from './studio';
import brandRoutes from './brand';
import userRoutes from './user';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';
const useSentryRoutes = wrapUseRoutes(useRoutes);

export const AppRoutes = () => {
  const { userinfo } = useAppSelector(selectUser);
  const renderStudio = useMemo(() => {
    const filterRoutes = (userinfo?.permissions as any)?.['*'] ? studioRoutes : studioRoutes.filter((_) => (userinfo?.permissions as any)?.[_.KEY]);
    return [
      ...filterRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ];
  }, [studioRoutes, userinfo]);
  const renderBrand = useMemo(() => {
    const filterRoutes = (userinfo?.permissions as any)?.['*'] ? brandRoutes : brandRoutes.filter((_) => (userinfo?.permissions as any)?.[_.KEY]);

    return [
      ...filterRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ];
  }, [brandRoutes, userinfo]);

  const element = useSentryRoutes([
    {
      path: '/',
      element: <AuthIntercept />,
      children: [
        { index: true, element: <Navigate replace to={renderStudio.length > 1 ? renderStudio[0].path : '/studio/desktop'} /> },
        {
          path: '/studio',
          element: <StudioLayout />,
          children: renderStudio,
        },
        {
          path: '/brand',
          element: <BrandLayout />,
          children: renderBrand,
        },
        {
          path: '/user',
          element: <UserLayout />,
          children: [
            ...userRoutes, // 个人中心不需要权限控制
            {
              path: '*',
              element: <NotFound />,
            },
          ],
        },
      ],
    },
    { path: '/login', element: <Login /> },
    { path: '*', element: <NotFound notice={false} redirect /> },
  ]);

  return <>{element}</>;
};
