import { Col, Row } from 'antd';
import CarouselBlock from './CarouselBlock';
import LoginForm from './LoginForm';
import PNGLogoWhite from '@/assets/logo-white.png';

const Login = () => {
  return (
    <Row wrap={false}>
      <Col flex="0 0 512px">
        <img alt="智械舞客" className="absolute z-40 h-10 ml-4 mt-4" src={PNGLogoWhite} />
        <CarouselBlock />
      </Col>
      <Col flex={1}>
        <LoginForm />
      </Col>
    </Row>
  );
};

export default Login;
