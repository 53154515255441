import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

import { IData, IRange } from './common';
import AddCard from './addCard';

type IRenderDayData = {
  timeRange: number[];
  data: IData;
};
const transformData: (_: IData) => IRenderDayData = (data) => {
  const dataStartSort = data.sort((_, __) => {
    const pH = dayjs(_.start).hour();
    const pM = dayjs(_.start).minute();
    const nH = dayjs(__.start).hour();
    const nM = dayjs(__.start).minute();
    if (pH === nH) return pM - nM;
    return pH - nH;
  });
  const dataEndSort = data.sort((_, __) => {
    const pH = dayjs(_.end).hour();
    const pM = dayjs(_.end).minute();
    const nH = dayjs(__.end).hour();
    const nM = dayjs(__.end).minute();
    if (pH === nH) return pM - nM;
    return pH - nH;
  });

  const top = dataStartSort[0]?.start;
  const bottom = dataEndSort[dataEndSort.length - 1]?.end;
  const tH = dayjs(top).hour(),
    tM = dayjs(top).minute() < 30 ? 0 : 0.5,
    bH = dayjs(bottom).hour(),
    bM = dayjs(bottom).minute() > 30 ? 1 : 0.5;

  const timeRange = [];
  let s = tH + tM;
  while (s <= bH + bM) {
    timeRange.push(s);
    s += 0.5;
  }

  return { timeRange, data: dataEndSort };
};

interface IProps {
  rowHeight?: number; // 默认90px，对应30分钟
  data: IData;
  range: IRange;
  renderCard?: (data: unknown) => ReactElement;
  clickCardCb?: (data: unknown) => void;
  clickEmptyCb?: (startStamp: number) => void;
}
const Day: FC<IProps> = ({ rowHeight = 90, data, range, renderCard, clickCardCb, clickEmptyCb }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const renderData = useMemo(() => transformData(data), [data]);

  const scheduleMinute = useCallback((start: number, end: number) => Math.floor((end - start) / 1000 / 60), []);
  const timeHeight = useCallback(
    ({ start, end }: IData[number]) => Math.round(scheduleMinute(start, end) * (rowHeight / 30)),
    [scheduleMinute, rowHeight],
  );
  const scheduleTop = useCallback(
    (start: number) => {
      const startHour = renderData.timeRange[0] || 0;
      const scheduleHour = dayjs(start).hour() + dayjs(start).minute() / 60;
      return (scheduleHour - startHour) * (rowHeight * 2);
    },
    [rowHeight, renderData],
  );
  const indicatorTop = useMemo(() => {
    const startHour = renderData.timeRange[0] || 0;
    const endHour = renderData.timeRange[renderData.timeRange.length - 1] || 0;
    const indicatorHour = dayjs().hour() + dayjs().minute() / 60;
    const prevDifference = indicatorHour - startHour;
    const nextDifference = endHour - indicatorHour;

    if (prevDifference <= 0) return 0;
    if (nextDifference <= 0) return (endHour - startHour + 0.5) * (rowHeight * 2);

    return prevDifference * (rowHeight * 2);
  }, [rowHeight, renderData]);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current?.scroll({ top: indicatorTop, behavior: 'smooth' });
      }, 500);
    }
  }, [containerRef, indicatorTop]);

  return (
    <div className="max-h-[720px] flex flex-col">
      <div
        className="relative flex-1 h-0 flex overflow-y-auto border-solid border-0 border-t border-slate-300"
        ref={containerRef}>
        <div className="sticky left-5 right-0 z-20 h-px bg-rose-500" style={{ top: indicatorTop }}>
          <div className="absolute -left-4 -translate-y-1/2 z-30 px-2 py-1 bg-rose-500 text-white text-xs rounded-xl">
            {dayjs().format('HH:mm')}
          </div>
        </div>

        <div className="flex-none font-mono w-20 sticky left-0 z-10">
          {renderData.timeRange.map((no) => (
            <div
              className="p-1 border-solid border-0 border-b border-slate-300 bg-slate-100"
              key={no}
              style={{ boxShadow: '2px 0 8px 0 #f1f5f9', height: rowHeight }}>
              {dayjs().startOf('day').add(no, 'hour').format('HH:mm')}
            </div>
          ))}
        </div>

        {renderData.data.map((_) => (
          <div className="flex-1 min-w-[136px] relative" key={_.id} style={{ width: `${100 / 7}%` }}>
            {renderData.timeRange.map((no) => (
              <div
                className="p-1 border-solid border-0 border-r border-b border-slate-100"
                key={no}
                style={{ height: rowHeight }}>
                <AddCard onClick={() => clickEmptyCb?.(dayjs(range.start).startOf('day').add(no, 'hour').valueOf())} />
              </div>
            ))}

            <div
              className="absolute left-3 right-0 text-sm bg-white border-solid border rounded cursor-pointer hover:shadow-xl duration-200 transition-all"
              onClick={() => clickCardCb?.(_.payload)}
              style={{ top: scheduleTop(_.start), borderColor: _.color || '#64748b' }}>
              <Tooltip title={`${scheduleMinute(_.start, _.end)}分钟`}>
                <span className="absolute top-0 w-1 bg-slate-900 -left-2 rounded" style={{ height: timeHeight(_) }} />
              </Tooltip>
              <div className="p-1 flex items-center gap-1 text-white" style={{ backgroundColor: _.color || '#64748b' }}>
                <svg
                  className="w-3 h-3"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>
                  {dayjs(_.start).format('HH:mm')}&nbsp;至&nbsp;{dayjs(_.end).format('HH:mm')}
                </span>
              </div>
              <div className="p-1">{renderCard ? renderCard(_.payload) : null}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Day;
