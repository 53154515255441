import React from 'react';
import clsx from 'clsx';

import PNGGa from './ga.png';

interface IProps {
  className?: string;
}

const CopyRight: React.FC<IProps> = ({ className }) => (
  <footer className={clsx('py-2 flex flex-col gap-2 justify-center items-center text-xs', className)}>
    <div>Copyright © {new Date().getFullYear()} 杭州智械舞客网络科技有限公司</div>

    <div className="flex gap-10 items-center">
      <span className="flex gap-2 items-center">
        <img alt="网安备案" className="w-4 h-4" src={PNGGa} />
        <a
          className="text-gray-400 hover:text-gray-400"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902003344"
          rel="noreferrer"
          target="_blank"
        >
          浙公网安备 33010902003344号
        </a>
      </span>

      <a
        className="text-gray-400 hover:text-gray-400"
        href="@/features/Copyright/index"
        rel="noreferrer"
        target="_blank"
      >
        浙ICP备2021013630号-2
      </a>
    </div>
  </footer>
);

export default CopyRight;
