import { Iconfont } from '@/components';
import { PlusCircleFilled, SettingFilled } from '@ant-design/icons';
import generate from './_generate';

export const routes = [
  { KEY: 'USER.SETTING', name: '个人设置', path: '/user/setting', icon: <Iconfont type="admin-setting" /> },
  { KEY: 'USER.NEW', name: '创建品牌', path: '/user/new', icon: <Iconfont type="admin-new" /> },
  { KEY: 'USER.JOIN', name: '加盟品牌', path: '/user/join', icon: <PlusCircleFilled /> },
  { KEY: 'USER.COMPANYSETTING', name: '企业管理', path: '/user/companySetting', icon: <SettingFilled /> },
];

const userRoutes = generate(routes);
export default userRoutes;
