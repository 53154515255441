import React, {  useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Modal, Input, message } from 'antd';
interface IProps {
    value: string
    onChange?: (v: string) => void
}
const ChooseColor: React.FC<IProps> = ({ value, onChange }) => {
    const [visible, setVisible] = useState(false);
    const [temp, setTemp] = useState(value)
    // const [color, setColor] = useState(value)
    return (
        <>
            <div className="flex item-center p-0.5 border-solid border border-gray-200 rounded">
                <div className="w-20 h-6 cursor-pointer" onClick={() => {
                    setVisible(true)
                    setTemp(value)
                }} style={{ backgroundColor: value }} />
            </div>
            <Modal
                onCancel={() => {
                    setVisible(false)
                    setTemp(value)
                }}
                onOk={() => {
                    if (!/^#([a-fA-F\d]{6}|[a-fA-F\d]{3})$/.test(temp)) return message.error('请输入正确的16进制颜色')
                    onChange?.(temp)
                    setVisible(false)
                }}
                open={visible}
                title='选择颜色'
            >
                <div className='flex items-center mb-4'>
                    <div className="w-20 h-6 " style={{ backgroundColor: temp }} />
                    <Input className='w-20 ml-4' onChange={_ => setTemp(_.target.value)} value={temp} />
                    {/* <Form>
                        <Form.Item name='color' noStyle rules={[{
                            required: true,
                            validator(_, v) {
                                if () {
                                    setTemp(v)
                                    return Promise.resolve('')
                                }
                                return Promise.reject('请输入正确的16进制颜色')
                            }
                        }]}>
                            <Input className='w-20 ml-4' />
                        </Form.Item>
                    </Form> */}
                </div>
                <HexColorPicker
                    className="!w-full"
                    color={temp}
                    onChange={(color) => setTemp(color)
                    }
                />
            </Modal>

        </>
    );
};

export default ChooseColor;
