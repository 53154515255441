import { useMemo, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import { HexColorPicker } from 'react-colorful';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { action__setPrimaryColor, selectBasic } from '@/model/basicSlice';

const CustomizePrimary = () => {
  const dispatch = useAppDispatch();
  const { primaryColor, darkTheme } = useAppSelector(selectBasic);
  const [visible, setVisible] = useState(false);

  const snapPrimaryColor = useMemo(() => primaryColor, []);

  const onClose = () => {
    dispatch(action__setPrimaryColor(snapPrimaryColor));
    setVisible(false);
  };

  return (
    <>
      <div
        className="fixed right-0 bottom-[50px] w-10 h-10 rounded-l-md flex justify-center items-center bg-gray-100 shadow cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <SettingOutlined />
      </div>

      <Drawer
        closable={false}
        destroyOnClose
        footer={
          <Space>
            <Button onClick={() => setVisible(false)} type="primary">
              确定
            </Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        }
        onClose={onClose}
        open={visible}
        title="主题色设置"
      >
        <div className="flex flex-col gap-4">
          {/*<div className="flex items-center justify-between">*/}
          {/*  <span>暗黑模式</span>*/}
          {/*  <Switch checked={darkTheme} onChange={() => dispatch(action__toggleTheme())} />*/}
          {/*</div>*/}

          {darkTheme ? null : (
            <>
              <div className="outline-1 outline outline-slate-900/25 rounded-sm p-1 flex gap-2 group relative">
                <div className="w-20 h-6" style={{ backgroundColor: primaryColor }} />
                <div className="flex-1">{primaryColor}</div>
              </div>

              <HexColorPicker
                className="!w-full"
                color={primaryColor}
                onChange={(color) => dispatch(action__setPrimaryColor(color))}
              />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default CustomizePrimary;
