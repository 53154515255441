import { forwardRef, useImperativeHandle, useState } from 'react';
import { Checkbox, Modal, Space, Tabs, message } from 'antd';
import { useAppSelector } from 'src/hooks';
import { getScheduleSetting, putScheduleSetting } from '@/services';
import { selectUser } from '@/model/userSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import ChooseColor from './ChooseColor';
import { BookOutlined, HomeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

interface IProps {
    cb?: () => void
}
interface IHandle {
    open: () => void;
    cb?: () => void
}
const UISet = forwardRef<IHandle, IProps>(({ cb }, ref) => {
    const { studioInfo } = useAppSelector(selectUser);
    const [visible, setVisible] = useState(false)

    const [colors, setColors] = useState<string[]>([])
    const [fields, setFields] = useState<string[]>([])

    useQuery(['getScheduleSetting'], () => getScheduleSetting({ id: studioInfo?.id || '' }), {
        enabled: visible,
        onSuccess({ result }) {
            setColors(result.colors?.length ? result.colors : ['#87CEFA', '#00BFFF', '#1E90FF', '#4169E1', '#B0C4DE', '#f30000'])
            setFields(result.fields?.length ? result.fields : ['1', '3', '5'])
        }
    })
    const mutPut = useMutation(putScheduleSetting);

    const handleFinish = async () => {
        if (fields.length === 0) return message.error('请至少选择一个展示内容')
        const { result, msg } = await mutPut.mutateAsync({ id: studioInfo?.id || '', colors, fields })
        if (result) {
            cb?.()
            message.success('修改成功')
            setVisible(false)
        } else message.error(msg)
    }
    useImperativeHandle(ref, () => ({
        open: () => {
            setVisible(true)
        },
    }));
    const handleClick = (v: string) => {
        if (fields.includes(v)) setFields(fields.filter(_ => _ !== v))
        else v === '1' ? setFields([v, ...fields]) : setFields([...fields, v])
    }
    return (
        <Modal
            bodyStyle={{ height: 350 }}
            centered
            confirmLoading={mutPut.isLoading}
            destroyOnClose
            onCancel={() => setVisible(false)}
            onOk={handleFinish}
            open={visible}
            title='界面设置'
            width={800}
        >
            <Tabs
                className='h-full'
                items={[{
                    label: '状态颜色',
                    key: '1',
                    children: <div className='h-full pr-40'>
                        <div className='mb-4'>可以自定义每种课程状态在日历内的颜色显示</div>
                        {colors.map((_, idx) => <div className='flex justify-between  mb-4' key={idx}>

                            <div className='flex items-center'><span className='w-20'> {{ 0: '未上课', 1: '上课中', 2: '已下课', 3: '已消课', 4: '已取消', 5: '冲突课程' }[idx]}：</span>< ChooseColor onChange={(v) => {
                                const temp = [...colors]
                                temp[idx] = v
                                setColors(temp)
                            }} value={_} /></div>
                            <div className='flex items-center'>
                                效果： <div className="py-1 px-2 rounded-t flex items-center gap-1 text-white" style={{ backgroundColor: _ }}>
                                    <svg
                                        className="w-3 h-3"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <span>
                                        14:05&nbsp;至&nbsp;18:00
                                    </span>
                                </div>
                            </div>
                        </div>)}
                    </div>
                },
                {
                    label: '内容显示',
                    key: '2',
                    children: <div className='h-full'>
                        可以自定义课程在列表内显示的要素，至多四个，标题默认置顶，其余按照点击顺序排列。
                        <div className='flex justify-between mt-4'>
                            <Space direction="vertical">
                                <Checkbox checked={!!fields.includes('1')} disabled={fields.length === 4 && !fields.includes('1')} onChange={_ => handleClick(_.target.value)} value="1">课程标题</Checkbox>
                                <Checkbox checked={!!fields.includes('2')} disabled={fields.length === 4 && !fields.includes('2')} onChange={_ => handleClick(_.target.value)} value="2">课程名称</Checkbox>
                                <Checkbox checked={!!fields.includes('3')} disabled={fields.length === 4 && !fields.includes('3')} onChange={_ => handleClick(_.target.value)} value="3">课程老师</Checkbox>
                                <Checkbox checked={!!fields.includes('4')} disabled={fields.length === 4 && !fields.includes('4')} onChange={_ => handleClick(_.target.value)} value="4">预约成功人数</Checkbox>
                                <Checkbox checked={!!fields.includes('5')} disabled={fields.length === 4 && !fields.includes('5')} onChange={_ => handleClick(_.target.value)} value="5">教室</Checkbox>
                            </Space>
                            <div className='mr-20'>
                                效果样式:
                                <div
                                    className="text-sm bg-white border-solid border rounded"
                                    style={{ borderColor: '#87CEFA' }}>
                                    <div className="pl-1 pr-8 py-1 flex items-center text-white" style={{ backgroundColor: '#87CEFA' }}>
                                        <svg
                                            className="w-3 h-3"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={1.5}
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span>
                                            16:05&nbsp;至&nbsp;18:00
                                        </span>
                                    </div>
                                    <div className="p-1">
                                        {
                                            fields.includes('1') ? <div className="font-bold text-sm mb-1 overflow-hidden text-ellipsis whitespace-nowrap">课程标题</div> : null
                                        }
                                        {
                                            fields.filter(_ => _ !== '1').map(_ => <div className="flex items-center" key={_}>
                                                <span className="flex-none w-5">

                                                    {{
                                                        '2': <BookOutlined />,
                                                        '3': <UserOutlined />,
                                                        '4': <TeamOutlined />,
                                                        '5': <HomeOutlined />
                                                    }[_]}
                                                </span>
                                                <span className="flex-1 w-0 overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {{
                                                        '2': '课程名称',
                                                        '3': '课程老师',
                                                        '4': '预约成功人数',
                                                        '5': '教室'
                                                    }[_]}
                                                </span>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }]}
                tabPosition='left'
            />
        </Modal>
    );
})

export default UISet;
