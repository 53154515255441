export interface IRange {
  start: number;
  end: number;
}

export const week = ['一', '二', '三', '四', '五', '六', '日'];

export type IData = {
  id: string;
  start: number;
  end: number;
  title: string;
  color?: string; // 卡片banner的颜色
  conflict?: string; //课程是否冲突
  payload: unknown;
}[];
