import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import brandRoutes from '@/routes/brand';

const Bread = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbNameMap = useMemo(
    () =>
      brandRoutes.reduce((prev, next) => {
        if (next.path) prev[next.path] = next.name;
        return prev;
      }, {} as { [key: string]: string }),
    [brandRoutes],
  );

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const name = breadcrumbNameMap[url];
    if (name)
      return (
        <Breadcrumb.Item key={url}>
          <span className="!text-white">{name}</span>
        </Breadcrumb.Item>
      );
    return null;
  });

  return (
    <Breadcrumb separator={<span className="text-white">{`/`}</span>}>
      <Breadcrumb.Item key="home">
        <span className="text-white">
          <HomeFilled />
        </span>
      </Breadcrumb.Item>
      {extraBreadcrumbItems}
    </Breadcrumb>
  );
};

export default Bread;
