import { FieldTimeOutlined, FieldBinaryOutlined, StarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
interface IProps {
  type?: string;
}

const PkgType = ({ type }: IProps) => {
  if (type === '1')
    return (
      <Tag className="font-bold text-sm" color="#0f172a" icon={<FieldTimeOutlined />}>
        时间限制
      </Tag>
    );
  if (type === '2')
    return (
      <Tag className="font-bold text-sm" color="#0f172a" icon={<FieldBinaryOutlined />}>
        次数限制
      </Tag>
    );
  if (type === '3')
    return (
      <Tag className="font-bold text-sm" color="#0f172a" icon={<StarOutlined />}>
        积分
      </Tag>
    );
  return <>--</>;
};

export default PkgType;
