import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';
import { getStudioUnderBrand } from '@/services';

interface IProps {
  value: SelectProps['value'];
  onChange: SelectProps['onChange'];
  bordered?: boolean;
  className?: string;
}

const SelectStudio: FC<IProps> = ({ value, onChange, bordered, className }) => {
  const { brandInfo } = useAppSelector(selectUser);

  const { data: studioList, isFetching } = useQuery(['getStudioUnderBrand'], () =>
    getStudioUnderBrand({ id: brandInfo?.id || '' }),
  );

  return (
    <Select
      bordered={bordered}
      className={className}
      loading={isFetching}
      onChange={onChange}
      options={[{ label: '全部门店', value: '' }].concat(
        studioList?.result.map(({ name, id }) => ({ label: name, value: id })) || [],
      )}
      value={value}
    />
  );
};

export default SelectStudio;
