import { CaretRightOutlined, CrownFilled, LoadingOutlined } from '@ant-design/icons';
import { message, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';
import { putUserBrand } from '@/services';

const Info = () => {
  const { brandInfo, studioInfo } = useAppSelector(selectUser);

  const { mutateAsync, isLoading } = useMutation(putUserBrand);

  const handleClick = async () => {
    if (!isLoading) {
      const { success, msg } = await mutateAsync({ id: brandInfo?.id || '' });
      if (success) window.location.href = '/';
      else message.error(msg);
    }
  };

  return (
    <div className="p-4 flex flex-col items-start">
      <Space align="start">
        <img alt={studioInfo?.name} className="h-16 rounded" src={studioInfo?.signage} />

        <Space direction="vertical">
          <Space className="px-2 py-0.5 rounded text-white bg-slate-900">
            <CrownFilled />
            门店
          </Space>
          <Space
            className="whitespace-nowrap px-3 py-1 text-sm text-white rounded cursor-pointer transition-all duration-200 bg-slate-900 hover:bg-slate-700"
            onClick={handleClick}
          >
            前往品牌
            {isLoading ? <LoadingOutlined /> : <CaretRightOutlined />}
          </Space>
        </Space>
      </Space>

      <div className="w-full truncate mt-4 mb-2">{brandInfo?.name}</div>
      <div className="w-full truncate font-bold text-base">{studioInfo?.name}</div>
    </div>
  );
};

export default Info;
