import { BellOutlined, ExportOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Popover, Space } from 'antd';
import { useAppDispatch, useAppNavigate, useAppSelector } from '@/hooks';
import { action__logout, selectUser } from '@/model/userSlice';
import { routes } from '@/routes/user';

const NavMenu = () => {
  const dispatch = useAppDispatch();
  const { appNavigate } = useAppNavigate();
  const { userinfo } = useAppSelector(selectUser);

  return (
    <div>
      <Space size="middle">
        <Popover content={<div>敬请期待</div>} getPopupContainer={(_) => _} showArrow={false} trigger={['click']}>
          <div className="w-8 h-8 rounded-full cursor-pointer bg-gray-200 flex items-center justify-center">
            <BellOutlined />
          </div>
        </Popover>

        <Dropdown
          menu={{
            items: [
              ...routes.map(({ KEY, icon, name, path }) => ({
                key: KEY,
                icon,
                label: <a onClick={() => appNavigate(path)}>{name}</a>,
              })),
              {
                type: 'divider',
              },
              {
                disabled: true,
                key: '00',
                icon: <LockOutlined />,
                label: <a>修改密码</a>,
              },
              {
                key: '0',
                icon: <ExportOutlined />,
                label: <a onClick={() => dispatch(action__logout())}>退出登录</a>,
              },
            ],
          }}
          trigger={['click']}>
          <div>
            <Avatar className="cursor-pointer" icon={<UserOutlined />} src={userinfo?.avatar} />
          </div>
        </Dropdown>
      </Space>
    </div>
  );
};

export default NavMenu;
