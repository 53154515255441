import { useEffect, useState } from 'react';

import styles from './index.module.scss';

const COUNT = 0;
const MAX = 3;

interface IProps {
  dots?: boolean;
  content?: string;
}

const LoadingPage = ({ content = '加载中', dots = false }: IProps) => {
  const [count, setCount] = useState(COUNT);
  useEffect(() => {
    if (dots) {
      let timer: NodeJS.Timer;
      if (count <= MAX) {
        timer = setTimeout(() => setCount((prevState) => ++prevState), 500);
      } else setCount(COUNT);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [count, dots]);

  return (
    <div className={`w-screen h-screen flex flex-col gap-10 justify-center items-center ${styles.bg}`}>
      <div className="w-44 h-24">
        {Array(15)
          .fill(null)
          .map((_, idx) => (
            <span className={styles.span} key={idx} />
          ))}
      </div>
      <span className="font-bold bg-gradient-to-br from-slate-300 via-slate-900 to-slate-500 bg-clip-text text-transparent">
        {content}
        {''.padStart(count, '·')}
      </span>
    </div>
  );
};

export default LoadingPage;
