/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { CaretDownOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Cascader, Divider, Input, message, Modal, Select, Space } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getPDC, getSuggestion } from '@/services';

import PNGLocation from './location.png';

type TypeAddr = Awaited<ReturnType<typeof getSuggestion>>['result']['data'];

interface IProps {
  value?: any;
  onChange?: (val: any) => void;
}

const ChooseMap: React.FC<IProps> = ({ value, onChange }) => {
  const mapRef = useRef<any>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const [city, setCity] = useState(['北京市', '北京市']); // 默认值
  const [addrOpts, setAddrOpts] = useState<TypeAddr>([]);
  const [address, setAddress] = useState(undefined);
  const [detail, setDetail] = useState('');

  const { data } = useQuery(['getPDC'], () => getPDC({}));
  const { mutate } = useMutation(getSuggestion, {
    onSuccess: ({ success, result }) => {
      if (success) setAddrOpts(result?.data);
    },
  });

  const handleChangeCity = (value: any) => {
    setCity(value);
    setAddrOpts([]);
    setAddress(undefined);
  };

  const { run: handleSearch } = useDebounceFn(
    (value: string) => {
      if (value) mutate({ region: city[1] || city[0], keyword: value });
      else setAddrOpts([]);
    },
    { wait: 500, leading: true },
  );

  const handleOk = () => {
    if (!address) return message.warning('请选择地址');
    onChange &&
      onChange({
        ...addrOpts.find((_) => _.id === address),
        detail,
      });
    setVisible(false);
  };

  useEffect(() => {
    if (container) {
      mapRef.current = new TMap.Map(container, {
        center: new TMap.LatLng(39.908823, 116.39747), // 默认值：天安门
        zoom: 12,
        viewMode: '2D',
      });
    }
  }, [container]);

  useEffect(() => {
    if (addrOpts.length && address) {
      const location = addrOpts.find((_) => _.id === address)?.location;
      mapRef.current?.setCenter(new TMap.LatLng(location?.lat, location?.lng));

      new TMap.MultiMarker({
        map: mapRef.current,
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            src: PNGLocation,
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: '1',
            styleId: 'myStyle',
            position: new TMap.LatLng(location?.lat, location?.lng),
          },
        ],
      });
    }
  }, [addrOpts, address]);

  const options = useMemo(
    () =>
      data?.result.map((_) => ({
        value: _.districtName,
        label: _.districtName,
        children: _.sonList.map((__) => ({
          value: __.districtName,
          label: __.districtName,
        })),
      })),
    [data],
  );

  return (
    <div className="h-16 ring-1 ring-gray-300 rounded cursor-pointer">
      {value ? (
        <div className="h-full p-2 text-xs" onClick={() => setVisible(true)}>
          <p>{value.title}</p>
          <div className="truncate" title={value.address + ' ' + value.detail}>
            {value.address} {value.detail}
          </div>
        </div>
      ) : (
        <div className="h-full flex flex-col items-center justify-center" onClick={() => setVisible(true)}>
          <EnvironmentOutlined />
          <label>选择地址</label>
        </div>
      )}

      <Modal
        destroyOnClose
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        open={visible}
        title="选择地址"
        width={800}>
        <div className="flex items-center gap-4">
          <Cascader onChange={handleChangeCity} options={options} value={city}>
            <span>
              <Space className="pointer">
                {city[1] || city[0]}
                <CaretDownOutlined />
              </Space>
            </span>
          </Cascader>
          <Select
            className="flex-1"
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            onChange={(val) => setAddress(val)}
            onSearch={handleSearch}
            options={addrOpts.map((_: any) => ({
              label: (
                <span className="flex items-center gap-2">
                  {_.title}
                  <span className="text-xs text-gray-500">{_.address}</span>
                </span>
              ),
              value: _.id,
            }))}
            placeholder="请输入商圈名称或街道"
            showArrow={false}
            showSearch
            value={address}
          />
        </div>
        <Divider />
        <div className="h-96" ref={setContainer} />
        <Divider />
        <Input.TextArea
          allowClear
          autoSize={{ minRows: 5, maxRows: 5 }}
          className='mb-6'
          maxLength={200}
          onChange={(e) => setDetail(e.target.value)}
          placeholder="请输入详细地址，如：A座1901室"
          showCount
          value={detail}
        />
      </Modal>
    </div>
  );
};

export default ChooseMap;
