import use, { downloadInterceptor } from '@/services/saas.request';
import {
  ReqGetV1SaasBrandIdVipList,
  ResGetV1SaasBrandIdVipList,
  ReqPostV1SaasBrandBrandIdVipExport,
  ResPostV1SaasBrandBrandIdVipExport,
  ReqGetV1SaasBrandIdVipTemporaryList,
  ResGetV1SaasBrandIdVipTemporaryList,
  ReqPutV1SaasBrandBrandIdVipVipIdInfo,
  ResPutV1SaasBrandBrandIdVipVipIdInfo,
  ReqGetV1SaasBrandBrandIdVipVipIdInfo,
  ResGetV1SaasBrandBrandIdVipVipIdInfo,
  ReqGetV1SaasBrandBrandIdVipVipIdPkgList,
  ResGetV1SaasBrandBrandIdVipVipIdPkgList,
  ReqGetV1SaasBrandBrandIdVipVipIdAppointmentList,
  ResGetV1SaasBrandBrandIdVipVipIdAppointmentList,
  ReqGetV1SaasBrandBrandIdVipVipIdEliminateList,
  ResGetV1SaasBrandBrandIdVipVipIdEliminateList,
  ReqGetV1SaasBrandBrandIdOrderOrderIdPkgPkgIdInfo,
  ResGetV1SaasBrandBrandIdOrderOrderIdPkgPkgIdInfo,
  ReqPutV1SaasBrandBrandIdVipVipIdOrderOrderIdRefund,
  ResPutV1SaasBrandBrandIdVipVipIdOrderOrderIdRefund,
  ReqGetV1SaasBrandBrandIdVipVipIdTemporaryTimeList,
  ResGetV1SaasBrandBrandIdVipVipIdTemporaryTimeList,
  ReqGetV1SaasBrandBrandIdVipVipIdTemporaryDegreeList,
  ResGetV1SaasBrandBrandIdVipVipIdTemporaryDegreeList,
  ReqGetV1SaasBrandBrandIdVipVipIdExpirationList,
  ResGetV1SaasBrandBrandIdVipVipIdExpirationList,
  ReqGetV1SaasBrandBrandIdVipVipIdTrace,
  ResGetV1SaasBrandBrandIdVipVipIdTrace,
  ReqPostV1SaasBrandBrandIdVipVipIdTrace,
  ResPostV1SaasBrandBrandIdVipVipIdTrace,
  ReqGetV1SaasBrandIdVipLoginfoList,
  ResGetV1SaasBrandIdVipLoginfoList,
  ReqPutV1SaasBrandIdVipVipIdCurriculumCurriculumId,
  ResPutV1SaasBrandIdVipVipIdCurriculumCurriculumId,
  ReqGetV1SaasBrandIdVipVipIdCurriculumCurriculumIdList,
  ResGetV1SaasBrandIdVipVipIdCurriculumCurriculumIdList,
  ReqPostV1SaasBrandIdVipVipIdOrderOrderIdDelay,
  ResPostV1SaasBrandIdVipVipIdOrderOrderIdDelay,
  ReqGetV1SaasBrandBrandIdVipVipIdPointsList,
  ResGetV1SaasBrandBrandIdVipVipIdPointsList,
  ReqPostV1SaasBrandIdVipVipIdOrderOrderIdPointsAfter,
  ResPostV1SaasBrandIdVipVipIdOrderOrderIdPointsAfter,
  ReqGetV1SaasBrandIdVipIntegralIdRelieveFreezeLogs,
  ResGetV1SaasBrandIdVipIntegralIdRelieveFreezeLogs,
  ReqPutV1SaasBrandIdVipVipIdRelieveFreeze,
  ReqPutV1SaasBrandIdVipVipIdMinusPoints,
  ResPutV1SaasBrandIdVipVipIdMinusPoints,
  ReqPutV1SaasBrandIdVipVipIdDelayDays,
  ResPutV1SaasBrandIdVipVipIdDelayDays,
  ReqGetV1SaasBrandIdVipVipIdIntegralChangeList,
  ResGetV1SaasBrandIdVipVipIdIntegralChangeList,
  ReqGetV1SaasBrandIdVipVipIdUsedPkgList,
  ResGetV1SaasBrandIdVipVipIdUsedPkgList,
  ReqGetV1SaasBrandIdVipVipIdUsedIntegralList,
  ResGetV1SaasBrandIdVipVipIdUsedIntegralList,
  ReqGetV1SaasBrandIdScrips,
  ResGetV1SaasBrandIdScrips,
  ReqPostV1SaasBrandScrip,
  ResPostV1SaasBrandScrip,
  ReqPostV1SaasBrandScripLess,
  ResPostV1SaasBrandScripLess,
  ReqPutV1SaasBrandIdVipVipIdIntegralFreeze,
  ResPutV1SaasBrandIdVipVipIdIntegralFreeze,
  ReqGetV1SaasBrandBrandIdVipVipIdTemporaryIntegralList,
  ResGetV1SaasBrandBrandIdVipVipIdTemporaryIntegralList,
  ReqGetV1SaasBrandBrandIdVipVipIdExpiredIntegralList,
  ResGetV1SaasBrandBrandIdVipVipIdExpiredIntegralList,
} from '@/services/params';

export const getVipBrand = (p: ReqGetV1SaasBrandIdVipList) =>
  use<ReqGetV1SaasBrandIdVipList, ResGetV1SaasBrandIdVipList>('get', `/v1/saas/brand/${p.id}/vip/list`, p);

export const exportVipBrand = (p: ReqPostV1SaasBrandBrandIdVipExport, type: string) =>
  use<ReqPostV1SaasBrandBrandIdVipExport, ResPostV1SaasBrandBrandIdVipExport>(
    'post',
    `/v1/saas/brand/${p.brandId}/vip/export`,
    p,
    downloadInterceptor<ResPostV1SaasBrandBrandIdVipExport>(type),
  );

export const getVipWarnBrand = (p: ReqGetV1SaasBrandIdVipTemporaryList) =>
  use<ReqGetV1SaasBrandIdVipTemporaryList, ResGetV1SaasBrandIdVipTemporaryList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/temporary/list`,
    p,
  );

export const getVipTraceBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdTrace) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdTrace, ResGetV1SaasBrandBrandIdVipVipIdTrace>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/trace`,
    p,
  );

export const postVipTraceBrand = (p: ReqPostV1SaasBrandBrandIdVipVipIdTrace) =>
  use<ReqPostV1SaasBrandBrandIdVipVipIdTrace, ResPostV1SaasBrandBrandIdVipVipIdTrace>(
    'post',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/trace`,
    p,
  );

export const getVipInfoBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdInfo) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdInfo, ResGetV1SaasBrandBrandIdVipVipIdInfo>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/info`,
    p,
  );

export const putVipBrand = (p: ReqPutV1SaasBrandBrandIdVipVipIdInfo) =>
  use<ReqPutV1SaasBrandBrandIdVipVipIdInfo, ResPutV1SaasBrandBrandIdVipVipIdInfo>(
    'put',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/info`,
    p,
  );

export const getVipPkgBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdPkgList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdPkgList, ResGetV1SaasBrandBrandIdVipVipIdPkgList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/pkg/list`,
    p,
  );
export const freezePkgBrand = (p: ReqPutV1SaasBrandIdVipVipIdCurriculumCurriculumId) =>
  use<ReqPutV1SaasBrandIdVipVipIdCurriculumCurriculumId, ResPutV1SaasBrandIdVipVipIdCurriculumCurriculumId>(
    'put',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/curriculum/${p.curriculumId}`,
    p
  )
export const getVipAppointmentBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdAppointmentList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdAppointmentList, ResGetV1SaasBrandBrandIdVipVipIdAppointmentList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/appointment/list`,
    p,
  );
export const getVipUsedBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdEliminateList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdEliminateList, ResGetV1SaasBrandBrandIdVipVipIdEliminateList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/eliminate/list`,
    p,
  );

export const getVipIntegralListBrand = (p: ReqGetV1SaasBrandBrandIdVipVipIdPointsList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdPointsList, ResGetV1SaasBrandBrandIdVipVipIdPointsList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/points/list`,
    p
  )

export const getVipScripsList = (p: ReqGetV1SaasBrandIdScrips) =>
  use<ReqGetV1SaasBrandIdScrips, ResGetV1SaasBrandIdScrips>(
    'get',
    `/v1/saas/brand/${p.id}/scrips`,
    p
  )

export const addScrip = (p: ReqPostV1SaasBrandScrip) =>
  use<ReqPostV1SaasBrandScrip, ResPostV1SaasBrandScrip>(
    'post',
    `/v1/saas/brand/scrip`,
    p
  )
export const reduceScrip = (p: ReqPostV1SaasBrandScripLess) =>
  use<ReqPostV1SaasBrandScripLess, ResPostV1SaasBrandScripLess>(
    'post',
    `/v1/saas/brand/scrip/less`,
    p
  )
export const getVipOrderBrand = (p: ReqGetV1SaasBrandBrandIdOrderOrderIdPkgPkgIdInfo) =>
  use<ReqGetV1SaasBrandBrandIdOrderOrderIdPkgPkgIdInfo, ResGetV1SaasBrandBrandIdOrderOrderIdPkgPkgIdInfo>(
    'get',
    `/v1/saas/brand/${p.brandId}/order/${p.orderId}/pkg/${p.pkgId}/info`,
    p,
  );
export const freezePkgRecord = (p: ReqGetV1SaasBrandIdVipVipIdCurriculumCurriculumIdList) =>
  use<ReqGetV1SaasBrandIdVipVipIdCurriculumCurriculumIdList, ResGetV1SaasBrandIdVipVipIdCurriculumCurriculumIdList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/curriculum/${p.curriculumId}/list`,
    p
  )
export const freezeIntegralRecord = (p: ReqGetV1SaasBrandIdVipIntegralIdRelieveFreezeLogs) =>
  use<ReqGetV1SaasBrandIdVipIntegralIdRelieveFreezeLogs, ResGetV1SaasBrandIdVipIntegralIdRelieveFreezeLogs>(
    'get',
    `/v1/saas/brand/${p.id}/vip/${p.integralId}/relieve/freeze/logs`,
    p
  )

export const unFreezeIntegral = (p: ReqPutV1SaasBrandIdVipVipIdRelieveFreeze) =>
  use<ReqPutV1SaasBrandIdVipVipIdRelieveFreeze, ReqPutV1SaasBrandIdVipVipIdRelieveFreeze>(
    'put',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/relieve/freeze`,
    p
  )
export const reduceIntegral = (p: ReqPutV1SaasBrandIdVipVipIdMinusPoints) =>
  use<ReqPutV1SaasBrandIdVipVipIdMinusPoints, ResPutV1SaasBrandIdVipVipIdMinusPoints>(
    'put',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/minus/points`,
    p
  )

export const freezeIntegral = (p: ReqPutV1SaasBrandIdVipVipIdIntegralFreeze) =>
  use<ReqPutV1SaasBrandIdVipVipIdIntegralFreeze, ResPutV1SaasBrandIdVipVipIdIntegralFreeze>(
    'put',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/integral/freeze`,
    p
  )
export const delayIntegral = (p: ReqPutV1SaasBrandIdVipVipIdDelayDays) =>
  use<ReqPutV1SaasBrandIdVipVipIdDelayDays, ResPutV1SaasBrandIdVipVipIdDelayDays>(
    'put',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/delay/days`,
    p
  )
export const putVipRefund = (p: ReqPutV1SaasBrandBrandIdVipVipIdOrderOrderIdRefund) =>
  use<ReqPutV1SaasBrandBrandIdVipVipIdOrderOrderIdRefund, ResPutV1SaasBrandBrandIdVipVipIdOrderOrderIdRefund>(
    'put',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/order/${p.orderId}/refund`,
    p,
  );
export const putOrderAfter = (p: ReqPostV1SaasBrandIdVipVipIdOrderOrderIdDelay) =>
  use<ReqPostV1SaasBrandIdVipVipIdOrderOrderIdDelay, ResPostV1SaasBrandIdVipVipIdOrderOrderIdDelay>(
    'post',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/order/${p.orderId}/delay`,
    p
  )

export const putIntegralAfter = (p: ReqPostV1SaasBrandIdVipVipIdOrderOrderIdPointsAfter) =>
  use<ReqPostV1SaasBrandIdVipVipIdOrderOrderIdPointsAfter, ResPostV1SaasBrandIdVipVipIdOrderOrderIdPointsAfter>(
    'post',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/order/${p.orderId}/points/after`,
    p
  )
export const getTempoTime = (p: ReqGetV1SaasBrandBrandIdVipVipIdTemporaryTimeList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdTemporaryTimeList, ResGetV1SaasBrandBrandIdVipVipIdTemporaryTimeList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/temporary/time/list`,
    p,
  );
export const getTempoIntegral = (p: ReqGetV1SaasBrandBrandIdVipVipIdTemporaryIntegralList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdTemporaryIntegralList, ResGetV1SaasBrandBrandIdVipVipIdTemporaryIntegralList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/temporary/integral/list`,
    p
  )
export const getTempoDegree = (p: ReqGetV1SaasBrandBrandIdVipVipIdTemporaryDegreeList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdTemporaryDegreeList, ResGetV1SaasBrandBrandIdVipVipIdTemporaryDegreeList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/temporary/degree/list`,
    p,
  );

export const getExpiration = (p: ReqGetV1SaasBrandBrandIdVipVipIdExpirationList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdExpirationList, ResGetV1SaasBrandBrandIdVipVipIdExpirationList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/expiration/list`,
    p,
  );

export const getExpirationIntegral = (p: ReqGetV1SaasBrandBrandIdVipVipIdExpiredIntegralList) =>
  use<ReqGetV1SaasBrandBrandIdVipVipIdExpiredIntegralList, ResGetV1SaasBrandBrandIdVipVipIdExpiredIntegralList>(
    'get',
    `/v1/saas/brand/${p.brandId}/vip/${p.vipId}/expired/integral/list`,
    p
  )
export const vipLoginfoBrand = (p: ReqGetV1SaasBrandIdVipLoginfoList) =>
  use<ReqGetV1SaasBrandIdVipLoginfoList, ResGetV1SaasBrandIdVipLoginfoList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/loginfo/list`,
    p,
  );
export const getVipIntegralChangeListBrand = (p: ReqGetV1SaasBrandIdVipVipIdIntegralChangeList) =>
  use<ReqGetV1SaasBrandIdVipVipIdIntegralChangeList, ResGetV1SaasBrandIdVipVipIdIntegralChangeList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/integralChange/list`,
    p
  )

export const useingPkgBrand = (p: ReqGetV1SaasBrandIdVipVipIdUsedPkgList) =>
  use<ReqGetV1SaasBrandIdVipVipIdUsedPkgList, ResGetV1SaasBrandIdVipVipIdUsedPkgList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/used/pkg/list`
  )
export const useingIntegralBrand = (p: ReqGetV1SaasBrandIdVipVipIdUsedIntegralList) =>
  use<ReqGetV1SaasBrandIdVipVipIdUsedIntegralList, ResGetV1SaasBrandIdVipVipIdUsedIntegralList>(
    'get',
    `/v1/saas/brand/${p.id}/vip/${p.vipId}/used/integral/list`
  )