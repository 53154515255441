/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { LoadingPage } from '@/components';
import { VersionNotice } from '@/features';
import { action__setBrandInfo, action__setStudioInfo, selectUser } from '@/model/userSlice';
import { getBrand, getStudio } from '@/services';
import brandRoutes from '@/routes/brand';

import Layout from './layout';

const StudioLayout = () => {

  const dispatch = useAppDispatch();
  const { userinfo } = useAppSelector(selectUser);
  const filterRoutes = (userinfo?.permissions as any)?.['*'] ? brandRoutes : brandRoutes.filter((_) => (userinfo?.permissions as any)?.[_.KEY]);


  const { data, isLoading, isError } = useQuery(
    ['getStudio'],
    async () => {
      const { success, result } = await getStudio({ id: userinfo?.defaultInfo?.id || '' });
      if (success) {
        dispatch(action__setStudioInfo(result));
        return result;
      } else return Promise.reject(result);
    },
    {
      enabled: userinfo?.defaultInfo?.type === '2',
    },
  );
  const queryBrand = useQuery(
    ['getBrand'],
    async () => {
      const { success, result } = await getBrand({ id: data?.brand.id || '' });
      if (success) return dispatch(action__setBrandInfo(result));
      else return Promise.reject(result);
    },
    {
      enabled: !!data?.brand.id,
    },
  );

  // 如果该账号默认目标不存在，说明一定没有创建品牌
  if (!userinfo?.defaultInfo)
    return (
      <Navigate
        replace
        state={{
          default: true,
        }}
        to="/user/setting"
      />
    );

  // 如果该账号目标类型为 1:品牌，则跳转品牌
  if (userinfo?.defaultInfo.type === '1') return <Navigate replace to={filterRoutes.length ? filterRoutes[0].path : '/brand/dashboard'} />;
  if (isLoading || queryBrand.isLoading) return <LoadingPage content="获取门店信息" dots />;
  if (isError || queryBrand.isError) throw new Error('获取门店信息错误');

  return (
    <Layout>
      <Outlet />
      <VersionNotice />
    </Layout>
  );
};

export default memo(StudioLayout);
