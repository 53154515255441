import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { persistor, store } from '@/store';
import { LoadingPage, ErrorPage } from '@/components';
import App from '@/App';

import 'nprogress/nprogress.css';
import 'antd/dist/reset.css';
import '@/index.scss';

// 只有在master分支build才会开启sentry
// REACT_APP_CI_PIPELINE_IID和REACT_APP_CI_COMMIT_BRANCH在gitlab-ci流水线中注入
process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_CI_COMMIT_BRANCH === 'master' &&
  Sentry.init({
    dsn: 'https://0445b14a02e34a68a4f0d544c042ad07@o623796.ingest.sentry.io/4504122498088960',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        tracingOrigins: [/^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    release: `cyber-saas-odin@${process.env.REACT_APP_CI_PIPELINE_IID}`,
    environment: process.env.REACT_APP_CI_COMMIT_BRANCH,
  });
const AppWithProfiler = Sentry.withProfiler(App);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0, // 不缓存
      retry: 1, // 失败后重试1次
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <StrictMode>
  <ReduxProvider store={store}>
    <PersistGate loading={<LoadingPage content="读取本地数据" dots />} persistor={persistor}>
      <Suspense fallback={<LoadingPage content="加载页面" dots />}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {/* <ReactQueryDevtools position="bottom-right" /> */}
              <BrowserRouter>
                <AppWithProfiler />
              </BrowserRouter>
            </QueryClientProvider>
          </HelmetProvider>
        </Sentry.ErrorBoundary>
      </Suspense>
    </PersistGate>
  </ReduxProvider>,
  // </StrictMode>,
);
