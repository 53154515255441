import use from '@/services/saas.request';

import {
  ReqDeleteV1SaasBrandIdCoursesCourseId,
  ReqDeleteV1SaasBrandIdIntegralIntegralId,
  ReqDeleteV1SaasBrandIdPkgPkgId,
  ReqGetV1SaasBrandBrandIdLowSellPrice,
  ReqGetV1SaasBrandIdCourseCourseIdTagListAll,
  ReqGetV1SaasBrandIdCourseLoginfoList,
  ReqGetV1SaasBrandIdCoursesCourseIdInfo,
  ReqGetV1SaasBrandIdCoursesCourseIdStudios,
  ReqGetV1SaasBrandIdCoursesList,
  ReqGetV1SaasBrandIdCoursesListAll,
  ReqGetV1SaasBrandIdCourseTagList,
  ReqGetV1SaasBrandIdIntegralIntegralIdInfo,
  ReqGetV1SaasBrandIdIntegralListAll,
  ReqGetV1SaasBrandIdPkgList,
  ReqGetV1SaasBrandIdPkgLoginfoList,
  ReqGetV1SaasBrandIdPkgPkgIdInfo,
  ReqGetV1SaasBrandIdPkgPkgIdStatusList,
  ReqGetV1SaasBrandIdPkgPkgIdStudios,
  ReqGetV1SaasBrandIdTagLoginfoList,
  ReqGetV1SaasBrandIdTagTagIdCheck,
  ReqGetV1SaasBrandIdTagTagIdCoursesList,
  ReqPatchV1SaasBrandIdIntegralFromIdToId,
  ReqPostV1SaasBrandIdCourses,
  ReqPostV1SaasBrandIdCourseTag,
  ReqPostV1SaasBrandIdIntegral,
  ReqPostV1SaasBrandIdIntegralIntegralIdStatus,
  ReqPostV1SaasBrandIdPkg,
  ReqPutV1SaasBrandBrandIdLowSellPrice,
  ReqPutV1SaasBrandIdCourseCourseIdUpgrade,
  ReqPutV1SaasBrandIdCoursesCourseId,
  ReqPutV1SaasBrandIdCourseTagTagId,
  ReqPutV1SaasBrandIdIntegral,
  ReqPutV1SaasBrandIdPkgPkgId,
  ReqPutV1SaasBrandIdPkgPkgIdStatus,
  ReqPutV1SaasBrandIdPkgPkgIdUpgrade,
  ResDeleteV1SaasBrandIdCoursesCourseId,
  ResDeleteV1SaasBrandIdIntegralIntegralId,
  ResDeleteV1SaasBrandIdPkgPkgId,
  ResGetV1SaasBrandBrandIdLowSellPrice,
  ResGetV1SaasBrandIdCourseCourseIdTagListAll,
  ResGetV1SaasBrandIdCourseLoginfoList,
  ResGetV1SaasBrandIdCoursesCourseIdInfo,
  ResGetV1SaasBrandIdCoursesCourseIdStudios,
  ResGetV1SaasBrandIdCoursesList,
  ResGetV1SaasBrandIdCoursesListAll,
  ResGetV1SaasBrandIdCourseTagList,
  ResGetV1SaasBrandIdIntegralIntegralIdInfo,
  ResGetV1SaasBrandIdIntegralListAll,
  ResGetV1SaasBrandIdPkgList,
  ResGetV1SaasBrandIdPkgLoginfoList,
  ResGetV1SaasBrandIdPkgPkgIdInfo,
  ResGetV1SaasBrandIdPkgPkgIdStatusList,
  ResGetV1SaasBrandIdPkgPkgIdStudios,
  ResGetV1SaasBrandIdTagLoginfoList,
  ResGetV1SaasBrandIdTagTagIdCheck,
  ResGetV1SaasBrandIdTagTagIdCoursesList,
  ResPatchV1SaasBrandIdIntegralFromIdToId,
  ResPostV1SaasBrandIdCourses,
  ResPostV1SaasBrandIdCourseTag,
  ResPostV1SaasBrandIdIntegral,
  ResPostV1SaasBrandIdIntegralIntegralIdStatus,
  ResPostV1SaasBrandIdPkg,
  ResPutV1SaasBrandIdCourseCourseIdUpgrade,
  ResPutV1SaasBrandIdCoursesCourseId,
  ResPutV1SaasBrandIdCourseTagTagId,
  ResPutV1SaasBrandIdIntegral,
  ResPutV1SaasBrandIdPkgPkgId,
  ResPutV1SaasBrandIdPkgPkgIdStatus,
  ResPutV1SaasBrandIdPkgPkgIdUpgrade,
} from './params';

export const getPkgList = (p: ReqGetV1SaasBrandIdPkgList) =>
  use<ReqGetV1SaasBrandIdPkgList, ResGetV1SaasBrandIdPkgList>('get', `/v1/saas/brand/${p.id}/pkg/list`, p);

export const deletePkg = (p: ReqDeleteV1SaasBrandIdPkgPkgId) =>
  use<ReqDeleteV1SaasBrandIdPkgPkgId, ResDeleteV1SaasBrandIdPkgPkgId>(
    'delete',
    `/v1/saas/brand/${p.id}/pkg/${p.pkgId}`,
    p,
  );

export const putPkgStatus = (p: ReqPutV1SaasBrandIdPkgPkgIdStatus) =>
  use<ReqPutV1SaasBrandIdPkgPkgIdStatus, ResPutV1SaasBrandIdPkgPkgIdStatus>(
    'put',
    `/v1/saas/brand/${p.id}/pkg/${p.pkgId}/status`,
    p,
  );

export const getPkgStudio = (p: ReqGetV1SaasBrandIdPkgPkgIdStudios) =>
  use<ReqGetV1SaasBrandIdPkgPkgIdStudios, ResGetV1SaasBrandIdPkgPkgIdStudios>(
    'get',
    `/v1/saas/brand/${p.id}/pkg/${p.pkgId}/studios`,
    p,
  );

export const getCourseAllBrand = (p: ReqGetV1SaasBrandIdCoursesListAll) =>
  use<ReqGetV1SaasBrandIdCoursesListAll, ResGetV1SaasBrandIdCoursesListAll>(
    'get',
    `/v1/saas/brand/${p.id}/courses/list_all`,
    p,
  );
export const getTagCourseAllBrand = (p: ReqGetV1SaasBrandIdTagTagIdCoursesList) =>
  use<ReqGetV1SaasBrandIdTagTagIdCoursesList, ResGetV1SaasBrandIdTagTagIdCoursesList>(
    'get',
    `/v1/saas/brand/${p.id}/tag/${p.tagId}/coursesList`,
    p
  )


export const pkgLogInfoBrand = (p: ReqGetV1SaasBrandIdPkgLoginfoList) =>
  use<ReqGetV1SaasBrandIdPkgLoginfoList, ResGetV1SaasBrandIdPkgLoginfoList>(
    'get',
    `/v1/saas/brand/${p.id}/pkg/loginfo/list`,
    p,
  );

export const postPkgBrand = (p: ReqPostV1SaasBrandIdPkg) =>
  use<ReqPostV1SaasBrandIdPkg, ResPostV1SaasBrandIdPkg>('post', `/v1/saas/brand/${p.id}/pkg`, p);

export const upgradePkg = (p: ReqPutV1SaasBrandIdPkgPkgIdUpgrade) =>
  use<ReqPutV1SaasBrandIdPkgPkgIdUpgrade, ResPutV1SaasBrandIdPkgPkgIdUpgrade>(
    'put',
    `/v1/saas/brand/${p.id}/pkg/${p.pkg_id}/upgrade`,
    p,
  );

export const getCourseTagsBrand = (p: ReqGetV1SaasBrandIdCourseCourseIdTagListAll) =>
  use<ReqGetV1SaasBrandIdCourseCourseIdTagListAll, ResGetV1SaasBrandIdCourseCourseIdTagListAll>(
    'get',
    `/v1/saas/brand/${p.id}/course/${p.course_id}/tag/list_all`,
    p,
  );

export const getPkgDetailBrand = (p: ReqGetV1SaasBrandIdPkgPkgIdInfo) =>
  use<ReqGetV1SaasBrandIdPkgPkgIdInfo, ResGetV1SaasBrandIdPkgPkgIdInfo>(
    'get',
    `/v1/saas/brand/${p.id}/pkg/${p.pkgId}/info`,
    p,
  );

export const getPkgStatusRecordBrand = (p: ReqGetV1SaasBrandIdPkgPkgIdStatusList) =>
  use<ReqGetV1SaasBrandIdPkgPkgIdStatusList, ResGetV1SaasBrandIdPkgPkgIdStatusList>(
    'get',
    `/v1/saas/brand/${p.id}/pkg/${p.pkgId}/status/list`,
    p,
  );
export const getIntegralListAllBrand = (p: ReqGetV1SaasBrandIdIntegralListAll) =>
  use<ReqGetV1SaasBrandIdIntegralListAll, ResGetV1SaasBrandIdIntegralListAll>(
    'get',
    `/v1/saas/brand/${p.id}/integral/list/all`,
    p
  )
export const postIntegralBrand = (p: ReqPostV1SaasBrandIdIntegral) =>
  use<ReqPostV1SaasBrandIdIntegral, ResPostV1SaasBrandIdIntegral>(
    'post',
    `/v1/saas/brand/${p.id}/integral`,
    p
  )
export const getIntegralInfoBrand = (p: ReqGetV1SaasBrandIdIntegralIntegralIdInfo) =>
  use<ReqGetV1SaasBrandIdIntegralIntegralIdInfo, ResGetV1SaasBrandIdIntegralIntegralIdInfo>(
    'get',
    `/v1/saas/brand/${p.id}/integral/${p.integralId}/info`,
    p
  )
export const changeIntegralWeightBrand = (p: ReqPatchV1SaasBrandIdIntegralFromIdToId) =>
  use<ReqPatchV1SaasBrandIdIntegralFromIdToId, ResPatchV1SaasBrandIdIntegralFromIdToId>(
    'patch',
    `/v1/saas/brand/${p.id}/integral/${p.fromId}/${p.toId}`,
    p
  )
export const putIntegralBrand = (p: ReqPutV1SaasBrandIdIntegral) =>
  use<ReqPutV1SaasBrandIdIntegral, ResPutV1SaasBrandIdIntegral>(
    'put',
    `/v1/saas/brand/${p.id}/integral`,
    p
  )
export const deleteIntegralBrand = (p: ReqDeleteV1SaasBrandIdIntegralIntegralId) =>
  use<ReqDeleteV1SaasBrandIdIntegralIntegralId, ResDeleteV1SaasBrandIdIntegralIntegralId>(
    'delete',
    `/v1/saas/brand/${p.id}/integral/${p.integralId}`,
    p
  )
export const putIntegralStatusBrand = (p: ReqPostV1SaasBrandIdIntegralIntegralIdStatus) =>
  use<ReqPostV1SaasBrandIdIntegralIntegralIdStatus, ResPostV1SaasBrandIdIntegralIntegralIdStatus>(
    'post',
    `/v1/saas/brand/${p.id}/integral/${p.integralId}/${p.status}`,
    p
  )

export const brandLowPrice = (p: ReqPutV1SaasBrandBrandIdLowSellPrice) =>
  use<ReqPutV1SaasBrandBrandIdLowSellPrice, ReqPutV1SaasBrandBrandIdLowSellPrice>(
    'put',
    `/v1/saas/brand/${p.brandId}/low/sell_price`,
    p
  )

export const getBrandLowPrice = (p: ReqGetV1SaasBrandBrandIdLowSellPrice) =>
  use<ReqGetV1SaasBrandBrandIdLowSellPrice, ResGetV1SaasBrandBrandIdLowSellPrice>(
    'get',
    `/v1/saas/brand/${p.brandId}/low/sell_price`,
    p
  )
export const editPkgBrand = (p: ReqPutV1SaasBrandIdPkgPkgId) =>
  use<ReqPutV1SaasBrandIdPkgPkgId, ResPutV1SaasBrandIdPkgPkgId>('put', `/v1/saas/brand/${p.id}/pkg/${p.pkgId}`, p);

export const getCourseListBrand = (p: ReqGetV1SaasBrandIdCoursesList) =>
  use<ReqGetV1SaasBrandIdCoursesList, ResGetV1SaasBrandIdCoursesList>(
    'get',
    `/v1/saas/brand/${p.id}/courses/list`,
    p
  )

export const getCourseStudio = (p: ReqGetV1SaasBrandIdCoursesCourseIdStudios) =>
  use<ReqGetV1SaasBrandIdCoursesCourseIdStudios, ResGetV1SaasBrandIdCoursesCourseIdStudios>(
    'get',
    `/v1/saas/brand/${p.id}/courses/${p.courseId}/studios`,
    p
  )

export const upgradeCourse = (p: ReqPutV1SaasBrandIdCourseCourseIdUpgrade) =>
  use<ReqPutV1SaasBrandIdCourseCourseIdUpgrade, ResPutV1SaasBrandIdCourseCourseIdUpgrade>(
    'put',
    `/v1/saas/brand/${p.id}/course/${p.course_id}/upgrade`,
    p
  )

export const deleteCourseBrand = (p: ReqDeleteV1SaasBrandIdCoursesCourseId) =>
  use<ReqDeleteV1SaasBrandIdCoursesCourseId, ResDeleteV1SaasBrandIdCoursesCourseId>(
    'delete',
    `/v1/saas/brand/${p.id}/courses/${p.courseId}`,
    p
  )

export const courseLoginfoBrand = (p: ReqGetV1SaasBrandIdCourseLoginfoList) =>
  use<ReqGetV1SaasBrandIdCourseLoginfoList, ResGetV1SaasBrandIdCourseLoginfoList>(
    'get',
    `/v1/saas/brand/${p.id}/course/loginfo/list`,
    p
  )

export const createCourseBrand = (p: ReqPostV1SaasBrandIdCourses) =>
  use<ReqPostV1SaasBrandIdCourses, ResPostV1SaasBrandIdCourses>(
    'post',
    `/v1/saas/brand/${p.id}/courses`,
    p
  )

export const getTagListBrand = (p: ReqGetV1SaasBrandIdCourseTagList) =>
  use<ReqGetV1SaasBrandIdCourseTagList, ResGetV1SaasBrandIdCourseTagList>(
    'get',
    `/v1/saas/brand/${p.id}/course_tag/list`,
    p
  )
export const checkTagUse = (p: ReqGetV1SaasBrandIdTagTagIdCheck) =>
  use<ReqGetV1SaasBrandIdTagTagIdCheck, ResGetV1SaasBrandIdTagTagIdCheck>(
    'get',
    `/v1/saas/brand/${p.id}/tag/${p.tagId}/check`,
    p
  )
export const createTagBrand = (p: ReqPostV1SaasBrandIdCourseTag) =>
  use<ReqPostV1SaasBrandIdCourseTag, ResPostV1SaasBrandIdCourseTag>(
    'post',
    `/v1/saas/brand/${p.id}/course_tag`,
    p
  )

export const editTagBrand = (p: ReqPutV1SaasBrandIdCourseTagTagId) =>
  use<ReqPutV1SaasBrandIdCourseTagTagId, ResPutV1SaasBrandIdCourseTagTagId>(
    'put',
    `/v1/saas/brand/${p.id}/course_tag/${p.tagId}`,
    p
  )

export const getCourseDetailBrand = (p: ReqGetV1SaasBrandIdCoursesCourseIdInfo) =>
  use<ReqGetV1SaasBrandIdCoursesCourseIdInfo, ResGetV1SaasBrandIdCoursesCourseIdInfo>(
    'get',
    `/v1/saas/brand/${p.id}/courses/${p.courseId}/info`,
    p
  )


export const editCourseBrand = (p: ReqPutV1SaasBrandIdCoursesCourseId) =>
  use<ReqPutV1SaasBrandIdCoursesCourseId, ResPutV1SaasBrandIdCoursesCourseId>(
    'put',
    `/v1/saas/brand/${p.id}/courses/${p.courseId}`,
    p
  )

export const tagLoginfoBrand = (p: ReqGetV1SaasBrandIdTagLoginfoList) =>
  use<ReqGetV1SaasBrandIdTagLoginfoList, ResGetV1SaasBrandIdTagLoginfoList>(
    'get',
    `/v1/saas/brand/${p.id}/tag/loginfo/list`,
    p
  )
