import { NavLink, Outlet } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { NavMenu } from '@/features';
import { useAppNavigate } from '@/hooks';
import { routes } from '@/routes/user';
import PNGLogo from '@/assets/logo-font-dark.png';

const UserLayout = () => {
  const { appNavigate } = useAppNavigate();

  return (
    <>
      <nav className="sticky top-0 z-10 h-16 px-8 flex items-center justify-between bg-white shadow">
        <img alt="智械舞客" className="h-8" src={PNGLogo} />
        <NavMenu />
      </nav>

      <section className="py-12 px-12 flex gap-12">
          <aside className="w-48 h-80 flex flex-col gap-4 sticky top-28">
            <Space
              className="text-white text-lg mb-2 px-4 py-2 bg-primary rounded cursor-pointer hover:!bg-white hover:text-black hover:ring-1 ring-gray-100 transition-all duration-200"
              onClick={() => window.location.assign(window.location.origin)}
              size="large">
              <ArrowLeftOutlined />
              前往管理
            </Space>

            {routes.map(({ KEY, icon, name, path }) => {
              return (
                <NavLink
                  className={({ isActive }) =>
                    `ml-5 px-4 py-2 flex gap-2 no-underline items-center rounded cursor-pointer transition-all duration-200 text-black/50 hover:text-black/50 hover:bg-gray-300 ${isActive ? 'bg-gray-300' : 'bg-gray-100'
                    }`
                  }
                  key={KEY}
                  onClick={(e) => {
                    e.preventDefault();
                    appNavigate(path);
                  }}
                  to={path}>
                  {icon}
                  {name}
                </NavLink>
              );
            })}
          </aside>

          <main className="flex-1 overflow-x-hidden">
            <Outlet />
          </main>
      </section>
    </>
  );
};

export default UserLayout;
