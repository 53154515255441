import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectBasic, action__setVersion } from '@/model/basicSlice';

import PNGVersionBg from './version-bg.jpg';
import PACKAGEJSON from '@/../package.json';

const version = PACKAGEJSON.version;

const VersionNotice = () => {
  const dispatch = useAppDispatch();
  const { version: oldVersion } = useAppSelector(selectBasic);

  const onClose = () => {
    dispatch(action__setVersion(version));
  };

  return (
    <Modal
      centered
      closable={false}
      destroyOnClose
      footer={false}
      mask={false}
      open={oldVersion !== version}
      width={960}>
      <div
        className="-m-6 h-96 rounded-lg shadow-lg shadow-gray-500 outline outline-1 outline-gray-500 overflow-hidden"
        style={{ backgroundImage: `url(${PNGVersionBg})` }}>
        <div className="p-6 w-full h-full bg-black/75 flex flex-col justify-between">
          <div>
            <h2 className="text-white">版本：{version}</h2>
            {/* <h3 className="text-white">版本详情：</h3> */}
            <ul className="text-white">
              <h3>常规功能：</h3>
              <li>复制排课加强，可最多复制连续7天的课程</li>
              <li>排课展示调整，可自定义各种状态课程的颜色与预览内容</li>
              <li>积分优先功能，(门店设置后)当排课同时支持现金积分、赠送积分时，客户端优先使用现金积分约课</li>
              <li>排课逻辑调整</li>
              <h3>增值功能(限免)：</h3>
              <li>新增上课短信：门店可根据需求向”教学”角色开启开课短信提醒</li>
            </ul>
          </div>

          <Button className="self-end" onClick={onClose} type="primary">
            关闭
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VersionNotice;
