import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { TabletOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Input, message } from 'antd';
import { RE__MOBILE } from '@cyberdancer/regexp';
import { useAppDispatch } from '@/hooks';
import { action__login } from '@/model/userSlice';
import { getVerification, loginVerification } from '@/services';

type Values = Parameters<typeof loginVerification>[0];

const FormCaptcha = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const [sec, setSec] = useState(0);
  const [form] = Form.useForm();

  const mutVerification = useMutation(getVerification);
  const mutLogin = useMutation(loginVerification);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (sec > 0) timer = setTimeout(() => setSec((prevState) => --prevState), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [sec]);

  const handleSend = async () => {
    const { phone } = await form.validateFields(['phone']);
    const { success, msg } = await mutVerification.mutateAsync({ phone, type: '1' });
    if (success) {
      message.success('短信验证码已发送', 2);
      setSec(60)
    }
    else message.error(msg);
  };

  const handleSubmit = async (v: Values) => {
    const { success, result, msg } = await mutLogin.mutateAsync(v);
    if (success) {
      dispatch(action__login(result.accessToken));
      message.success('登录成功', 2, () => navigate(searchParams.get('from') || '/', { replace: true }));
    } else message.error(msg);
  };

  return (
    <Form<Values> form={form} onFinish={handleSubmit}>
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: '手机号不能为空',
          },
          {
            pattern: RE__MOBILE,
            message: '手机号格式错误',
          },
        ]}
      >
        <Input allowClear placeholder="手机号" prefix={<TabletOutlined />} size="large" />
      </Form.Item>
      <Row gutter={8}>
        <Col span={14}>
          <Form.Item
            name="verification"
            rules={[
              {
                required: true,
                message: '验证码不能为空',
              },
            ]}
          >
            <Input allowClear placeholder="短信验证码" prefix={<MessageOutlined />} size="large" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item>
            <Button block disabled={sec > 0} loading={mutVerification.isLoading} onClick={handleSend} size="large">
              {sec > 0 ? `重试(${sec})秒` : '获取验证码'}
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button block htmlType="submit" loading={mutLogin.isLoading} size="large" type="primary">
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormCaptcha;
