import { CrownFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/model/userSlice';

import Choose from './choose';

const Info = () => {
  const { brandInfo } = useAppSelector(selectUser);

  return (
    <div className="p-4 flex flex-col items-start gap-4">
      <Space>
        <img alt={brandInfo?.name} className="h-16 w-16 rounded" src={brandInfo?.logoUrl} />

        <Space direction="vertical">
          <Space>
            <Space className="px-3 py-0.5 rounded text-white bg-slate-900">
              <CrownFilled />
              品牌
            </Space>
            {!brandInfo?.status && <b className="text-xs">未激活</b>}
          </Space>
          <Choose />
        </Space>
      </Space>
      <div className="w-full truncate font-bold text-base">{brandInfo?.name}</div>
    </div>
  );
};

export default Info;
