import { Button } from 'antd';
import PNG404 from '@/assets/404.png';

interface IProps {
  notice?: boolean;
  redirect?: boolean;
}

const NotFound = ({ redirect = false, notice = true }: IProps) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img alt="cyberdancer" className="w-80" src={PNG404} />

      {notice ? <h3 className="font-bold text-lg">该页面不存在，或您无权限访问该页面</h3> : null}

      {redirect ? (
        <Button onClick={() => (window.location.href = '/')} type="primary">
          回到首页
        </Button>
      ) : null}
    </div>
  );
};

export default NotFound;
