import type { PropsWithChildren } from 'react';
import { Tag } from 'antd';

const Plate = ({ children }: PropsWithChildren) => {
  return (
    <Tag className="italic font-bold text-sm" color="#0f172a">
      {children}
    </Tag>
  );
};

export default Plate;
