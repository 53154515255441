import use, { downloadInterceptor } from '@/services/saas.request';
import {
  ReqGetV1SaasStudioIdOrderList,
  ResGetV1SaasStudioIdOrderList,
  ReqGetV1SaasStudioIdAfterList,
  ResGetV1SaasStudioIdAfterList,
  ReqGetV1SaasStudioIdAfterAfterIdInfo,
  ResGetV1SaasStudioIdAfterAfterIdInfo,
  ReqPostV1SaasStudioStudioIdOrderExport,
  ResPostV1SaasStudioStudioIdOrderExport,
  ReqPostV1SaasStudioStudioIdAfterListExport,
  ResPostV1SaasStudioStudioIdAfterListExport,
  ReqGetV1SaasStudioStudioIdCourseList,
  ResGetV1SaasStudioStudioIdCourseList,
} from '@/services/params';

export const getOrderStudio = (p: ReqGetV1SaasStudioIdOrderList) =>
  use<ReqGetV1SaasStudioIdOrderList, ResGetV1SaasStudioIdOrderList>('get', `/v1/saas/studio/${p.id}/order/list`, p);
export const getPointStudio = (p: ReqGetV1SaasStudioStudioIdCourseList) =>
  use<ReqGetV1SaasStudioStudioIdCourseList, ResGetV1SaasStudioStudioIdCourseList>('get', `/v1/saas/studio/${p.studioId}/course/list`, p);
export const getAfterStudio = (p: ReqGetV1SaasStudioIdAfterList) =>
  use<ReqGetV1SaasStudioIdAfterList, ResGetV1SaasStudioIdAfterList>('get', `/v1/saas/studio/${p.id}/after/list`, p);

export const getAfterDetailStudio = (p: ReqGetV1SaasStudioIdAfterAfterIdInfo) =>
  use<ReqGetV1SaasStudioIdAfterAfterIdInfo, ResGetV1SaasStudioIdAfterAfterIdInfo>(
    'get',
    `/v1/saas/studio/${p.id}/after/${p.afterId}/info`,
    p,
  );

export const exportOrderStudio = (p: ReqPostV1SaasStudioStudioIdOrderExport, type: string) =>
  use<ReqPostV1SaasStudioStudioIdOrderExport, ResPostV1SaasStudioStudioIdOrderExport>(
    'post',
    `/v1/saas/studio/${p.studioId}/after/list/export`,
    p,
    downloadInterceptor<ResPostV1SaasStudioStudioIdOrderExport>(type),
  );

export const exportAfterStudio = (p: ReqPostV1SaasStudioStudioIdAfterListExport, type: string) =>
  use<ReqPostV1SaasStudioStudioIdAfterListExport, ResPostV1SaasStudioStudioIdAfterListExport>(
    'post',
    `/v1/saas/studio/${p.studioId}/after/list/export`,
    p,
    downloadInterceptor<ResPostV1SaasStudioStudioIdAfterListExport>(type),
  );
