import type { CSSProperties, FC, ChangeEvent } from 'react';
import clsx from 'clsx';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface IProps {
  style?: CSSProperties;
  className?: string;
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
}

const SearchInput: FC<IProps> = ({ style, className, placeholder, value, onChange }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    let v: string;
    if (typeof e === 'string') v = e;
    else v = e.target.value;
    onChange && onChange(v);
  };

  return (
    <div
      className={clsx('flex items-center justify-between w-50 h-8 px-2 rounded-full shadow bg-white', className)}
      style={style}
    >
      <SearchOutlined />
      <Input
        allowClear
        className="w-full h-full mx-1 !border-0 !ring-0 bg-transparent"
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    </div>
  );
};

export default SearchInput;
