import { forwardRef } from 'react';
import { Dayjs } from 'dayjs';
import DatePicker from './DatePicker';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = forwardRef<never, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} mode={undefined} picker="time" ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
