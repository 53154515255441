import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';

interface IStateBasic {
  primaryColor: string;
  darkTheme: boolean;
  version: string;
  setting: {
    menu: boolean;
    menuWidth: number;
    collapsed: boolean;
  };

  brandCollapse: boolean;
  studioCollapse: boolean;
}

const initialState: IStateBasic = {
  primaryColor: '#1677ff',
  darkTheme: false,
  version: '',
  setting: {
    menu: true,
    menuWidth: 220,
    collapsed: false,
  },
  brandCollapse: false,
  studioCollapse: false,
};

export const basicSlice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    action__toggleTheme: (state) => {
      state.darkTheme = !state.darkTheme;
    },
    action__setPrimaryColor: (state, { payload }: PayloadAction<string>) => {
      state.primaryColor = payload;
    },
    action__setVersion: (state, { payload }: PayloadAction<string>) => {
      state.version = payload;
    },
    action__setSetting: (state, { payload }: PayloadAction<Partial<IStateBasic['setting']>>) => {
      state.setting = { ...state.setting, ...payload };
    },
    action__toggleBrandCollapse: (state) => {
      state.brandCollapse = !state.brandCollapse;
    },
    action__toggleStudioCollapse: (state) => {
      state.studioCollapse = !state.studioCollapse;
    },
  },
});

export const {
  action__toggleTheme,
  action__setPrimaryColor,
  action__setVersion,
  action__toggleBrandCollapse,
  action__toggleStudioCollapse,
} = basicSlice.actions;

export const selectBasic = ({ basic }: RootState) => basic;

export default basicSlice.reducer;
