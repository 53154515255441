import use from '@/services/saas.request';

import {
  ReqGetV1ReceptionStudioIdTodayScheduleListAll,
  ReqGetV1ReceptionStudioIdTodaysellPkgList,
  ResGetV1ReceptionStudioIdTodaysellPkgList,
  ReqGetV1ReceptionStudioIdVipInfo,
  ResGetV1ReceptionStudioIdVipInfo,
  ReqPostV1ReceptionStudioStudioIdOrder,
  ResPostV1ReceptionStudioStudioIdOrder,
  ResGetV1ReceptionStudioIdTodayScheduleListAll,
  ReqGetV1ReceptionStudioIdOrderOrderId,
  ResGetV1ReceptionStudioIdOrderOrderId,
  ReqPatchV1ReceptionStudioIdOrderOrderId,
  ResPatchV1ReceptionStudioIdOrderOrderId,
  ReqGetV1ReceptionStudioIdSalesmanListAll,
  ResGetV1ReceptionStudioIdSalesmanListAll,
  ReqGetV1ReceptionStudioIdOrderOrderIdInfo,
  ResGetV1ReceptionStudioIdOrderOrderIdInfo,
  ReqPostV1ReceptionStudioIdOrderOrderId,
  ResPostV1ReceptionStudioIdOrderOrderId,
  ReqGetV1ReceptionStudioIdCansellPkgListAll,
  ResGetV1ReceptionStudioIdCansellPkgListAll,
  ReqDeleteV1ReceptionStudioIdScheduleScheduleId,
  ResDeleteV1ReceptionStudioIdScheduleScheduleId,
  ReqPutV1ReceptionStudioIdScheduleScheduleIdClear,
  ResPutV1ReceptionStudioIdScheduleScheduleIdClear,
  ReqPutV1ReceptionStudioIdScheduleScheduleId,
  ResPutV1ReceptionStudioIdScheduleScheduleId,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdAppointmentList,
  ResGetV1ReceptionStudioIdScheduleScheduleIdAppointmentList,
  ResGetV1ReceptionStudioIdScheduleScheduleIdSignedList,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdSignedList,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdCleanList,
  ResGetV1ReceptionStudioIdScheduleScheduleIdCleanList,
  ResPutV1ReceptionStudioStudioIdScheduleScheduleIdReservation,
  ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdReservation,
  ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdCheck,
  ResPutV1ReceptionStudioStudioIdScheduleScheduleIdCheck,
  ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdUncheck,
  ResPutV1ReceptionStudioStudioIdScheduleScheduleIdUncheck,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdVipVipIdUncheckListAll,
  ResGetV1ReceptionStudioIdScheduleScheduleIdVipVipIdUncheckListAll,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdStudents,
  ResGetV1ReceptionStudioIdScheduleScheduleIdStudents,
  ReqGetV1ReceptionStudioSchedulePackageBooking,
  ResGetV1ReceptionStudioSchedulePackageBooking,
  ReqGetV1ReceptionStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList,
  ResGetV1ReceptionStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList,
  ReqPutV1ReceptionStudioIdScheduleScheduleIdReservationAll,
  ResPutV1ReceptionStudioIdScheduleScheduleIdReservationAll,
  ReqPostV1ReceptionStudioIdScheduleScheduleIdCopy,
  ResPostV1ReceptionStudioIdScheduleScheduleIdCopy,
  ReqGetV1ReceptionStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse,
  ResGetV1ReceptionStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse,
  ReqPatchV1SaasStudioIdUpdateOrderOrderIdStaffStaffId,
  ResPatchV1SaasStudioIdUpdateOrderOrderIdStaffStaffId,
  ReqPatchV1SaasStudioIdCloseOrderOrderId,
  ResPatchV1SaasStudioIdCloseOrderOrderId,
  ReqPatchV1ReceptionStudioIdScheduleScheduleIdUserUserId,
  ReqGetV1ReceptionStudioIdCansellPointsListAll,
  ResGetV1ReceptionStudioIdCansellPointsListAll,
  ReqGetV1ReceptionStudioIdScheduleScheduleIdPointsStudentsList,
  ResGetV1ReceptionStudioIdScheduleScheduleIdPointsStudentsList,
} from '@/services/params';

export const getTodaySchedule = (p: ReqGetV1ReceptionStudioIdTodayScheduleListAll) =>
  use<ReqGetV1ReceptionStudioIdTodayScheduleListAll, ResGetV1ReceptionStudioIdTodayScheduleListAll>(
    'get',
    `/v1/reception/studio/${p.id}/today/schedule/list_all`,
    p,
  );

export const getTodaySell = (p: ReqGetV1ReceptionStudioIdCansellPkgListAll) =>
  use<ReqGetV1ReceptionStudioIdCansellPkgListAll, ResGetV1ReceptionStudioIdCansellPkgListAll>(
    'get',
    `/v1/reception/studio/${p.id}/cansell/pkg/list_all`,
    p,
  );

export const getTodayIntegral = (p: ReqGetV1ReceptionStudioIdCansellPointsListAll) =>
  use<ReqGetV1ReceptionStudioIdCansellPointsListAll, ResGetV1ReceptionStudioIdCansellPointsListAll>(
    'get',
    `/v1/reception/studio/${p.id}/cansell/points/list_all`,
    p,
  );

export const postTodayOrder = (p: ReqPostV1ReceptionStudioStudioIdOrder) =>
  use<ReqPostV1ReceptionStudioStudioIdOrder, ResPostV1ReceptionStudioStudioIdOrder>(
    'post',
    `/v1/reception/studio/${p.studioId}/order`,
    p,
  );

export const getTodayHarvest = (p: ReqGetV1ReceptionStudioIdTodaysellPkgList) =>
  use<ReqGetV1ReceptionStudioIdTodaysellPkgList, ResGetV1ReceptionStudioIdTodaysellPkgList>(
    'get',
    `/v1/reception/studio/${p.id}/todaysell/pkg/list`,
    p,
  );

export const getVipInfoDesk = (p: ReqGetV1ReceptionStudioIdVipInfo) =>
  use<ReqGetV1ReceptionStudioIdVipInfo, ResGetV1ReceptionStudioIdVipInfo>(
    'get',
    `/v1/reception/studio/${p.id}/vip/info`,
    p,
  );

export const checkOrder = (p: ReqGetV1ReceptionStudioIdOrderOrderId) =>
  use<ReqGetV1ReceptionStudioIdOrderOrderId, ResGetV1ReceptionStudioIdOrderOrderId>(
    'get',
    `/v1/reception/studio/${p.id}/order/${p.orderId}`,
    p,
  );

export const refresfQRCode = (p: ReqPatchV1ReceptionStudioIdOrderOrderId) =>
  use<ReqPatchV1ReceptionStudioIdOrderOrderId, ResPatchV1ReceptionStudioIdOrderOrderId>(
    'patch',
    `/v1/reception/studio/${p.id}/order/${p.orderId}`,
    p,
  );

export const getSalesmanDesk = (p: ReqGetV1ReceptionStudioIdSalesmanListAll) =>
  use<ReqGetV1ReceptionStudioIdSalesmanListAll, ResGetV1ReceptionStudioIdSalesmanListAll>(
    'get',
    `/v1/reception/studio/${p.id}/salesman/list_all`,
    p,
  );
export const changeSale = (p: ReqPatchV1SaasStudioIdUpdateOrderOrderIdStaffStaffId) =>
  use<ReqPatchV1SaasStudioIdUpdateOrderOrderIdStaffStaffId, ResPatchV1SaasStudioIdUpdateOrderOrderIdStaffStaffId>(
    'patch',
    `/v1/saas/studio/${p.id}/update/order/${p.orderId}/staff/${p.staffId}`,
    p
  )

export const closeOrder = (p: ReqPatchV1SaasStudioIdCloseOrderOrderId) =>
  use<ReqPatchV1SaasStudioIdCloseOrderOrderId, ResPatchV1SaasStudioIdCloseOrderOrderId>(
    'patch',
    `/v1/saas/studio/${p.id}/close/order/${p.orderId}`,
    p
  )
export const getOrderDetailDesk = (p: ReqGetV1ReceptionStudioIdOrderOrderIdInfo) =>
  use<ReqGetV1ReceptionStudioIdOrderOrderIdInfo, ResGetV1ReceptionStudioIdOrderOrderIdInfo>(
    'get',
    `/v1/reception/studio/${p.id}/order/${p.orderId}/info`,
    p,
  );

export const goPayDesk = (p: ReqPostV1ReceptionStudioIdOrderOrderId) =>
  use<ReqPostV1ReceptionStudioIdOrderOrderId, ResPostV1ReceptionStudioIdOrderOrderId>(
    'post',
    `/v1/reception/studio/${p.id}/order/${p.orderId}`,
    p,
  );

export const deleteScheduleDesk = (p: ReqDeleteV1ReceptionStudioIdScheduleScheduleId) =>
  use<ReqDeleteV1ReceptionStudioIdScheduleScheduleId, ResDeleteV1ReceptionStudioIdScheduleScheduleId>(
    'delete',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}`,
    p
  )

export const putScheduleClearDesk = (p: ReqPutV1ReceptionStudioIdScheduleScheduleIdClear) =>
  use<ReqPutV1ReceptionStudioIdScheduleScheduleIdClear, ResPutV1ReceptionStudioIdScheduleScheduleIdClear>(
    'put',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/clear`,
    p
  )

export const putScheduleDesk = (p: ReqPutV1ReceptionStudioIdScheduleScheduleId) =>
  use<ReqPutV1ReceptionStudioIdScheduleScheduleId, ResPutV1ReceptionStudioIdScheduleScheduleId>(
    'put',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}`,
    p
  )
export const getScheduleOrderInfoDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdAppointmentList) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdAppointmentList, ResGetV1ReceptionStudioIdScheduleScheduleIdAppointmentList>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/appointment/list`,
    p
  )
export const getCycleScheduleOrderInfoDesk = (p: ReqGetV1ReceptionStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList) =>
  use<ReqGetV1ReceptionStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList, ResGetV1ReceptionStudioStudioIdCycleScheduleCycleScheduleIdAppointmentList>(
    'get',
    `/v1/reception/studio/${p.studioId}/cycle_schedule/${p.cycle_scheduleId}/appointment/list`,
    p
  )
export const getScheduleSignInfoDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdSignedList) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdSignedList, ResGetV1ReceptionStudioIdScheduleScheduleIdSignedList>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/signed/list`,
    p
  )

export const getScheduleCleanInfoDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdCleanList) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdCleanList, ResGetV1ReceptionStudioIdScheduleScheduleIdCleanList>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/clean/list`,
    p
  )
export const patchVipCountDesk = (p: ReqPatchV1ReceptionStudioIdScheduleScheduleIdUserUserId) =>
  use<ReqPatchV1ReceptionStudioIdScheduleScheduleIdUserUserId, ReqPatchV1ReceptionStudioIdScheduleScheduleIdUserUserId>(
    'patch',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/user/${p.userId}`,
    p
  )
export const putScheduleReservationDesk = (p: ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdReservation) =>
  use<ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdReservation, ResPutV1ReceptionStudioStudioIdScheduleScheduleIdReservation>(
    'put',
    `/v1/reception/studio/${p.studioId}/schedule/${p.scheduleId}/reservation`,
    p
  )

export const putCheckDesk = (p: ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdCheck) =>
  use<ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdCheck, ResPutV1ReceptionStudioStudioIdScheduleScheduleIdCheck>(
    'put',
    `/v1/reception/studio/${p.studioId}/schedule/${p.scheduleId}/check`,
    p
  )

export const putUnCheckDesk = (p: ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdUncheck) =>
  use<ReqPutV1ReceptionStudioStudioIdScheduleScheduleIdUncheck, ResPutV1ReceptionStudioStudioIdScheduleScheduleIdUncheck>(
    'put',
    `/v1/reception/studio/${p.studioId}/schedule/${p.scheduleId}/uncheck`,
    p
  )

export const getVipUnCheckListDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdVipVipIdUncheckListAll) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdVipVipIdUncheckListAll, ResGetV1ReceptionStudioIdScheduleScheduleIdVipVipIdUncheckListAll>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/vip/${p.vipId}/uncheck/list_all`,
    p
  )

export const getScheduleStudentsDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdStudents) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdStudents, ResGetV1ReceptionStudioIdScheduleScheduleIdStudents>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/students`,
    p
  )


export const getUserPkgListDesk = (p: ReqGetV1ReceptionStudioSchedulePackageBooking) =>
  use<ReqGetV1ReceptionStudioSchedulePackageBooking, ResGetV1ReceptionStudioSchedulePackageBooking>(
    'get',
    `/v1/reception/studio/schedule/package/booking`,
    p
  )

export const helpReservationAllDesk = (p: ReqPutV1ReceptionStudioIdScheduleScheduleIdReservationAll) =>
  use<ReqPutV1ReceptionStudioIdScheduleScheduleIdReservationAll, ResPutV1ReceptionStudioIdScheduleScheduleIdReservationAll>(
    'put',
    `/v1/reception/studio/${p.id}/schedule/${p.schedule_id}/reservation_all`,
    p
  )

export const postCopyScheduleDesk = (p: ReqPostV1ReceptionStudioIdScheduleScheduleIdCopy) =>
  use<ReqPostV1ReceptionStudioIdScheduleScheduleIdCopy, ResPostV1ReceptionStudioIdScheduleScheduleIdCopy>(
    'post',
    `/v1/reception/studio/${p.id}/schedule/${p.schedule_id}/copy`,
    p
  )

export const canAppointmentDesk = (p: ReqGetV1ReceptionStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse) =>
  use<ReqGetV1ReceptionStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse, ResGetV1ReceptionStudioStudioIdScheduleScheduleIdCurriculumCurriculumIdHasUse>(
    'get',
    `/v1/reception/studio/${p.studioId}/schedule/${p.scheduleId}/curriculum/${p.curriculumId}/hasUse`,
    p
  )

export const integralVipListDesk = (p: ReqGetV1ReceptionStudioIdScheduleScheduleIdPointsStudentsList) =>
  use<ReqGetV1ReceptionStudioIdScheduleScheduleIdPointsStudentsList, ResGetV1ReceptionStudioIdScheduleScheduleIdPointsStudentsList>(
    'get',
    `/v1/reception/studio/${p.id}/schedule/${p.scheduleId}/points/students/list`,
    p
  )


