import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { TabletOutlined, LockOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { RE__MOBILE } from '@cyberdancer/regexp';
import { postUser } from '@/services';

type Values = Parameters<typeof postUser>[number];
interface IProps {
  setBlock: (n: number) => void;
}

const Signup = ({ setBlock }: IProps) => {
  const [passwdShow, setPasswdShow] = useState('password');
  const [confirmShow, setConfirmShow] = useState('password');

  const [form] = Form.useForm();

  const { mutateAsync, isLoading } = useMutation(postUser);

  const handleSubmit = async (v: Values) => {
    const { success, msg } = await mutateAsync(v);
    if (success) {
      form.resetFields();
      message.success('注册成功', 1, () => setBlock(1));
    } else message.error(msg);
  };

  return (
    <>
      <h1 className="font-medium">注册账号</h1>
      <Form<Values> form={form} onFinish={handleSubmit}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: '手机号不能为空',
            },
            {
              pattern: RE__MOBILE,
              message: '手机号格式错误',
            },
          ]}
        >
          <Input allowClear placeholder="手机号" prefix={<TabletOutlined />} size="large" />
        </Form.Item>
        <Form.Item
          name="passwd"
          rules={[
            () => ({
              validator(_, value) {
                if (!value) return Promise.reject(new Error('密码不能为空'));
                if (value.length < 8) return Promise.reject(new Error('密码长度必须大于等于8位'));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear
            placeholder="密码"
            prefix={<LockOutlined />}
            size="large"
            suffix={
              passwdShow ? (
                <EyeInvisibleOutlined onClick={() => setPasswdShow('')} />
              ) : (
                <EyeOutlined onClick={() => setPasswdShow('password')} />
              )
            }
            type={passwdShow}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) return Promise.reject(new Error('确认密码不能为空'));
                if (value !== getFieldValue('passwd')) return Promise.reject(new Error('两次密码输入不一致'));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear
            placeholder="确认密码"
            prefix={<LockOutlined />}
            size="large"
            suffix={
              confirmShow ? (
                <EyeInvisibleOutlined onClick={() => setConfirmShow('')} />
              ) : (
                <EyeOutlined onClick={() => setConfirmShow('password')} />
              )
            }
            type={confirmShow}
          />
        </Form.Item>
        <Form.Item>
          <Button block htmlType="submit" loading={isLoading} type="primary">
            确定
          </Button>
        </Form.Item>
        <p className="text-center">
          <a onClick={() => setBlock(1)}>去登录</a>
        </p>
      </Form>
    </>
  );
};

export default Signup;
