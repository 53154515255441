import {
  DesktopOutlined,
  PayCircleOutlined,
  UnorderedListOutlined,
  SolutionOutlined,
  TableOutlined,
  SettingOutlined,
  NotificationOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import generate from './_generate';

export const routes = [
  { KEY: 'DESKTOP', name: '前台', path: '/studio/desktop', icon: <DesktopOutlined /> },
  { KEY: 'STUDIO.DASHBOARD', name: '门店仪表盘', path: '/studio/dashboard', icon: <DashboardOutlined /> },
  {
    KEY: 'STUDIO.COURSES',
    name: '课程排期',
    path: '/studio/courses',
    nopage: true,
    icon: <TableOutlined />,
    children: [
      {
        KEY: 'STUDIO.COURSES.SCHEDULE',
        name: '排课',
        path: '/studio/courses/schedule',
        children: [
          {
            KEY: 'STUDIO.COURSES.SCHEDULE.CYCLE',
            name: '循环排课',
            path: '/studio/courses/schedule/cycle',
          },
        ],
      },
      {
        KEY: 'STUDIO.COURSES.POINTS',
        name: '积分',
        path: '/studio/courses/points',
      },
      {
        KEY: 'STUDIO.COURSES.PKG',
        name: '课程套餐',
        path: '/studio/courses/pkg',
        children: [
          {
            KEY: 'STUDIO.COURSES.PKG.CREATE',
            name: '新增套餐',
            path: '/studio/courses/pkg/create',
          },
          {
            KEY: 'STUDIO.COURSES.PKG.EDIT',
            name: '编辑套餐',
            path: '/studio/courses/pkg/edit',
          },
          {
            KEY: 'STUDIO.COURSES.PKG.DETAIL',
            name: '套餐详情',
            path: '/studio/courses/pkg/detail',
          },
          {
            KEY: 'STUDIO.COURSES.PKG.GROUP',
            name: '分组管理',
            path: '/studio/courses/pkg/group',
          },
        ],
      },
      {
        KEY: 'STUDIO.COURSES.LIB',
        name: '课程库',
        path: '/studio/courses/lib',
        children: [
          {
            KEY: 'STUDIO.COURSES.LIB.DETAIL',
            name: '课程详情',
            path: '/studio/courses/lib/detail',
          },
          // {
          //   KEY: 'STUDIO.COURSES.LIB.CREATE',
          //   name: '新增课程',
          //   path: '/studio/courses/lib/create',
          // },
          // {
          //   KEY: 'STUDIO.COURSES.LIB.EDIT',
          //   name: '编辑课程',
          //   path: '/studio/courses/lib/edit',
          // },
        ],
      },
      { KEY: 'STUDIO.COURSES.TAG', name: '授课方式', path: '/studio/courses/tag' },
    ],
  },
  {
    KEY: 'STUDIO.VIP',
    name: '会员管理',
    path: '/studio/vip',
    nopage: true,
    icon: <SolutionOutlined />,
    children: [
      {
        KEY: 'STUDIO.VIP.LIST',
        name: '会员列表',
        path: '/studio/vip/list',
        children: [
          { KEY: 'STUDIO.VIP.EDIT', name: '会员编辑', path: '/studio/vip/list/edit' },
          {
            KEY: 'STUDIO.VIP.DETAIL',
            name: '会员详情',
            path: '/studio/vip/list/detail',
            children: [
              { KEY: 'STUDIO.VIP.DETAIL.PKG', name: '套餐订单', path: '/studio/vip/list/detail/pkg' },
              { KEY: 'STUDIO.VIP.DETAIL.AFTER', name: '套餐售后', path: '/studio/vip/list/detail/after' },
              { KEY: 'STUDIO.VIP.DETAIL.INTEGRAL', name: '积分订单', path: '/studio/vip/list/detail/integral' },
              {
                KEY: 'STUDIO.VIP.DETAIL.INTEGRALAFTER',
                name: '积分售后',
                path: '/studio/vip/list/detail/integralAfter',
              },
            ],
          },
          {
            KEY: 'STUDIO.VIP.ADD',
            name: '新增会员',
            path: '/studio/vip/list/add',
          },
        ],
      },
      { KEY: 'STUDIO.VIP.NEAR', name: '临期列表', path: '/studio/vip/near' },
      { KEY: 'STUDIO.VIP.OVER', name: '过期列表', path: '/studio/vip/over' },
    ],
  },
  // {
  //   KEY: 'STUDIO.SALEACT',
  //   name: '营销活动',
  //   path: '/studio/saleactivity',
  //   nopage: true,
  //   icon: <BookOutlined />,
  //   children: [
  //     {
  //       KEY: 'STUDIO.SALEACT.REDUCE',
  //       name: '优惠券',
  //       path: '/studio/saleactivity/reduce',
  //       children:[
  //         { KEY: 'STUDIO.SALEACT.REDUCE.CREATE', name: '新增优惠券', path: '/studio/saleactivity/reduce/create' },
  //         { KEY: 'STUDIO.SALEACT.REDUCE.DETAIL', name: '优惠券详情', path: '/studio/saleactivity/reduce/detail' },
  //         { KEY: 'STUDIO.SALEACT.REDUCE.GIVE', name: '优惠券发放', path: '/studio/saleactivity/reduce/give' },
  //       ]
  //     },
  //   ],
  // },
  {
    KEY: 'STUDIO.ORDER',
    name: '订单管理',
    path: '/studio/order',
    nopage: true,
    icon: <UnorderedListOutlined />,
    children: [
      {
        KEY: 'STUDIO.ORDER.LIST',
        name: '套餐积分订单',
        path: '/studio/order/list',
        children: [{ KEY: 'STUDIO.ORDER.LIST.DETAIL', name: '订单详情', path: '/studio/order/list/detail' }],
      },
      {
        KEY: 'STUDIO.ORDER.POINTLIST',
        name: '课程订单',
        path: '/studio/order/pointlist',
      },
      {
        KEY: 'STUDIO.ORDER.AFTER',
        name: '售后记录',
        path: '/studio/order/after',
        children: [{ KEY: 'STUDIO.ORDER.AFTER.DETAIL', name: '售后详情', path: '/studio/order/after/detail' }],
      },
    ],
  },
  {
    KEY: 'STUDIO.FINANCE',
    name: '财务管理',
    path: '/studio/finance',
    nopage: true,
    icon: <PayCircleOutlined />,
    children: [{ KEY: 'STUDIO.FINANCE.PAYMENTS', name: '收支流水', path: '/studio/finance/payments' }],
  },
  {
    KEY: 'STUDIO.CAMPAIGN',
    name: '消息活动',
    path: '/studio/campaign',
    nopage: true,
    icon: <NotificationOutlined />,
    children: [
      {
        KEY: 'STUDIO.CAMPAIGN.NOTICE',
        name: '会员通知',
        path: '/studio/campaign/notice',
        children: [{ KEY: 'STUDIO.CAMPAIGN.NOTICE.CREATE', name: '新增通知', path: '/studio/campaign/notice/create' }],
      },
      {
        KEY: 'STUDIO.CAMPAIGN.MSG',
        name: '短信通知（限免）',
        path: '/studio/campaign/msg',
      },
      {
        KEY: 'STUDIO.CAMPAIGN.OANOTICE',
        name: '公众号通知（限免）',
        path: '/studio/campaign/oanotice',
      },
      // {
      //   KEY: 'STUDIO.CAMPAIGN.ACTIVITY',
      //   name: '活动管理',
      //   path: '/studio/campaign/activity',
      //   children: [
      //     { KEY: 'STUDIO.CAMPAIGN.ACTIVITY.CREATE', name: '创建活动', path: '/studio/campaign/activity/create' },
      //   ],
      // },
    ],
  },
  {
    KEY: 'STUDIO.SETTING',
    name: '门店设置',
    path: '/studio/setting',
    icon: <SettingOutlined />,
    children: [
      {
        KEY: 'STUDIO.SETTING.BLACK',
        name: '小黑屋',
        path: '/studio/setting/black',
      },
      {
        KEY: 'STUDIO.SETTING.INFO',
        name: '门店信息',
        path: '/studio/setting/info',
      },
    ],
  },
];

const studioRoutes = generate(routes);
export default studioRoutes;
