import use, { downloadInterceptor } from '@/services/saas.request';

import {
  ReqGetV1SaasBrandBrandIdFundList,
  ResGetV1SaasBrandBrandIdFundList,
  ReqGetV1SaasBrandBrandIdRefundList,
  ResGetV1SaasBrandBrandIdRefundList,
  ReqGetV1SaasBrandBrandIdCashList,
  ResGetV1SaasBrandBrandIdCashList,
  ReqGetV1SaasBrandBrandIdBankcardList,
  ResGetV1SaasBrandBrandIdBankcardList,
  ReqPostV1SaasBrandIdFundList,
  ResPostV1SaasBrandIdFundList,
  ReqGetV1SaasBrandIdFundInfo,
  ResGetV1SaasBrandIdFundInfo,
  ReqGetV1SaasBrandBrandIdFundSettleList,
  ResGetV1SaasBrandBrandIdFundSettleList,
  ReqGetV1SaasBrandBrandIdBankcardPersonListAll,
  ResGetV1SaasBrandBrandIdBankcardPersonListAll,
  ReqGetV1SaasBrandBrandIdBankcardCompanyListAll,
  ResGetV1SaasBrandBrandIdBankcardCompanyListAll,
  ReqPostV1SaasBrandIdBankcard,
  ResPostV1SaasBrandIdBankcard,
  ReqGetV1VerificationType,
  ResGetV1VerificationType,
  ReqDeleteV1SaasBrandIdBankcard,
  ResDeleteV1SaasBrandIdBankcard,
  ReqPostV1SaasBrandIdFundSettle,
  ResPostV1SaasBrandIdFundSettle,
  ReqPostV1SaasBrandIdRecharge,
  ResPostV1SaasBrandIdRecharge,
  ReqGetV1SaasBrandIdChargeChargeId,
  ResGetV1SaasBrandIdChargeChargeId,
  ReqGetV1SaasBrandBrandIdRechargeList,
  ResGetV1SaasBrandBrandIdRechargeList,
  ReqGetV1SaasBrandBrandIdBalance,
  ResGetV1SaasBrandBrandIdBalance,
  ReqPatchV1SaasBrandIdChargeChargeId,
  ResPatchV1SaasBrandIdChargeChargeId,
  ReqDeleteV1SaasBrandIdChargeChargeId,
  ResDeleteV1SaasBrandIdChargeChargeId,
  ReqGetV1SaasBrandBrandIdDelayList,
  ResGetV1SaasBrandBrandIdDelayList,
  ReqGetV1SaasBrandBrandIdFreeClassList,
  ResGetV1SaasBrandBrandIdFreeClassList,
  ReqGetV1SaasBrandBrandIdCount,
  ResGetV1SaasBrandBrandIdCount,
  ReqGetV1SaasBrandBrandIdCourseList,
  ResGetV1SaasBrandBrandIdCourseList,
} from '@/services/params';

export const getFundBrand = (p: ReqGetV1SaasBrandBrandIdFundList) =>
  use<ReqGetV1SaasBrandBrandIdFundList, ResGetV1SaasBrandBrandIdFundList>(
    'get',
    `/v1/saas/brand/${p.brandId}/fund/list`,
    p,
  );

export const getRefundBrand = (p: ReqGetV1SaasBrandBrandIdRefundList) =>
  use<ReqGetV1SaasBrandBrandIdRefundList, ResGetV1SaasBrandBrandIdRefundList>(
    'get',
    `/v1/saas/brand/${p.brandId}/refund/list`,
    p,
  );
export const getDelayBrand = (p: ReqGetV1SaasBrandBrandIdDelayList) =>
  use<ReqGetV1SaasBrandBrandIdDelayList, ResGetV1SaasBrandBrandIdDelayList>(
    'get',
    `/v1/saas/brand/${p.brandId}/delay/list`,
    p,
  );
export const getGiveBrand = (p: ReqGetV1SaasBrandBrandIdFreeClassList) =>
  use<ReqGetV1SaasBrandBrandIdFreeClassList, ResGetV1SaasBrandBrandIdFreeClassList>(
    'get',
    `/v1/saas/brand/${p.brandId}/free/class/list`,
    p,
  );
export const getCashBrand = (p: ReqGetV1SaasBrandBrandIdCashList) =>
  use<ReqGetV1SaasBrandBrandIdCashList, ResGetV1SaasBrandBrandIdCashList>(
    'get',
    `/v1/saas/brand/${p.brandId}/cash/list`,
    p,
  );

export const getBankCard = (p: ReqGetV1SaasBrandBrandIdBankcardList) =>
  use<ReqGetV1SaasBrandBrandIdBankcardList, ResGetV1SaasBrandBrandIdBankcardList>(
    'get',
    `/v1/saas/brand/${p.brandId}/bankcard/list`,
    p,
  );

export const exportFundList = (p: ReqPostV1SaasBrandIdFundList, type: string) =>
  use<ReqPostV1SaasBrandIdFundList, ResPostV1SaasBrandIdFundList>(
    'post',
    `/v1/saas/brand/${p.id}/fund/list`,
    p,
    downloadInterceptor<ResPostV1SaasBrandIdFundList>(type),
  );

export const getFundInfo = (p: ReqGetV1SaasBrandIdFundInfo) =>
  use<ReqGetV1SaasBrandIdFundInfo, ResGetV1SaasBrandIdFundInfo>('get', `/v1/saas/brand/${p.id}/fund/info`);

export const getPointInfo = (p: ReqGetV1SaasBrandBrandIdCount) =>
  use<ReqGetV1SaasBrandBrandIdCount, ResGetV1SaasBrandBrandIdCount>('get', `/v1/saas/brand/${p.brandId}/count`);

export const getFundList = (p: ReqGetV1SaasBrandBrandIdFundSettleList) =>
  use<ReqGetV1SaasBrandBrandIdFundSettleList, ResGetV1SaasBrandBrandIdFundSettleList>(
    'get',
    `/v1/saas/brand/${p.brandId}/fund/settle/list`,
    p,
  );
export const getPointList = (p: ReqGetV1SaasBrandBrandIdCourseList) =>
  use<ReqGetV1SaasBrandBrandIdCourseList, ResGetV1SaasBrandBrandIdCourseList>(
    'get',
    `/v1/saas/brand/${p.brandId}/course/list`,
    p,
  );
export const getPrivateCards = (p: ReqGetV1SaasBrandBrandIdBankcardPersonListAll) =>
  use<ReqGetV1SaasBrandBrandIdBankcardPersonListAll, ResGetV1SaasBrandBrandIdBankcardPersonListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/bankcard/person/list_all`,
  );

export const getCompanyCards = (p: ReqGetV1SaasBrandBrandIdBankcardCompanyListAll) =>
  use<ReqGetV1SaasBrandBrandIdBankcardCompanyListAll, ResGetV1SaasBrandBrandIdBankcardCompanyListAll>(
    'get',
    `/v1/saas/brand/${p.brandId}/bankcard/company/list_all`,
  );

export const bindCard = (p: ReqPostV1SaasBrandIdBankcard) =>
  use<ReqPostV1SaasBrandIdBankcard, ResPostV1SaasBrandIdBankcard>('post', `/v1/saas/brand/${p.id}/bankcard`, p);
export const unBindCard = (p: ReqDeleteV1SaasBrandIdBankcard) =>
  use<ReqDeleteV1SaasBrandIdBankcard, ResDeleteV1SaasBrandIdBankcard>('delete', `/v1/saas/brand/${p.id}/bankcard`, p);

export const getCode = (p: ReqGetV1VerificationType) =>
  use<ReqGetV1VerificationType, ResGetV1VerificationType>('get', `/v1/verification/${p.type}`, p);

export const postWithDraw = (p: ReqPostV1SaasBrandIdFundSettle) =>
  use<ReqPostV1SaasBrandIdFundSettle, ResPostV1SaasBrandIdFundSettle>('post', `/v1/saas/brand/${p.id}/fund/settle`, p);

export const charge = (p: ReqPostV1SaasBrandIdRecharge) =>
  use<ReqPostV1SaasBrandIdRecharge, ResPostV1SaasBrandIdRecharge>(
    'post',
    `/v1/saas/brand/${p.id}/recharge`,
    p
  )

export const checkCharge = (p: ReqGetV1SaasBrandIdChargeChargeId) =>
  use<ReqGetV1SaasBrandIdChargeChargeId, ResGetV1SaasBrandIdChargeChargeId>(
    'get',
    `/v1/saas/brand/${p.id}/charge/${p.chargeId}`,
    p
  )

export const chargeList = (p: ReqGetV1SaasBrandBrandIdRechargeList) =>
  use<ReqGetV1SaasBrandBrandIdRechargeList, ResGetV1SaasBrandBrandIdRechargeList>(
    'get',
    `/v1/saas/brand/${p.brandId}/recharge/list`,
    p
  )

export const getStoredBalance = (p: ReqGetV1SaasBrandBrandIdBalance) =>
  use<ReqGetV1SaasBrandBrandIdBalance, ResGetV1SaasBrandBrandIdBalance>(
    'get',
    `/v1/saas/brand/${p.brandId}/balance`,
    p
  )

export const refreshQRCodeBrand = (p: ReqPatchV1SaasBrandIdChargeChargeId) =>
  use<ReqPatchV1SaasBrandIdChargeChargeId, ResPatchV1SaasBrandIdChargeChargeId>(
    'patch',
    `/v1/saas/brand/${p.id}/charge/${p.chargeId}`,
    p
  )

export const deleteCharge = (p: ReqDeleteV1SaasBrandIdChargeChargeId) =>
  use<ReqDeleteV1SaasBrandIdChargeChargeId, ResDeleteV1SaasBrandIdChargeChargeId>(
    'delete',
    `/v1/saas/brand/${p.id}/charge/${p.chargeId}`,
    p
  )