import type { PropsWithChildren } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectBasic, action__toggleStudioCollapse } from '@/model/basicSlice';

import Header from './header';
import Info from './info';
import Aside from './aside';

const Layout = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { studioCollapse } = useAppSelector(selectBasic);

  return (
    <>
      {/*<div className="fixed left-0 top-0 right-0 z-0 h-40 bg-gray-900"></div>*/}

      <div className="flex relative">
        <div
          className={`fixed top-[170px] w-3 h-10 bg-white hover:bg-gray-900 hover:text-white rounded-r shadow cursor-pointer flex items-center justify-center transition-all duration-200 ${
            studioCollapse ? 'left-0' : 'left-64'
          }`}
          onClick={() => dispatch(action__toggleStudioCollapse())}>
          {studioCollapse ? (
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                fillRule="evenodd"
              />
            </svg>
          ) : (
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                fillRule="evenodd"
              />
            </svg>
          )}
        </div>
        <aside
          className={`flex-none flex flex-col sticky top-0 h-screen bg-white shadow overflow-hidden transition-[width] duration-200 ${
            studioCollapse ? 'w-0' : 'w-64'
          }`}>
          <Info />
          <div className="flex-1 overflow-y-auto hide-scrollbar">
            <Aside />
          </div>
        </aside>
        <div className="flex-1 pt-16 overflow-hidden">
          <header
            className="fixed top-0 z-10 px-6 h-16 bg-gray-900 transition-all duration-200"
            style={{ width: `calc(100% - ${studioCollapse ? 0 : 16}rem)` }}>
            <Header />
          </header>
          <main className="p-6">{children}</main>
          <footer className="h-16" />
        </div>
      </div>
    </>
  );
};

export default Layout;
