import use from '@/services/saas.request';
import {
  ReqGetV1SaasStudioStudioIdVipNoticeList,
  ResGetV1SaasStudioStudioIdVipNoticeList,
  ReqPostV1SaasStudioStudioIdVipNotice,
  ResPostV1SaasStudioStudioIdVipNotice,
  ReqGetV1SaasStudioStudioIdVipNoticeVipListAll,
  ResGetV1SaasStudioStudioIdVipNoticeVipListAll,
  ReqGetV1SaasStudioStudioIdVipNoticeIdDetail,
  ResGetV1SaasStudioStudioIdVipNoticeIdDetail,
  ReqDeleteV1SaasStudioStudioIdVipNoticeId,
  ResDeleteV1SaasStudioStudioIdVipNoticeId,
  ReqPutV1SaasStudioStudioIdVipNoticeIdRevert,
  ResPutV1SaasStudioStudioIdVipNoticeIdRevert,
  ReqPutV1SaasStudioStudioIdVipNoticeIdMsm,
  ResPutV1SaasStudioStudioIdVipNoticeIdMsm,
  ReqPutV1SaasStudioStudioIdVipNoticeIdPublish,
  ResPutV1SaasStudioStudioIdVipNoticeIdPublish,
  ReqGetV1SaasStudioIdMessage,
  ResGetV1SaasStudioIdMessage,
  ReqGetV1SaasStudioIdMessageTime,
  ResGetV1SaasStudioIdMessageTime,
  ReqPutV1SaasStudioIdMessageTime,
  ResPutV1SaasStudioIdMessageTime,
  ReqPutV1SaasStudioStudioIdMessageId,
  ResPutV1SaasStudioStudioIdMessageId,
  ReqGetApiV1SaasStudioIdRoles,
  ReqGetV1SaasStudioIdSms,
  ResGetV1SaasStudioIdSms,
  ResGetApiV1SaasStudioIdRoles,
  ReqGetV1SaasStudioIdSmsLogs,
  ResGetV1SaasStudioIdSmsLogs,
} from './params';

export const getVipNoticeList = (p: ReqGetV1SaasStudioStudioIdVipNoticeList) =>
  use<ReqGetV1SaasStudioStudioIdVipNoticeList, ResGetV1SaasStudioStudioIdVipNoticeList>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip_notice/list`,
    p,
  );

export const postVipNotice = (p: ReqPostV1SaasStudioStudioIdVipNotice) =>
  use<ReqPostV1SaasStudioStudioIdVipNotice, ResPostV1SaasStudioStudioIdVipNotice>(
    'post',
    `/v1/saas/studio/${p.studioId}/vip_notice`,
    p,
  );

export const getNoticeVipListAll = (p: ReqGetV1SaasStudioStudioIdVipNoticeVipListAll) =>
  use<ReqGetV1SaasStudioStudioIdVipNoticeVipListAll, ResGetV1SaasStudioStudioIdVipNoticeVipListAll>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip_notice/vip/list_all`,
    p,
  );

export const getVipNoticeDetail = (p: ReqGetV1SaasStudioStudioIdVipNoticeIdDetail) =>
  use<ReqGetV1SaasStudioStudioIdVipNoticeIdDetail, ResGetV1SaasStudioStudioIdVipNoticeIdDetail>(
    'get',
    `/v1/saas/studio/${p.studioId}/vip_notice/${p.id}/detail`,
    p,
  );

export const deleteVipNotice = (p: ReqDeleteV1SaasStudioStudioIdVipNoticeId) =>
  use<ReqDeleteV1SaasStudioStudioIdVipNoticeId, ResDeleteV1SaasStudioStudioIdVipNoticeId>(
    'delete',
    `/v1/saas/studio/${p.studioId}/vip_notice/${p.id}`,
    p,
  );

export const putVipNoticeRevert = (p: ReqPutV1SaasStudioStudioIdVipNoticeIdRevert) =>
  use<ReqPutV1SaasStudioStudioIdVipNoticeIdRevert, ResPutV1SaasStudioStudioIdVipNoticeIdRevert>(
    'put',
    `/v1/saas/studio/${p.studioId}/vip_notice/${p.id}/revert`,
    p,
  );

export const putVipNoticeMsm = (p: ReqPutV1SaasStudioStudioIdVipNoticeIdMsm) =>
  use<ReqPutV1SaasStudioStudioIdVipNoticeIdMsm, ResPutV1SaasStudioStudioIdVipNoticeIdMsm>(
    'put',
    `/v1/saas/studio/${p.studioId}/vip_notice/${p.id}/msm`,
    p,
  );

export const putVipNoticePublish = (p: ReqPutV1SaasStudioStudioIdVipNoticeIdPublish) =>
  use<ReqPutV1SaasStudioStudioIdVipNoticeIdPublish, ResPutV1SaasStudioStudioIdVipNoticeIdPublish>(
    'put',
    `/v1/saas/studio/${p.studioId}/vip_notice/${p.id}/publish`,
    p,
  );

export const getStudioMessage = (p: ReqGetV1SaasStudioIdMessage) =>
  use<ReqGetV1SaasStudioIdMessage, ResGetV1SaasStudioIdMessage>(
    'get',
    `/v1/saas/studio/${p.id}/message`,
    p
  )

export const getStudioShotMsg = (p: ReqGetV1SaasStudioIdSms) =>
  use<ReqGetV1SaasStudioIdSms, ResGetV1SaasStudioIdSms>(
    'get',
    `/v1/saas/studio/${p.id}/sms`,
    p
  )

export const getMsgList = (p: ReqGetV1SaasStudioIdSmsLogs) =>
  use<ReqGetV1SaasStudioIdSmsLogs, ResGetV1SaasStudioIdSmsLogs>(
    'get',
    `/v1/saas/studio/${p.id}/sms/logs`,
    p
  )
export const teacherList = (p: ReqGetApiV1SaasStudioIdRoles) =>
  use<ReqGetApiV1SaasStudioIdRoles, ResGetApiV1SaasStudioIdRoles>(
    'get',
    `/v1/saas/studio/${p.id}/roles`,
    p
  )
export const putStudioMessage = (p: ReqPutV1SaasStudioStudioIdMessageId) =>
  use<ReqPutV1SaasStudioStudioIdMessageId, ResPutV1SaasStudioStudioIdMessageId>(
    'put',
    `/v1/saas/studio/${p.studioId}/message/${p.id}`,
    p
  )

export const getStudioSetTime = (p: ReqGetV1SaasStudioIdMessageTime) =>
  use<ReqGetV1SaasStudioIdMessageTime, ResGetV1SaasStudioIdMessageTime>(
    'get',
    `/v1/saas/studio/${p.id}/message/time`,
    p
  )

export const putStudioSetTime = (p: ReqPutV1SaasStudioIdMessageTime) =>
  use<ReqPutV1SaasStudioIdMessageTime, ResPutV1SaasStudioIdMessageTime>(
    'put',
    `/v1/saas/studio/${p.id}/message/time`,
    p
  )

