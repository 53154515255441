import { ElementRef, FC, ReactElement, useEffect, useRef } from 'react';
import { useMemo, useState } from 'react';
import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import UISet from './UISet'

import type { IData, IRange } from './common';
import Day from './day';
import Week from './week';
import Month from './month';
import { SettingOutlined } from '@ant-design/icons';

const MRangeTypes = new Map<number, { ch: string; en: OpUnitType }>([
  [0, { ch: '日', en: 'day' }],
  [1, { ch: '周', en: 'week' }],
  [2, { ch: '月', en: 'month' }],
]);

interface IProps {
  type?: number;
  data?: IData;
  refresh?: (range: IRange, rangeType: number, step: number,flag:boolean) => Promise<unknown> | void;
  renderCard?: (data: unknown) => ReactElement;
  clickCardCb?: (data: unknown) => void;
  clickEmptyCb?: (startStamp: number) => void;
  clickCopy?:(startStamp: number) => void
}

const Syllabus: FC<IProps> = ({ type = 1, data = [], refresh, renderCard, clickCardCb, clickEmptyCb,clickCopy }) => {
  const UISetRef = useRef<ElementRef<typeof UISet>>(null);
  const [rangeType, setRangeType] = useState(type); // 日0、周1、月2
  const [step, setStep] = useState(0);
  const range = useMemo<IRange>(() => {
    const unit = MRangeTypes.get(rangeType);
    if (unit) {
      const currentRange = dayjs().add(step, unit.en as ManipulateType);
      return {
        start: currentRange.startOf(unit.en).valueOf(),
        end: currentRange.endOf(unit.en).valueOf(),
      };
    } else throw new Error('ERROR: invalid rangeType');
  }, [rangeType, step]);

  useEffect(() => {
    refresh?.(range, rangeType, step,false)
  }, [range, rangeType, step])

  return (
    <div className="bg-white flex flex-col">
      <div className="flex-none mb-6 bg-white flex">
        <div className="basis-40 flex item-center"><SettingOutlined className='text-xl cursor-pointer' onClick={() => UISetRef.current?.open()}/></div>

        <div className="grow  flex items-center justify-center gap-2">
          <span
            className="px-3 py-2 border-solid border rounded border-slate-300 hover:border-slate-900 cursor-pointer"
            onClick={() => setStep((prevState) => --prevState)}>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 19.5L8.25 12l7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          <div className="flex items-center justify-center gap-2 font-bold text-xl w-96">
            <span>{dayjs(range.start).format('YYYY年MM月DD日')}</span>
            <span>~</span>
            <span>{dayjs(range.end).format('YYYY年MM月DD日')}</span>
          </div>

          <span
            className="px-3 py-2 border-solid border rounded border-slate-300 hover:border-slate-900 cursor-pointer"
            onClick={() => setStep((prevState) => ++prevState)}>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M8.25 4.5l7.5 7.5-7.5 7.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          <span
            className={`px-3 py-2 border-solid border rounded border-slate-300 hover:border-slate-900 cursor-pointer ${step === 0 ? 'bg-slate-900 text-white' : ''
              }`}
            onClick={() => setStep(0)}>
            今
          </span>
        </div>

        <div className="basis-40 flex items-center justify-end">
          <div>
            {[...MRangeTypes].map(([k, v]) => (
              <span
                className={`p-2 hover:bg-slate-900 first:rounded-l last:rounded-r text-white cursor-pointer ${rangeType === k ? 'bg-slate-900' : 'bg-slate-500'
                  }`}
                key={k}
                onClick={() => setRangeType(k)}>
                {v.ch}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="flex-1">
        {rangeType === 0 ? (
          <Day
            clickCardCb={clickCardCb}
            clickEmptyCb={clickEmptyCb}
            data={data}
            range={range}
            renderCard={renderCard}
          />
        ) : null}
        {rangeType === 1 ? (
          <Week
            clickCardCb={clickCardCb}
            clickCopy={clickCopy}
            clickEmptyCb={clickEmptyCb}
            data={data}
            range={range}
            renderCard={renderCard}
          />
        ) : null}
        {rangeType === 2 ? (
          <Month clickCardCb={clickCardCb} clickEmptyCb={clickEmptyCb} data={data} range={range} />
        ) : null}
      </div>
      <UISet cb={() => refresh?.(range, rangeType, step,true)} ref={UISetRef}/>
    </div>
  );
};

export default Syllabus;
