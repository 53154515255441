import use from '@/services/saas.request';
import {
  ReqGetV1SaasStudioIdInfo,
  ResGetV1SaasStudioIdInfo,
  ReqPostV1SaasStudioIdRoom,
  ResPostV1SaasStudioIdRoom,
  ReqPutV1SaasStudioIdRoomRoomId,
  ResPutV1SaasStudioIdRoomRoomId,
  ReqDeleteV1SaasStudioIdRoomRoomId,
  ResDeleteV1SaasStudioIdRoomRoomId,
  ReqGetV1SaasStudioStudioIdTeachersListAll,
  ResGetV1SaasStudioStudioIdTeachersListAll,
  ReqGetV1SaasStudioStudioIdSalesmanListAll,
  ResGetV1SaasStudioStudioIdSalesmanListAll,
  ReqPutV1SaasStudioIdOpeStatus,
  ResPutV1SaasStudioIdOpeStatus,
  ReqGetV1SaasStudioIdBlacklist,
  ResGetV1SaasStudioIdBlacklist,
  ReqPostV1SaasStudioIdBlack,
  ReqPutV1SaasStudioIdBlack,
  ReqGetV1SaasStudioIdVipVipIdBlacklist,
  ResPostV1SaasStudioIdBlack,
  ResPutV1SaasStudioIdBlack,
  ResGetV1SaasStudioIdVipVipIdBlacklist,
  ReqPutV1SaasStudioIdBlackSetting,
  ResPutV1SaasStudioIdBlackSetting,
  ReqGetV1SaasStudioIdBlackSetting,
  ResGetV1SaasStudioIdBlackSetting,
  ReqPutV1SaasStudioIdInfo,
} from './params';

export const getStudio = (p: ReqGetV1SaasStudioIdInfo) =>
  use<ReqGetV1SaasStudioIdInfo, ResGetV1SaasStudioIdInfo>('get', `/v1/saas/studio/${p.id}/info`);

export const postRoom = (p: ReqPostV1SaasStudioIdRoom) =>
  use<ReqPostV1SaasStudioIdRoom, ResPostV1SaasStudioIdRoom>('post', `/v1/saas/studio/${p.id}/room`, p);

export const putRoom = (p: ReqPutV1SaasStudioIdRoomRoomId) =>
  use<ReqPutV1SaasStudioIdRoomRoomId, ResPutV1SaasStudioIdRoomRoomId>(
    'put',
    `/v1/saas/studio/${p.id}/room/${p.roomId}`,
    p,
  );

export const deleteRoom = (p: ReqDeleteV1SaasStudioIdRoomRoomId) =>
  use<ReqDeleteV1SaasStudioIdRoomRoomId, ResDeleteV1SaasStudioIdRoomRoomId>(
    'delete',
    `/v1/saas/studio/${p.id}/room/${p.roomId}`,
    p,
  );

export const getTeachersStudio = (p: ReqGetV1SaasStudioStudioIdTeachersListAll) =>
  use<ReqGetV1SaasStudioStudioIdTeachersListAll, ResGetV1SaasStudioStudioIdTeachersListAll>(
    'get',
    `/v1/saas/studio/${p.studioId}/teachers/list_all`,
    p,
  );

export const getSalesmanStudio = (p: ReqGetV1SaasStudioStudioIdSalesmanListAll) =>
  use<ReqGetV1SaasStudioStudioIdSalesmanListAll, ResGetV1SaasStudioStudioIdSalesmanListAll>(
    'get',
    `/v1/saas/studio/${p.studioId}/salesman/list_all`,
    p,
  );

export const toggleStudioSelf = (p: ReqPutV1SaasStudioIdOpeStatus) =>
  use<ReqPutV1SaasStudioIdOpeStatus, ResPutV1SaasStudioIdOpeStatus>('put', `/v1/saas/studio/${p.id}/ope_status`, p);

export const blackList = (p: ReqGetV1SaasStudioIdBlacklist) =>
  use<ReqGetV1SaasStudioIdBlacklist, ResGetV1SaasStudioIdBlacklist>(
    'get',
    `/v1/saas/studio/${p.id}/blacklist`,
    p
  )

export const postBlack = (p: ReqPostV1SaasStudioIdBlack) =>
  use<ReqPostV1SaasStudioIdBlack, ResPostV1SaasStudioIdBlack>(
    'post',
    `/v1/saas/studio/${p.id}/black`,
    p
  )

export const setBlack = (p: ReqPutV1SaasStudioIdBlackSetting) =>
  use<ReqPutV1SaasStudioIdBlackSetting, ResPutV1SaasStudioIdBlackSetting>(
    'put',
    `/v1/saas/studio/${p.id}/black/setting`,
    p
  )

export const getBlack = (p: ReqGetV1SaasStudioIdBlackSetting) =>
  use<ReqGetV1SaasStudioIdBlackSetting, ResGetV1SaasStudioIdBlackSetting>(
    'get',
    `/v1/saas/studio/${p.id}/black/setting`,
    p
  )

export const putBlack = (p: ReqPutV1SaasStudioIdBlack) =>
  use<ReqPutV1SaasStudioIdBlack, ResPutV1SaasStudioIdBlack>(
    'put',
    `/v1/saas/studio/${p.id}/black`,
    p
  )

export const vipBlackList = (p: ReqGetV1SaasStudioIdVipVipIdBlacklist) =>
  use<ReqGetV1SaasStudioIdVipVipIdBlacklist, ResGetV1SaasStudioIdVipVipIdBlacklist>(
    'get',
    `/v1/saas/studio/${p.id}/vip/${p.vipId}/blacklist`,
    p
  )
export const putSudio = (p: ReqPutV1SaasStudioIdInfo) =>
  use<ReqPutV1SaasStudioIdInfo, ReqPutV1SaasStudioIdInfo>(
    'put',
    `/v1/saas/studio/${p.id}/info`,
    p
  )
