export const TECH_FEEDBACK_PHONE = '19357606138';

export const MAP_PACKAGE_STATUS = new Map<
  string,
  {
    value: string;
    status: 'processing' | 'warning' | 'default';
    action: string;
  }
>([
  ['1', { value: '已上架', status: 'processing', action: '下架' }],
  ['2', { value: '待上架', status: 'warning', action: '变更' }],
  ['3', { value: '未上架', status: 'default', action: '上架' }],
]);
