import { useLayoutEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FloatButton, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import { useAppSelector } from '@/hooks';
import { selectBasic } from '@/model/basicSlice';
import { AppRoutes } from '@/routes';
import { CustomizePrimary } from '@/features';

dayjs.locale('zh-cn');

const App = () => {
  const { primaryColor, darkTheme } = useAppSelector(selectBasic);

  // 暗色主题
  useLayoutEffect(() => {
    // 暂不判断 window.matchMedia('(prefers-color-scheme: dark)').matches
    if (darkTheme) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkTheme]);

  return (
    <ConfigProvider
      form={{ requiredMark: 'optional' }}
      input={{ autoComplete: 'off' }}
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: primaryColor,
        },
      }}>
      <AnimatePresence mode="wait">
        <AppRoutes />
      </AnimatePresence>
      <CustomizePrimary />
      <FloatButton.BackTop visibilityHeight={100} />
    </ConfigProvider>
  );
};

export default App;
