import type { FC } from 'react';

interface IProps {
  onClick: (data?: unknown) => void;
}

const AddCard: FC<IProps> = ({ onClick }) => {
  return (
    <div className="w-full h-full justify-center cursor-pointer rounded-lg overflow-hidden group">
      <div
        className="w-full h-full group-hover:flex flex-col items-center justify-center gap-2 text-sm border-dashed border border-slate-500 bg-slate-100 hidden"
        onClick={onClick}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4.5v15m7.5-7.5h-15" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>新增排期</span>
      </div>
    </div>
  );
};

export default AddCard;
