import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { TableProps } from 'antd';
import { Table as AntTable } from 'antd';
import { IndexBlock } from '@/components';

// 封装了Table.pagination.setFilter
// 使得setFilter属性接受一个 typeof React.useState()[1] 函数类型
// 该组件中主要用在表格分页请求上
const Table = <RecordType extends object = never>({
  children,
  pagination,
  setFilter,
  hideIndex,
  ...props
}: TableProps<RecordType> & {
  hideIndex?: boolean;
  setFilter?: Dispatch<SetStateAction<Record<string, unknown>>>;
}) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  useEffect(() => {
    if (pagination) {
      setPageNo(pagination?.current || 1);
      setSize(pagination?.pageSize || 10);
      if (props.dataSource) {
        pagination?.total  && !props.dataSource?.length && setFilter?.((pre) => ({ ...pre, pageNo: pre.pageNo ? pre.pageNo === 1 ? 1 : pre.pageNo as number - 1 : 1 }))
      }
    }
  }, [pagination])
  return (
    <AntTable<RecordType>
      onChange={({ current, pageSize }, filters) => {
        setFilter?.((prevState) => ({ ...prevState, ...filters, pageNo: current, pageSize }));
      }}
      pagination={
        pagination
          ? {
            showQuickJumper: false,
            showSizeChanger: true,
            ...pagination,
          }
          : pagination
      }
      {...props}>
      {hideIndex ? null : (
        <Table.Column
          align="center"
          fixed="left"
          key="table_internal_idx"
          render={(_, __, idx) => <IndexBlock>{(pageNo - 1) * size + idx + 1}</IndexBlock>}
          title="序号"
          width={'4rem'}
        />
      )}

      {children}
    </AntTable>
  );
};

Table.defaultProps = { rowKey: 'id' }; // 封装table的默认rowKey为id
Table.SELECTION_COLUMN = AntTable.SELECTION_COLUMN;
Table.EXPAND_COLUMN = AntTable.EXPAND_COLUMN;
Table.SELECTION_ALL = AntTable.SELECTION_ALL;
Table.SELECTION_INVERT = AntTable.SELECTION_INVERT;
Table.SELECTION_NONE = AntTable.SELECTION_NONE;
Table.Column = AntTable.Column;
Table.ColumnGroup = AntTable.ColumnGroup;
Table.Summary = AntTable.Summary;

export default Table;
